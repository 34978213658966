import {
  View,
  Text,
  StyleSheet,
  Pressable,
  Image,
  ScrollView,
  Dimensions,
} from "react-native";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";

// icons
import {
  AntDesign,
  Fontisto,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons";

// components
import MenuItem from "../MenuItem";

// globals
import { FONTSIZE } from "../../Global";
import DeviceInfo from "react-native-device-info";
import { useTheme } from "../../context/Theme/ThemeProvider";
import MainButton from "../MainButton";
import UserCard from "../UserCard";
import { useLanguage } from "../../context/Language/LanguageProvider";

const menuItems = [
  {
    id: 1,
    name: 'Para Yatır',
    value: 'DepositSelection',
    icon: <MaterialCommunityIcons name="cash-fast" size={24}/>,
  },
  {
    id: 2,
    name: 'Para Çek',
    value: 'Withdraw',
    icon: <MaterialCommunityIcons name="cash-fast" size={24}/>
  },
  {
    id: 3,
    name: "Bildirimler",
    value: "Notifications",
    icon: <Ionicons name="notifications" size={24} />,
  },
  {
    id: 4,
    name: "Hesap Özeti",
    value: "Profile",
    icon: <MaterialCommunityIcons name="cash-register" size={24} />,
  },
  {
    id: 5,
    name: "Şifre Değiştir",
    value: "ChangePassword",
    icon: <MaterialCommunityIcons name="onepassword" size={24} />,
  },
  {
    id: 6,
    name: "Traderlar",
    value: "Traders",
    icon: <Ionicons name="people" size={24} />,
  },
  {
    id: 7,
    name: "Robotlar",
    value: "Robots",
    icon: <MaterialCommunityIcons name="robot-excited-outline" size={24} />,
  },
  {
    id: 8,
    name: "Canlı Destek",
    value: "LiveSupport",
    icon: <MaterialIcons name="live-help" size={24} />,
  },
  {
    id: 9,
    name: "Müşteri Şikayet",
    value: "CustomerComplaint",
    icon: <AntDesign name="customerservice" size={24} />,
  },
  // {
  //   id: 9,
  //   name: 'Ayarlar',
  //   value: 'Settings',
  //   icon: <MaterialIcons name="settings" size={24} />,
  // },
];

const CustomSideMenu = ({ navigation }: any) => {
  const { colors } = useTheme();
  const { words } = useLanguage();
  const { logout, user } = useContext(AuthContext);
  const styles = Styles(colors);

  return (
    <View style={[styles.container]}>
      <View
        style={{
          height: 100,
          backgroundColor: colors.HEADER_BACKGROUND,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          borderRadius: 5,
          marginBottom: 10,
          justifyContent: "space-between",
          paddingHorizontal: "2%",
        }}
      >
        <UserCard
          username={`${user?.Name}`}
          number={user?.Phone || "Telefon kayıtlı değil"}
          loginID={user?.ID || "Login ID yok"}
          imageSource={require("../../assets/trader1.png")}
        />
      </View>
      <ScrollView
        showsVerticalScrollIndicator={false}  
        contentContainerStyle= {{flex:1}}      
        style={{ width: "100%", height: "100%"}}
      >
        <View style={{ gap: 10 }}>
          {menuItems.map((item) => (
            <MenuItem
              key={item.id}
              name={item.name}
              Icon={item.icon}
              route={item.value}
              navigation={navigation}
              extraProp={{ user }}
              isInvestor={!!user?.IsInvestor}
            />
          ))}
        </View>

        <View style={{ marginBottom: 10, marginTop:'auto' }}>
          <Text
            style={{
              alignSelf: "center",
              fontWeight: "400",
              color: colors.TEXT,
              marginTop: 30,
            }}
          >
            {" "}
            {/* {DeviceInfo.getVersion()}{' '} */}
            v0.8.7
          </Text>

          <MainButton
            onPress={() => logout({})}
            text={words["Exit"]}
            buttonStyle={styles.logoutBtn}
            textStyle={{ color: colors.MAIN_BUTTON_TEXT }}
          />
        </View>
      </ScrollView>
    </View>
  );
};

export default CustomSideMenu;

const Styles = (COLOR: any) =>
  StyleSheet.create({
    container: {
      backgroundColor: COLOR.SCREEN_BACKGROUND,
      height: Dimensions.get("window").height,
      padding: 10,
    },
    logoutBtn: {
      alignItems: "center",
      width: "100%",
      borderRadius: 5,
      padding: 10,
      marginVertical: 20,
      backgroundColor: COLOR.MAIN_BUTTON_BACKGORUND,
      color: COLOR.MAIN_BUTTON_TEXT,
    },
  });

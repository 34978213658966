import dayjs from "dayjs";
import { useContext, useState } from "react";
import { Image, Linking, Text, TextInput, View } from "react-native";
import InfoCard from "../../components/InfoCard";
import MainButton from "../../components/MainButton";
import { AuthContext } from "../../context/AuthContext";
import { useTheme } from "../../context/Theme/ThemeProvider";
import { Icons } from "./constants";
import { showFlowMessage } from "../../components/FlowMessage";
import { useLanguage } from "../../context/Language/LanguageProvider";
import { Audio } from "expo-av";
import apiCall from "../../utils/apiCall";

async function SoundSuccess() {
  console.log("Loading Sound");
  const { sound } = await Audio.Sound.createAsync(
    require("../../assets/sounds/soundssuccess.mp3")
  );
  console.log("Playing Sound");
  await sound.playAsync();
}
async function SoundError() {
  console.log("Loading Sound");
  const { sound } = await Audio.Sound.createAsync(
    require("../../assets/sounds/soundserror.mp3")
  );
  console.log("Playing Sound");
  await sound.playAsync();
}

const getIcon = (icon: any) => {
  if (icon == "garantibbva") {
    return Icons.garantibbva;
  } else if (icon == "yapi_kredi") {
    return Icons.yapi_kredi;
  } else if (icon == "ziraat") {
    return Icons.ziraat;
  } else if (icon == "kuveyt_turk") {
    return Icons.kuveyt_turk;
  } else if (icon == "akbank") {
    return Icons.akbank;
  } else {
    return Icons.bank;
  }
};

const Transaction = (props: any) => {
  const iconColor: string = props.route.params.iconColor;
  const { colors } = useTheme();
  const { words } = useLanguage();
  const { user } = useContext(AuthContext);

  const bank = props.route.params.Bank;
  const phone = props.route.params.Phone;
  const currencies = bank?.currencies?.find(
    (item: any) => item.currency == props.route.params.Currency
  );

  const [price, setPrice] = useState("0");

  function WhatsappMessageSend() {
    if (!price || isNaN(Number(price))) {
      SoundError();
      showFlowMessage({
        message: words["FlowMessage_InvalidPrice"],
        type: "error",
        duration: 1000,
      });
      return;
    } else if (price == "0") {
      SoundError();
      showFlowMessage({
        message: words["FlowMessage_InvalidPrice"],
        type: "error",
        duration: 1000,
      });
      return;
    }

    const type = 1;
    const subject = `Para Yatırma Talebi - Hesap ID: ${user?.ID} - ${user?.Name}`;
    const message = `
Sayın Yatırım Uzmanı,

İlgili Hesap Sahibi: ${user?.Name}
Hesap ID: ${user?.ID || user?.Login}
İşlem Tarihi: ${dayjs().startOf("second").format("DD/MM/YYYY HH:mm")}
		
Yatırımcımız ${user?.Name}, ${bank?.title} bankası, ${
      currencies?.iban
    } IBAN bilgisine sahip ${currencies?.currency} hesabı üzerinden ${dayjs()
      .startOf("second")
      .format("DD/MM/YYYY HH:mm")} tarihinde ${Number(price)} ${
      currencies?.currency
    } miktarında para yatırım talebinde bulunmuştur.
		
* Banka Adı    : ${bank?.title}
* Döviz Birimi : ${currencies?.currency}
* Alıcı Adı    : ${currencies?.name}
* IBAN         : ${currencies?.iban}
* Miktar       : ${price} ${currencies?.currency}

Lütfen gerekli işlemleri gerçekleştirip talebin değerlendirilmesini sağlayınız.
		
Detaylı bilgi için lütfen yatırımcı iletişime geçiniz.
		
Yatırımcı Telefon Numarası: ${user?.Phone}
		


Bu Mesaj Brillanttrade Web Tarafından İletilmiştir.`;

    const datas = {
      price: Number(price),
      bank: bank?.title,
      currency: currencies?.currency,
      name: currencies?.name,
      iban: currencies?.iban,
    };
    const body = {
      type: type,
      subject: subject,
      message: message,
      data: datas,
    };

    apiCall("/send-mail", body, "POST")
      .then((response) => {
        SoundSuccess();
        showFlowMessage({
          message: words["FlowMessage_Deposit"],
          type: "success",
          duration: 5000,
        });
        props?.navigation?.navigate("Home");
      })
      .catch((err) => {
        SoundError();
        showFlowMessage({
          message: words["FlowMessage_Error"],
          type: "error",
          duration: 5000,
        });
      });
  }
  console.log(bank?.id);
  return (
    <View
      style={{
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 20,
        paddingHorizontal: 10,
        height: "100%",
        backgroundColor: colors.SCREEN_BACKGROUND,
      }}
    >
      <View style={{ width: "50%", height: 100 }}>
      <Image
					source={getIcon(bank?.id)}
					resizeMode='contain'
					style={{ width: '100%', height: '100%', tintColor: !!iconColor ? iconColor : undefined}}
				/>
      </View>
      <View style={{ width: "100%", gap: 10 }}>
        <InfoCard
          type="basic"
          args={{ title: "Banka Adı", value: bank?.title }}
        />
        <InfoCard
          type="basic"
          args={{ title: "Döviz Türü", value: currencies?.currency }}
        />
        <InfoCard
          type="basicWithCopy"
          args={{ title: "Alıcı Adı", value: currencies?.name }}
        />
        <InfoCard
          type="basicWithCopy"
          args={{ title: "IBAN", value: currencies?.iban }}
        />
        {/* <InfoCard type='basicWithCopy' args={{title:"Alıcı Adı", value:currencies?.name}}/> */}
        {/* <InfoCard type='basicWithCopy' args={{title:"Iban", value:currencies?.iban}}/> */}
        <InfoCard
          type="basic"
          args={{
            title: "Açıklama",
            value:
              "AÇIKLAMA ALANINA SADECE YATIRIMCI AD SOYADI YAZILACAKTIR. (BASKA BIR BILGI YAZILMASI HALINDE PARA IADE EDILECEKTIR.)",
          }}
        />
      </View>
      <View
        style={{
          width: "100%",
          height: 2,
          borderRadius: 10,
          backgroundColor: colors.CARD_BACKGROUND1,
        }}
      ></View>
      <View
        style={{
          width: "100%",
          backgroundColor: colors.CARD_BACKGROUND1,
          borderRadius: 5,
          flexDirection: "row",
          alignItems: "center",
          padding: 8,
        }}
      >
        <Text style={{ flexBasis: "25%", color: colors.TEXT }}>Miktar</Text>
        <TextInput
          keyboardType="numeric"
          style={{ flexBasis: "60%", textAlign: "right", color: colors.TEXT }}
          onChangeText={(newText) => setPrice(newText)}
          defaultValue={price}
        />
        <Text
          style={{ flexBasis: "15%", textAlign: "right", color: colors.TEXT }}
        >
          {currencies?.currency}
        </Text>
      </View>
      <View style={{ width: "100%" }}>
        <MainButton
          onPress={WhatsappMessageSend}
          text={words['Button_SubmitDepositRequest']}
        />
      </View>
    </View>
  );
};

export default Transaction;

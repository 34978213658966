import { useContext, useEffect, useState } from "react";
import { FlatList, StyleSheet, TextInput, View } from "react-native";
import { IColors, IGroupSymbol } from "../Global";
import SymbolListItem from "../components/Symbol/SymbolListItem";
import Tab from "../components/Tab";
import { AuthContext } from "../context/AuthContext";
import { useTheme } from "../context/Theme/ThemeProvider";

const Symbols = (props:any, { navigation }: any) => {
  const { user } = useContext(AuthContext);

  // if (!user?.ID && !user?.Login) {
  //   return <View></View>;
  // }
  const { colors } = useTheme();
  const styles = Styles(colors);

  console.log("props", `https://web-terminal.phsterminal.com/markets/${props.route.params.symbolName}/tradingview?login=${user?.ID || user?.Login}`);
  return <View style={styles.container}>
    <iframe src={`https://web-terminal.phsterminal.com/markets/${props.route.params.symbolName}/tradingview?login=${user?.ID || user?.Login}`} style={{width:'100%', height:'100%'}}></iframe>
  </View>;
};

export default Symbols;

const Styles = (colors: IColors) =>
  StyleSheet.create({
    container: {
      width: "100%",
      height: "100%",
      paddingHorizontal: 10,
      paddingBottom: 10,
      paddingTop: 5,
      gap: 10,
      backgroundColor: colors.SCREEN_BACKGROUND,
    },
    menuWrapper: {
      flexDirection: "column",
      flexWrap: "wrap",
      width: "100%",
    },
    bottomContainer: {
      flex: 1,
      paddingHorizontal: 10,
    },
    dataWrapper: {
      flex: 1,
      gap: 10,
    },
  });

import React, {FC} from 'react';
import {Text, View} from 'react-native';
import dayjs from 'dayjs';
import {useTheme} from '../../context/Theme/ThemeProvider';

const fields = [
  {
    id: 0,
    name: 'Pazar',
  },
  {
    id: 1,
    name: 'Pazartesi',
  },
  {
    id: 2,
    name: 'Salı',
  },
  {
    id: 3,
    name: 'Çarşamba',
  },
  {
    id: 4,
    name: 'Perşembe',
  },
  {
    id: 5,
    name: 'Cuma',
  },
  {
    id: 6,
    name: 'Cumartesi',
  },
];

const getSession = (sessions: any, day: number) => {
  let session = [];

  for (let i = 0; i < sessions[day].length; i++) {
    const {Open, Close} = sessions[day][i];
    const openDate = new Date();
    const closeDate = new Date();
    openDate.setHours(0, Number(Open), 0);
    closeDate.setHours(0, Number(Close), 0);
    session.push({open: openDate, close: closeDate});
  }

  return session;
};

function toShort(data: number) {
  console.log(data, 'data');
  return dayjs(data).format('HH:mm');
}

interface Props {
  data: any;
}

const Sessions: FC<Props> = ({data}: Props) => {
  const sessions = data.Sessions;
  const {colors} = useTheme();

  return (
    <View style={{marginBottom: 20}}>
      <View style={{flexDirection: 'row', justifyContent: 'space-between', marginTop: 7}}>
        <Text style={{flex: 2, fontWeight: 'bold', color: colors.TEXT, textAlign: 'left'}}>Sessions</Text>
        <Text style={{flex: 2, fontWeight: 'bold', color: colors.TEXT, textAlign: 'right', marginBottom: 5}}>Trade</Text>
      </View>
      <View>
        {fields.map((item: any, index: number) => {
          return (
            <View
              style={{flexDirection: 'row', justifyContent: 'space-between'}}
              key={item.id}>
              <Text style={{flex: 2, color: colors.TEXT, textAlign: 'left'}}>{item.name}</Text>
              <Text style={{flex: 2, color: colors.TEXT, textAlign: 'right'}}>
                {' '}
                {getSession(sessions, index).map((sessionItem: any) => {
                  return (
                    <Text key={index}>
                      {toShort(sessionItem.open)} - {toShort(sessionItem.close)}
                    </Text>
                  );
                })}{' '}
              </Text>
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default Sessions;

export interface ILanguage {
	id: number;
	name: string;
	code: string;
}

export const Languages = [
	{ id: 0, name: 'Türkçe', code: 'tr' },
	{ id: 1, name: 'English', code: 'en' },
	{ id: 2, name: 'العربية', code: 'ar' },
	//{ id: 3, name: 'Türkmen', code: 'tk' },
	//{ id: 4, name: 'Deutsch', code: 'de' },
	{ id: 5, name: 'Español', code: 'es' },
	{ id: 6, name: '日本語', code: 'ja' },
	//{ id: 7, name: 'Русский', code: 'ru' },
	//{ id: 8, name: 'Italiano', code: 'it' },
	{ id: 9, name: '简体中文', code: 'zh' },
	//{ id: 10, name: '한국어', code: 'ko' },
	//{ id: 11, name: 'Português', code: 'pt' },
	//{ id: 12, name: 'Français', code: 'fr' },
	//{ id: 13, name: 'فارسی', code: 'fa' },
	//{ id: 14, name: 'Bahasa Indonesia', code: 'id' },
	//{ id: 15, name: 'Bahasa Melayu', code: 'ms' },
	//{ id: 16, name: 'ไทย', code: 'th' },
	//{ id: 17, name: 'Tiếng Việt', code: 'vi' },
	//{ id: 18, name: 'हिन्दी', code: 'hi' },
	//{ id: 19, name: 'اردو', code: 'ur' },
	//{ id: 20, name: 'മലയാളം', code: 'ml' },
	//{ id: 21, name: 'தமிழ்', code: 'ta' },
	//{ id: 22, name: 'বাংলা', code: 'bn' },
	//{ id: 23, name: 'ಕನ್ನಡ', code: 'kn' },
	//{ id: 24, name: 'తెలుగు', code: 'te' },
	//{ id: 25, name: 'मराठी', code: 'mr' },
	//{ id: 26, name: 'ગુજરાતી', code: 'gu' },
	//{ id: 27, name: 'ଓଡ଼ିଆ', code: 'or' },
	//{ id: 28, name: 'ਪੰਜਾਬੀ', code: 'pa' },
	//{ id: 29, name: 'অসমীয়া', code: 'as' },
	//{ id: 30, name: 'Manipuri', code: 'mni' },
	//{ id: 31, name: 'Mizo', code: 'lus' },
	//{ id: 32, name: 'Naga', code: 'nag' },
	//{ id: 33, name: 'Khasi', code: 'kha' },
	//{ id: 34, name: 'Garo', code: 'grt' },
	//{ id: 35, name: 'Kom', code: 'bkm' },
];

import React, { useContext, useEffect, useState } from "react";
import {
  Text,
  StyleSheet,
  View,
  TextInput,
  Pressable,
  Image,
  KeyboardAvoidingView,
  Platform,
  Keyboard,
  TouchableWithoutFeedback,
  ScrollView,
  Dimensions,
  ActivityIndicator,
} from "react-native";
import { AuthContext } from "../context/AuthContext";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useTheme } from "../context/Theme/ThemeProvider";
import Tooltip from "react-native-walkthrough-tooltip";
import DeviceInfo from "react-native-device-info";
import { FONTSIZE } from "../Global";
import checkVersion from "react-native-store-version";
import { useLanguage } from "../context/Language/LanguageProvider";
import socket from "../config/socket";

declare global {
  interface Window {
    ReactNativeWebView: any;
  }
}

const getLastLogin = async () => {
  try {
    const lastLogin = await AsyncStorage.getItem("lastLoginAccount");
    return lastLogin != null ? JSON.parse(lastLogin) : null;
  } catch (e) {
    // error reading value
  }
};

const setTipsStatus = async () => {
  try {
    await AsyncStorage.setItem("tips", JSON.stringify(true));
  } catch (e) {
    // error reading value
  }
};
const getTipsStatus = async () => {
  try {
    const jsonValue = await AsyncStorage.getItem("tips");
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    // error reading value
  }
};
const setServerStorage = async (ServerName: string, ServerIP: string) => {
  try {
    await AsyncStorage.setItem(
      "server",
      JSON.stringify({ server: ServerName, url: ServerIP })
    );
  } catch (e) {
    // error reading value
  }
};

export const getServerStorage = async () => {
  try {
    const serverStr = await AsyncStorage.getItem("server");
    return serverStr != null ? JSON.parse(serverStr) : null;
  } catch (e) {
    // error reading value
  }
};
const getLogins = async (selectedServer: string) => {
  try {
    const jsonValue = await AsyncStorage.getItem(`loginList-${selectedServer}`);
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    // error reading value
  }
};

const removeLogin = async (
  login: string,
  selectedServer: string,
  isInvestor: boolean
) => {
  try {
    const jsonValue = await AsyncStorage.getItem(`loginList-${selectedServer}`);
    if (jsonValue != null) {
      const logins = JSON.parse(jsonValue);
      const newLogins = logins.filter(
        (item: any) => item.login != login && item?.isInvestor != isInvestor
      );
      await AsyncStorage.setItem(
        `loginList-${selectedServer}`,
        JSON.stringify(newLogins)
      );
    }
  } catch (e) {
    // error reading value
  }
};

const getDarkMode = async () => {
  try {
    const jsonValue = await AsyncStorage.getItem(`darkMode`);
    // console.log('getDarkMode:', jsonValue);
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    console.log("getDarkModeError: \n", e);
    return true;
  }
};

const serverList = [
  { server: "REAL", url: "https://terminal-api.pmprs.xyz:8443" },
  // { server: "DEMO", url: "https://terminal-api.pmprs.xyz:2053" },
  // { server: "TEST", url: "http://192.168.1.107:8443" },
];

const LoginScreen = ({}): React.ReactElement => {
  const { checkLogin, isWebView, setIsWebView } = useContext(AuthContext);
  const { darkMode, colors, setScheme } = useTheme();
  const { language, setLanguage, words } = useLanguage();

  const styles = Styles(colors);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [selectedUserIsInvestor, setSelectedUserIsInvestor] = useState(false);

  const [lastLogin, setLastLogin] = useState<any>({
    login: "",
    password: "",
    isInvestor: false,
  });

  const [loginButtonToolTip, setLoginButtonToolTip] = useState(true);
  const [serverButtonToolTip, setServerButtonToolTip] = useState(true);
  const [loginListToolTip, setLoginListToolTip] = useState(true);

  
  const [server, setServer] = useState({
    server: "REAL",
    url: "https://terminal-api.pmprs.xyz:8443",
  }); //http://44.218.14.105:3051
  const [loginList, setLoginList] = useState<Array<any>>([]);
  const [newVersionAvailable, setNewVersionAvailable] =
  useState<boolean>(false);
  
  useEffect(() => {
    const messageListener = window.addEventListener('message', (nativeEvent) => {
      if (nativeEvent.data == 'webview') {
        setIsWebView(true);
      }  
    });
    return messageListener;
  }, []);

  // window.addEventListener('message', (nativeEvent) => {
  //   alert("isWebView: "+ nativeEvent.data);
  //   if (nativeEvent.data === 'webview') {
  //     setIsWebView(true);
  //   }  
  // });
  
  // const handleTextChange = (text: string) => {
  //   const numericValue = text.replace(/[^0-9]/g, "");
  //   setUsername(numericValue);
  // };

  const handleLogin = (uname?: string, pass?: string) => {
    if (uname && pass) {
      checkLogin(uname, pass, false, server);
    } else {
      checkLogin(username, password, false, server);
      setPassword("");
    }
  };

  const handleLoginWithoutSave = async () => {
    await checkLogin(username, password, false, server, false);
    setPassword("");
  };

  useEffect(() => {
    getServerStorage()
      .then((lastServer) => {
        if (lastServer) {
          setServer((prev) => lastServer);
        } else {
          setServer(serverList[0]);
        }
      })
      .catch(() => {
        setServer(serverList[0]);
      });

    AsyncStorage.removeItem("user"); // User bilgisini kaldırıyorum.
    getLastLogin().then((data) => {
      if (data) {
        setLastLogin(data);
      }
    });

    getDarkMode().then((data) => {
      setScheme(!!data);
    });

    getTipsStatus().then((data) => {
      if (data) {
        setLoginButtonToolTip(false);
        setServerButtonToolTip(false);
        setLoginListToolTip(false);
      }
    });
  }, []);

  useEffect(() => {
    // AsyncStorage.setItem('lastServer', JSON.stringify(server));
    setServerStorage(server.server, server.url);
    getLogins(server.server).then((data) => {
      setUsername("");
      setPassword("");
      setSelectedUserIsInvestor(false);
      if (data) {
        setLoginList(data);
      } else {
        setLoginList([]);
      }
    });
    setServerStorage(server.server, server.url);
    socket.setServer(server.url);
  }, [server]);

  useEffect(() => {
    if (loginList) {
      if (loginList.length > 0) {
        const tempLogin = loginList.find(
          (item: any) =>
            item.login == lastLogin.login &&
            item.isInvestor == lastLogin.isInvestor
        );
        if (tempLogin) {
          setUsername(tempLogin.login);
          setPassword(tempLogin.password);
          setSelectedUserIsInvestor(tempLogin.isInvestor);
        }
      }
    }
  }, [loginList]);

  // GÜNCELLEME SONRASI SİLİNECEK ------->>>
  const [warning, setWarning] = useState(true);
  // GÜNCELLEME SONRASI SİLİNECEK ------->>>

  

  // window.addEventListener("message", message => {
  //   console.log(message.data) // Wayne is coming!!!
  // });

  useEffect(() => {
    
    if (!isWebView) {
      return;
    }
    setWarning(false);
    setLoginButtonToolTip(false);
    setServerButtonToolTip(false);
    setLoginListToolTip(false);
  }, [isWebView]);

  const isValid = username && password;

  return (
    <View style={styles.container}>
      {/* {isLoading ? (
        <View style={{justifyContent: 'center', position: 'absolute', alignSelf: 'center', width:'100%', height:'100%'}}>
          <ActivityIndicator
            size={'large'}
            color={colors.ACTIVITY_INDICATOR}
          />
        </View>
      ): (<View></View>)} */}
      <View style={[styles.containers, { flex: 4, opacity: 1 }]}>
        <Image
          source={require("../assets/logo_brillanttrade.png")}
          style={[
            styles.image,
            darkMode ? { tintColor: colors.RED } : null,
            { flex: 2 },
          ]}
        />
        <View style={{ flex: 2, width: "100%", alignItems: "center" }}>
          <TextInput
            style={styles.input}
            placeholder="Kullanıcı Adı"
            placeholderTextColor={colors.TEXT}
            onChangeText={(text) => setUsername(text)}
            value={username}
          />
          <TextInput
            style={styles.input}
            placeholder="Şifre"
            placeholderTextColor={colors.TEXT}
            onChangeText={(text) => setPassword(text)}
            value={password}
            secureTextEntry={true}
          />
        </View>
        <View style={{ flex: 1, width: "100%", alignItems: "center" }}>
          <Tooltip
            isVisible={loginButtonToolTip}
            content={
              <View style={{ width: "100%" }}>
                <Text
                  style={{
                    color: colors.TEXT,
                    textAlign: "center",
                    fontWeight: "800",
                  }}
                >
                  {" "}
                  Giriş ekranı yenilendi.{" "}
                </Text>
                <Text style={{ color: colors.TEXT, textAlign: "justify" }}>
                  Kullanıcı adı ve şifreyi girdikten sonra 'Giriş Yap' butonuna
                  bastığınız zaman giriş yaparsınız ve giriş bilgilerinizi
                  kaydederiz.{" "}
                </Text>
                <Text style={{ color: colors.TEXT, textAlign: "justify" }}>
                  Eğer 'Giriş Yap' butonuna basılı tutarsanız Giriş
                  bilgilerinizi KAYDETMEDEN giriş yaparsınız.{" "}
                </Text>
              </View>
            }
            parentWrapperStyle={{
              width: "100%",
              alignItems: "center",
              maxHeight: "auto",
            }}
            onClose={() => {
              setLoginButtonToolTip(false);
            }}
            placement="center"
            // below is for the status bar of react navigation bar
            contentStyle={{
              width: "100%",
              alignItems: "center",
              maxHeight: "auto",
              backgroundColor: colors.CARD_BACKGROUND2,
            }}
            topAdjustment={0}
          >
            <Pressable
              style={[
                !isValid ? [styles.button, { opacity: 0.5 }] : styles.button,
              ]}
              onPress={() => {
                handleLogin();
              }}
              onLongPress={() => {
                handleLoginWithoutSave();
              }}
              disabled={!isValid}
            >
              <Text style={styles.buttonText}>Giriş Yap</Text>
            </Pressable>
          </Tooltip>
        </View>
      </View>
      <View style={[styles.containers, { flex: 1, opacity: 1 }]}>
        <Tooltip
          isVisible={loginListToolTip && !loginButtonToolTip}
          content={
            <View style={{ width: "100%" }}>
              <Text style={{ color: colors.TEXT, textAlign: "justify" }}>
                Kayıtlı kullanıcılarınız olduğunda istediğiniz kullanıcıyla
                giriş yapabilirsiniz.
              </Text>
              <Text style={{ color: colors.TEXT, textAlign: "justify" }}>
                Eğer kayıtlı kullanıcıya basılı tutarsanız basılı tuttuğunuz
                hesabı kaydedilenlerden kaldırırsınız.
              </Text>
            </View>
          }
          parentWrapperStyle={{
            width: "100%",
            alignItems: "center",
            maxHeight: "auto",
          }}
          onClose={() => {
            setLoginListToolTip(false);
          }}
          placement="center"
          // below is for the status bar of react navigation bar
          contentStyle={{
            width: "100%",
            alignItems: "center",
            maxHeight: "auto",
            backgroundColor: colors.CARD_BACKGROUND2,
          }}
          topAdjustment={0}
        >
          <View
            style={[
              styles.containers,
              { borderTopWidth: 0.5, borderColor: colors.TEXT },
            ]}
          >
            <Text
              style={{
                color: colors.TEXT,
                position: "relative",
                width: "40%",
                textAlign: "center",
                top: -12,
                backgroundColor: colors.SCREEN_BACKGROUND,
              }}
            >
              Kayıtlı Hesaplar
            </Text>
            {loginList.length > 0 ? (
              <ScrollView
                style={{maxWidth:"100%"}}
                showsVerticalScrollIndicator={false}
                horizontal={true}
              >
                {loginList.map((item, index) => (
                  <Pressable
                    key={index}
                    style={[
                      item.login == username &&
                      item.isInvestor == selectedUserIsInvestor
                        ? [
                            styles.serverAndLoginsContainerSelected,
                            item.isInvestor && styles.investorButtonSelected,
                          ]
                        : [
                            styles.serverAndLoginsContainer,
                            item.isInvestor && styles.investorButton,
                          ],
                    ]}
                    onPress={() => {
                      setUsername(item.login);
                      setPassword(item.password);
                      setSelectedUserIsInvestor(item.isInvestor);
                    }}
                    onLongPress={() => {
                      removeLogin(item.login, server.server, item?.isInvestor);
                      setLoginList(
                        loginList.filter(
                          (loginListItem: any) =>
                            loginListItem.login != item.login
                        )
                      );
                    }}
                  >
                    <Text
                      style={[
                        item.login == username &&
                        item.isInvestor == selectedUserIsInvestor
                          ? styles.serverAndLoginsContainerSelectedText
                          : styles.serverAndLoginsContainerText,
                      ]}
                    >
                      {item.login}
                    </Text>
                  </Pressable>
                ))}
              </ScrollView>
            ) : (
              <Text style={{ color: colors.TEXT }}>
                Kayıtlı hesap bulunmamaktadır.
              </Text>
            )}
          </View>
        </Tooltip>
      </View>
      <View style={[styles.containers, { flex: 1, opacity: 1 }]}>
        <Tooltip
          isVisible={
            !loginListToolTip && !loginButtonToolTip && serverButtonToolTip
          }
          content={
            <View style={{ width: "100%" }}>
              <Text style={{ color: colors.TEXT, textAlign: "justify" }}>
                Buradan bir sunucu seçmeniz gerekmektedir.
              </Text>
              <Text style={{ color: colors.TEXT, textAlign: "justify" }}>
                Giriş yaparken özellikle seçili sunucuya dikkat etmeniz
                gerekmektedir.
              </Text>
            </View>
          }
          parentWrapperStyle={{
            width: "100%",
            alignItems: "center",
            maxHeight: "auto",
          }}
          onClose={() => {
            setServerButtonToolTip(false);
            setTipsStatus();
          }}
          placement="center"
          // below is for the status bar of react navigation bar
          contentStyle={{
            width: "100%",
            alignItems: "center",
            maxHeight: "auto",
            backgroundColor: colors.CARD_BACKGROUND2,
          }}
          topAdjustment={0}
        >
          <View
            style={[
              styles.containers,
              { borderTopWidth: 0.5, borderColor: colors.TEXT },
            ]}
          >
            <Text
              style={{
                color: colors.TEXT,
                position: "relative",
                width: "30%",
                textAlign: "center",
                top: -12,
                backgroundColor: colors.SCREEN_BACKGROUND,
              }}
            >
              Sunucular
            </Text>
            {serverList.length > 0 ? (
              <ScrollView
              style={{flex:1}}
                showsVerticalScrollIndicator={false}
                horizontal={true}
              >
                {serverList.map((item, index) => (
                  <Pressable
                    key={index}
                    style={
                      item.server != server.server
                        ? styles.serverAndLoginsContainer
                        : styles.serverAndLoginsContainerSelected
                    }
                    onPress={() => setServer(item)}
                  >
                    <Text
                      style={
                        item.server != server.server
                          ? styles.serverAndLoginsContainerText
                          : styles.serverAndLoginsContainerSelectedText
                      }
                    >
                      {item.server}
                    </Text>
                  </Pressable>
                ))}
              </ScrollView>
            ) : (
              <Text style={{ color: colors.TEXT }}>
                Sistemde sunucu bulunmamaktadır.
              </Text>
            )}
          </View>
        </Tooltip>
      </View>
      {/* <Text style={{position: 'absolute', fontWeight: '300', fontSize: FONTSIZE.m, marginHorizontal: 'auto', color: colors.TEXT, bottom: 10}}> {DeviceInfo.getVersion()} </Text> */}
      <Text
        style={{
          position: "absolute",
          fontWeight: "300",
          fontSize: FONTSIZE.m,
          marginHorizontal: "auto",
          color: colors.TEXT,
          bottom: 10,
        }}
      >
        {" "}
        v0.8.7{" "}
      </Text>
    </View>
  );
};

const Styles = (COLOR: any) =>
  StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: COLOR.SCREEN_BACKGROUND,
    },
    containers: {
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    input: {
      width: "80%",
      flex: 1,
      maxHeight: 50,
      borderColor: "gray",
      borderWidth: 1,
      marginBottom: 10,
      borderRadius: 5,
      padding: 10,
      backgroundColor: COLOR.SCREEN_BACKGROUND,
      color: COLOR.TEXT,
    },
    button: {
      width: "80%",
      height: 40,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: COLOR.MAIN_BUTTON_BACKGORUND,
      borderRadius: 5,
    },
    saveButton: {
      width: "38%",
      height: 40,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: COLOR.MAIN_BUTTON_BACKGORUND,
      borderRadius: 5,
    },
    buttonText: {
      color: COLOR.MAIN_BUTTON_TEXT,
    },
    image: {
      width: "80%",
      height: 200,
      resizeMode: "contain",
      marginBottom: 20,
    },
    serverAndLoginsContainer: {
      width: Dimensions.get("window").width / 4,
      padding: 10,
      margin: 10,
      borderRadius: 2,
      borderWidth: 0.5,
      borderColor: COLOR.TEXT,
    },
    serverAndLoginsContainerSelected: {
      width: Dimensions.get("window").width / 4,
      padding: 10,
      margin: 10,
      borderRadius: 2,
      backgroundColor: COLOR.MAIN_BUTTON_BACKGORUND,
      borderColor: COLOR.MAIN_BUTTON_BACKGORUND,
      borderWidth: 2,
    },
    serverAndLoginsContainerText: {
      color: COLOR.TEXT,
      textAlign: "center",
    },
    serverAndLoginsContainerSelectedText: {
      color: COLOR.MAIN_BUTTON_TEXT,
      textAlign: "center",
    },

    investorButton: {
      borderColor: COLOR.ORANGE,
      borderWidth: 2,
    },
    investorButtonSelected: {
      backgroundColor: COLOR.ORANGE,
      borderColor: COLOR.ORANGE,
      borderWidth: 2,
    },
    investorButtonSelectedText: {
      color: COLOR.BLACK,
    },
  });
export default LoginScreen;

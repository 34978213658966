export const darkColors = {
  SCREEN_BACKGROUND: '#121212',
  HEADER_BACKGROUND: '#1E1E1E',
  HEADER_TEXT: '#FFFFFF',
  HEADER_ICON: '#FFFFFF',
  HEADER_BORDER: '#FFFFFF',

  CONTENT_BACKGROUND: '#1E1E1E',
  CONTENT_TEXT: '#FFFFFF',
  CONTENT_ICON: '#FFFFFF',
  CONTENT_BORDER: '#FFFFFF',

  BOTTOM_BACKGROUND: 'transparent',
  BOTTOM_BACKGROUND_FOCUSED: 'transparent',
  BOTTOM_TEXT: '#FFFFFF',
  BOTTOM_TEXT_FOCUSED: '#E30613',
  BOTTOM_ICON: '#FFFFFF',
  BOTTOM_ICON_FOCUSED: '#E30613',

  TEXT: '#FFFFFF',
  TEXT2: '#FFFFFF',
  TEXT_OPPOSITE: '#000000',
  TEXT_OPPOSITE2: '#000000',

  PROFIT: '#39B23E',
  LOSS: '#E30613',
  NATURAL: '#CACACA',

  MAIN_BUTTON_BACKGORUND: '#DF3024',
  MAIN_BUTTON_TEXT: '#FFFFFF',

  CARD_BACKGROUND1: '#303030',
  CARD_TEXT1: '#FFFFFF',
  CARD_BACKGROUND2: '#4F4F4F',
  CARD_TEXT2: '#FFFFFF',
  CARD_LOWER_OPACITY: 'rgba(158, 150, 150, .5)',
  CARD_DETAIL_BACKGROUND: '#555555',
  CARD_DETAIL_TEXT: '#FFFFFF',

  INPUT_BACKGROUND: '#2C2C2C',
  INPUT_TEXT: '#FFFFFF',

  BUY: '#39B23E',
  SELL: '#E30613',

  TAKE_PROFIT: '#39B23E',
  TAKE_PROFIT_TEXT: '#FFFFFF',
  STOP_LOSS: '#E30613',
  STOP_LOSS_TEXT: '#FFFFFF',
  STOP_OUT: '#F5A623',
  STOP_OUT_TEXT: '#000000',

  BUY_ORDER: '#39B23E',
  BUY_ORDER_TEXT: '#FFFFFF',
  SELL_ORDER: '#E30613',
  SELL_ORDER_TEXT: '#FFFFFF',
  BUY_LIMIT: '#39B23E',
  BUY_LIMIT_TEXT: '#FFFFFF',
  SELL_LIMIT: '#E30613',
  SELL_LIMIT_TEXT: '#FFFFFF',
  BUY_STOP: '#39B23E',
  BUY_STOP_TEXT: '#FFFFFF',
  SELL_STOP: '#E30613',
  SELL_STOP_TEXT: '#FFFFFF',

  BALANCE_BALANCE: '#007AFF',
  BALANCE_BALANCE_TEXT: '#FFFFFF',
  BALANCE_CREDIT: '#FFCC00',
  BALANCE_CREDIT_TEXT: '#000000',
  BALANCE_CORRECTION: '#F5A623',
  BALANCE_CORRECTION_TEXT: '#000000',
  BALANCE_BONUS: '#39B23E',
  BALANCE_BONUS_TEXT: '#FFFFFF',
  BALANCE_DEFAULT: '#313131',
  BALANCE_DEFAULT_TEXT: '#FFFFFF',

  SHADOW1: '#000000',
  SHADOW2: '#FFFFFF',
  SHADOW3: '#171717',

  CLOSED_MARKET: 'rgba(33,33,33,0.9)',
  CLOSED_MARKET_TEXT: '#E30613',

  TRADE_MODE_CLOSED: '#E30613',
  TRADE_MODE_CLOSED_TEXT: '#FFFFFF',
  TRADE_MODE_ONLY_BUY: '#FFCC00',
  TRADE_MODE_ONLY_SELL_TEXT: '#FFFFFF',
  TRADE_MODE_ONLY_SELL: '#FFCC00',
  TRADE_MODE_ONLY_BUY_TEXT: '#FFFFFF',
  TRADE_MODE_BUY_SELL: '#FFFFFF',
  TRADE_MODE_BUY_SELL_TEXT: '#000000',
  TRADE_MODE_ONLY_CLOSE: '#E30613',
  TRADE_MODE_ONLY_CLOSE_TEXT: '#FFFFFF',

  ACCEPT: '#39B23E',
  ACCEPT_TEXT: '#FFFFFF',
  REJECT: '#E30613',
  REJECT_TEXT: '#FFFFFF',
  THEME: 'dark',

  ACTIVITY_INDICATOR: '#E30613',

  RED: '#E30613',
  ORANGE: '#F5A623',
  GREEN: '#39B23E',
  BLUE: '#007AFF',
  YELLOW: '#FFCC00',
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  GRAY: '#313131',
  LIGHT_GRAY: '#F2F2F2',

  LAMB: '#FFF',

  BONUS_AVAILABLE: '#39B23E',
  BONUS_AVAILABLE_TEXT: '#FFFFFF',
  BONUS_UNAVAILABLE: '#E30613',
  BONUS_UNAVAILABLE_TEXT: '#FFFFFF',
  BONUS_COLLECTED: '#30d5c8',
  BONUS_COLLECTED_TEXT: '#FFFFFF',
  BONUS_PENDING: '#FFCC00',
  BONUS_PENDING_TEXT: '#000000',

  INVESTOR_BACKGROUND: '#ec942c',
  INVESTOR_TEXT: '#000',
};

export const lightColors = {
  SCREEN_BACKGROUND: '#F5F5F5',
  HEADER_BACKGROUND: '#FFFFFF',
  HEADER_TEXT: '#000000',
  HEADER_ICON: '#000000',
  HEADER_BORDER: '#000000',

  CONTENT_BACKGROUND: '#FFFFFF',
  CONTENT_TEXT: '#000000',
  CONTENT_ICON: '#000000',
  CONTENT_BORDER: '#000000',

  BOTTOM_BACKGROUND: 'transparent',
  BOTTOM_BACKGROUND_FOCUSED: 'transparent',
  BOTTOM_TEXT: '#000000',
  BOTTOM_TEXT_FOCUSED: '#E30613',
  BOTTOM_ICON: '#000000',
  BOTTOM_ICON_FOCUSED: '#E30613',

  TEXT: '#000000',
  TEXT2: '#000000',
  TEXT_OPPOSITE: '#FFFFFF',
  TEXT_OPPOSITE2: '#FFFFFF',

  PROFIT: '#39B23E',
  LOSS: '#E30613',
  NATURAL: '#CACACA',

  MAIN_BUTTON_BACKGORUND: '#DF3024',
  MAIN_BUTTON_TEXT: '#FFFFFF',

  CARD_BACKGROUND1: '#E4E4E4',
  CARD_TEXT1: '#000000',
  CARD_BACKGROUND2: '#CACACA',
  CARD_TEXT2: '#000000',
  CARD_LOWER_OPACITY: 'rgba(158, 150, 150, .5)',
  CARD_DETAIL_BACKGROUND: '#CCCCCC',
  CARD_DETAIL_TEXT: '#000000',

  INPUT_BACKGROUND: '#FFFFFF',
  INPUT_TEXT: '#000000',

  BUY: '#39B23E',
  SELL: '#E30613',

  TAKE_PROFIT: '#39B23E',
  TAKE_PROFIT_TEXT: '#FFFFFF',
  STOP_LOSS: '#E30613',
  STOP_LOSS_TEXT: '#FFFFFF',
  STOP_OUT: '#F5A623',
  STOP_OUT_TEXT: '#000000',

  BUY_ORDER: '#39B23E',
  BUY_ORDER_TEXT: '#FFFFFF',
  SELL_ORDER: '#E30613',
  SELL_ORDER_TEXT: '#FFFFFF',
  BUY_LIMIT: '#39B23E',
  BUY_LIMIT_TEXT: '#FFFFFF',
  SELL_LIMIT: '#E30613',
  SELL_LIMIT_TEXT: '#FFFFFF',
  BUY_STOP: '#39B23E',
  BUY_STOP_TEXT: '#FFFFFF',
  SELL_STOP: '#E30613',
  SELL_STOP_TEXT: '#FFFFFF',

  BALANCE_BALANCE: '#007AFF',
  BALANCE_BALANCE_TEXT: '#FFFFFF',
  BALANCE_CREDIT: '#FFCC00',
  BALANCE_CREDIT_TEXT: '#000000',
  BALANCE_CORRECTION: '#F5A623',
  BALANCE_CORRECTION_TEXT: '#000000',
  BALANCE_BONUS: '#39B23E',
  BALANCE_BONUS_TEXT: '#FFFFFF',
  BALANCE_DEFAULT: '#313131',
  BALANCE_DEFAULT_TEXT: '#FFFFFF',

  SHADOW1: '#000000',
  SHADOW2: '#FFFFFF',
  SHADOW3: '#171717',

  CLOSED_MARKET: 'rgba(255,255,255,0.9)',
  CLOSED_MARKET_TEXT: '#E30613',

  TRADE_MODE_CLOSED: '#E30613',
  TRADE_MODE_CLOSED_TEXT: '#FFFFFF',
  TRADE_MODE_ONLY_BUY: '#FFCC00',
  TRADE_MODE_ONLY_SELL_TEXT: '#FFFFFF',
  TRADE_MODE_ONLY_SELL: '#FFCC00',
  TRADE_MODE_ONLY_BUY_TEXT: '#FFFFFF',
  TRADE_MODE_BUY_SELL: '#FFFFFF',
  TRADE_MODE_BUY_SELL_TEXT: '#000000',
  TRADE_MODE_ONLY_CLOSE: '#E30613',
  TRADE_MODE_ONLY_CLOSE_TEXT: '#FFFFFF',

  ACCEPT: '#39B23E',
  ACCEPT_TEXT: '#FFFFFF',
  REJECT: '#E30613',
  REJECT_TEXT: '#FFFFFF',
  THEME: 'light',

  ACTIVITY_INDICATOR: '#E30613',

  RED: '#E30613',
  ORANGE: '#F5A623',
  GREEN: '#39B23E',
  BLUE: '#007AFF',
  YELLOW: '#FFCC00',
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  GRAY: '#313131',
  LIGHT_GRAY: '#F2F2F2',

  LAMB: '#FFCC00',

  BONUS_AVAILABLE: '#39B23E',
  BONUS_AVAILABLE_TEXT: '#FFFFFF',
  BONUS_UNAVAILABLE: '#E30613',
  BONUS_UNAVAILABLE_TEXT: '#FFFFFF',
  BONUS_COLLECTED: '#30d5c8',
  BONUS_COLLECTED_TEXT: '#FFFFFF',
  BONUS_PENDING: '#FFCC00',
  BONUS_PENDING_TEXT: '#000000',

  INVESTOR_BACKGROUND: '#ec942c',
  INVESTOR_TEXT: '#000',
};

import { useContext, useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";

// components
import MenuItem from "../../components/MenuItem";
import UserCard from "../../components/UserCard";

// constants
import { AuthContext } from "../../context/AuthContext";
import { useTheme } from "../../context/Theme/ThemeProvider";
import { formatNumber } from "../../utils/numberUtil";
import { infoCards, menuItems } from "./constants";
import InfoCard from "../Transaction/InfoCard";
import { InvestorWarningBox } from "../../components/InvestorWarningBox";

const Profile = ({ navigation }: any) => {
  const { user } = useContext(AuthContext);
  const { colors } = useTheme();

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: colors.SCREEN_BACKGROUND,
        paddingHorizontal: 10,
      }}
    >
      {user?.IsInvestor && <InvestorWarningBox/>}
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ backgroundColor: colors.SCREEN_BACKGROUND }}
      >
        <View style={styles.userCardContainer}>
          <UserCard
            username={`${user?.Name}`}
            number={user?.Phone || "Telefon kayıtlı değil"}
            imageSource={require("../../assets/trader1.png")}
            loginID={user?.ID || "Login ID yok"}
            isProile={true}
          />
        </View>

        <InfoCard />

        <View style={styles.menuContainer}>
          {menuItems.map((item: any) => (
            <MenuItem
              key={item.id}
              name={item.name}
              route={item.value}
              Icon={item.icon}
              extraProp={{ user }}
              navigation={navigation}
              isInvestor={!!user?.IsInvestor}
            />
          ))}
        </View>
      </ScrollView>
    </View>
  );
};

export default Profile;

const styles = StyleSheet.create({
  userCardContainer: { marginVertical: 10 },
  //card

  //menu
  menuContainer: {
    marginVertical: 10,
    gap: 7,
  },
});

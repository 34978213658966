import { Dispatch, SetStateAction } from "react";
import { Dimensions } from "react-native";

export const WIDTH = Dimensions.get("window").width;
export const HEIGHT = Dimensions.get("window").height;

export const formatNumber = (value: number, digits: number, showCurrency = true) => {
	const priceFormat = new Intl.NumberFormat('de-DE', {
		style: showCurrency ? 'currency' : 'decimal',
		currency: 'USD',
		minimumFractionDigits: digits ? digits : 2,
		maximumFractionDigits: digits ? digits : 2,
		useGrouping: true,
	});
	return priceFormat.format(value).replace(/\./g, ' ').replace(',', '.');
};

export const COLOR = {
  LIGHT: "#F2F2F2",
  LIGHT2: "#FAFAFA",
  LIGHT3: "#E7EAF3",
  DARK: "#000000",
  GRAY: "#313131",
  RED: "#E30613",
  ORANGE: "#F5A623",
  GREEN: "#39B23E",
};

export const GAP = {
  XS: 4,
  S: 6,
  M: 8,
  L: 10,
  XL: 12,
  XXL: 15,
};

export const RADIUS = {
  XS: 3,
  S: 5,
  M: 7,
  L: 10,
  XL: 12,
  XXL: 15,

  CARD: 5,
};

export const PADDING = {
  XS: 5,
  S: 8,
  M: 10,
  L: 12,
  XL: 15,
  XXL: 18,

  SCREEN: 8,
};

export const FONTSIZE = {
  xs: 8,
  s: 10,
  m: 12,
  l: 14,
  xl: 16,
  xxl: 20,
};
export const FONTWEIGHT = {
  xs: "200",
  s: "400",
  m: "600",
  l: "800",
  xl: "900",
};

export const CONSTANTS = {
  // CPP_URL: 'http://44.218.14.105:3051',
  CPP_URL: "https://terminal-api.pmprs.xyz:8443",
  // CPP_URL: 'http://localhost:3051',
  // CPP_URL: 'http://172.20.10.3:3051',
  // CPP_URL: 'http://192.168.1.112:8443',
};

export const sendError = (title: string, desc: string) => {
  //fetch('http://44.218.14.105:3004/create-log',{
  //  method:'POST',
  //  headers:{
  //    'Content-Type': 'application/json',
  //  },
  //  body:JSON.stringify({
  //    title:title,
  //    desc:desc,
  //  })
  //});
};

export interface IColors {
  SCREEN_BACKGROUND: string;
  HEADER_BACKGROUND: string;
  HEADER_TEXT: string;
  HEADER_ICON: string;
  HEADER_BORDER: string;
  CONTENT_BACKGROUND: string;
  CONTENT_TEXT: string;
  CONTENT_ICON: string;
  CONTENT_BORDER: string;
  BOTTOM_BACKGROUND: string;
  BOTTOM_BACKGROUND_FOCUSED: string;
  BOTTOM_TEXT: string;
  BOTTOM_TEXT_FOCUSED: string;
  BOTTOM_ICON: string;
  BOTTOM_ICON_FOCUSED: string;
  TEXT: string;
  TEXT2: string;
  TEXT_OPPOSITE: string;
  TEXT_OPPOSITE2: string;
  PROFIT: string;
  LOSS: string;
  NATURAL: string;
  MAIN_BUTTON_BACKGORUND: string;
  MAIN_BUTTON_TEXT: string;
  CARD_BACKGROUND1: string;
  CARD_TEXT1: string;
  CARD_BACKGROUND2: string;
  CARD_TEXT2: string;
  CARD_LOWER_OPACITY: string;
  CARD_DETAIL_BACKGROUND: string;
  CARD_DETAIL_TEXT: string;
  INPUT_BACKGROUND: string;
  INPUT_TEXT: string;
  BUY: string;
  SELL: string;
  TAKE_PROFIT: string;
  TAKE_PROFIT_TEXT: string;
  STOP_LOSS: string;
  STOP_LOSS_TEXT: string;
  STOP_OUT: string;
  STOP_OUT_TEXT: string;
  BUY_ORDER: string;
  BUY_ORDER_TEXT: string;
  SELL_ORDER: string;
  SELL_ORDER_TEXT: string;
  BUY_LIMIT: string;
  BUY_LIMIT_TEXT: string;
  SELL_LIMIT: string;
  SELL_LIMIT_TEXT: string;
  BUY_STOP: string;
  BUY_STOP_TEXT: string;
  SELL_STOP: string;
  SELL_STOP_TEXT: string;
  BALANCE_BALANCE: string;
  BALANCE_BALANCE_TEXT: string;
  BALANCE_CREDIT: string;
  BALANCE_CREDIT_TEXT: string;
  BALANCE_CORRECTION: string;
  BALANCE_CORRECTION_TEXT: string;
  BALANCE_BONUS: string;
  BALANCE_BONUS_TEXT: string;
  BALANCE_DEFAULT: string;
  BALANCE_DEFAULT_TEXT: string;
  SHADOW1: string;
  SHADOW2: string;
  SHADOW3: string;
  CLOSED_MARKET: string;
  CLOSED_MARKET_TEXT: string;
  TRADE_MODE_CLOSED: string;
  TRADE_MODE_CLOSED_TEXT: string;
  TRADE_MODE_ONLY_BUY: string;
  TRADE_MODE_ONLY_SELL_TEXT: string;
  TRADE_MODE_ONLY_SELL: string;
  TRADE_MODE_ONLY_BUY_TEXT: string;
  TRADE_MODE_BUY_SELL: string;
  TRADE_MODE_BUY_SELL_TEXT: string;
  TRADE_MODE_ONLY_CLOSE: string;
  TRADE_MODE_ONLY_CLOSE_TEXT: string;
  ACCEPT: string;
  ACCEPT_TEXT: string;
  REJECT: string;
  REJECT_TEXT: string;
  THEME: string;
  ACTIVITY_INDICATOR: string;
  RED: string;
  ORANGE: string;
  GREEN: string;
  BLUE: string;
  YELLOW: string;
  WHITE: string;
  BLACK: string;
  GRAY: string;
  LIGHT_GRAY: string;
  LAMB: string;
  BONUS_AVAILABLE: string;
  BONUS_AVAILABLE_TEXT: string;
  BONUS_UNAVAILABLE: string;
  BONUS_UNAVAILABLE_TEXT: string;
  BONUS_COLLECTED: string;
  BONUS_COLLECTED_TEXT: string;
  BONUS_PENDING: string;
  BONUS_PENDING_TEXT: string;
}

export interface IUser {
  Login: string;
  ID: string;
  Leverage: number;
  LimitPositionsValue: number;
  LimitOrders: number;
  Balance: number;
  Credit: number;
  BalancePrevDay: number;
  MarginFree: number;
  Margin: number;
  Name: string;
  Phone: string;
  Token: string;
  EMail: string;
  Group: string;
  Positions: Array<IPosition>;
  Orders: Array<IOrder>;
  FavoriteSymbols: Array<string>;
  IsInvestor: boolean;
}

export interface IPosition {
  Action: number;
  Digits: number;
  DigitsCurrency: number;
  ID: string;
  Position: number;
  PriceCurrent: number;
  PriceOpen: number;
  PriceSL: number;
  PriceTP: number;
  Profit: number;
  Storage: number;
  Symbol: string;
  TimeCreate: number;
  Volume: number;
  Reason?: number;
}

export interface IOrder {
  Digits: number;
  DigitsCurrency: number;
  ID: string;
  Order: number;
  PriceOrder: number;
  PriceSL: number;
  PriceTP: number;
  RateMargin: number;
  Reason: number;
  Symbol: string;
  TimeExpiration: number;
  TimeSetup: number;
  Type: number;
  TypeFill: number;
  TypeTime: number;
  VolumeCurrent: number;
  PriceCurrent: number;
}

export interface IGroup {
  Company: string;
  Currency: string;
  CurrencyDigits: number;
  Group: string;
  LimitHistory: number;
  LimitOrders: number;
  LimitPositions: number;
  LimitSymbols: number;
  MarginCall: number;
  MarginFlags: number;
  MarginFreeMode: number;
  MarginFreeProfitMode: number;
  MarginMode: number;
  MarginStopOut: number;
  Symbols: Array<IGroupSymbol>;
}

export interface IGroupSymbol {
  Path: string;
  SpreadDiff: number;
  SpreadDiffDefault: number;
  SpreadBalanceDiff: number;
  SpreadBalanceDiffDefault: number;
  Symbol: ISymbol;
  TradeMode: number;
  TradeModeDefault: number;
}

export interface ISymbol {
  isFavorited?: boolean;
  Category: string;
  CurrencyBase: string;
  CurrencyMargin: string;
  CurrencyProfit: string;
  Description: string;
  Digits: number;
  Exchange: string;
  FaceValue: number;
  International: string;
  MarginInitial: number;
  MarginLimit: number;
  MarginLong: number;
  MarginRateCurrency: number;
  MarginShort: number;
  MarginStop: number;
  MarginStopLimit: number;
  Path: string;
  PriceLimitMax: number;
  PriceLimitMin: number;
  QuotesTimeout: number;
  Sessions: ISession[][];
  Spread: number;
  SpreadBalance: number;
  StopsLevel: number;
  SwapLong: number;
  SwapMode: number;
  SwapShort: number;
  Symbol: string;
  TickValue: number;
  TimeExpiration: number;
  TimeStart: number;
  VolumeLimit: number;
  VolumeMax: number;
  VolumeMin: number;
  VolumeStep: number;
  Tick: ITick;
  TradeMode: number;

  SwapRateMonday: number;
  SwapRateTuesday: number;
  SwapRateWednesday: number;
  SwapRateThursday: number;
  SwapRateFriday: number;
  SwapRateSaturday: number;
  SwapRateSunday: number;
  ContractSize: number;
}
export type ISession = {
  Open: number;
  Close: number;
};

export interface ILogout {
  message?: string;
  type?: "success" | "error" | "warning" | "info";
}

export interface ITick {
  Symbol: string;
  Ask: number;
  Bid: number;
  Datetime: number;
}
export interface IAuthContext {
  isLoggedIn: boolean;
  user?: IUser;
  userPass: any;
  checkLogin: any;
  logout: ({ message, type }: ILogout) => void;
  orders?: Array<IOrder>;
  positions?: Array<IPosition>;
  group?: IGroup;
  serverIP: any;
  setFavorite: any;
  setOpenRequests: Dispatch<SetStateAction<IOpenRequest>>;
  openRequests: IOpenRequest;
  isWebView: boolean;
  setIsWebView: Dispatch<SetStateAction<boolean>>;
}

export interface IOpenRequest {
  [Symbol: string]: {
    ID: number;
    Datetime: number;
  };
}

import { AntDesign, MaterialIcons } from "@expo/vector-icons";
import React from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";

class FlowMessageManager {
  static instance: FlowMessageManager;
  static getInstance() {
    if (!FlowMessageManager.instance) {
      FlowMessageManager.instance = new FlowMessageManager();
    }
    return FlowMessageManager.instance;
  }

  _flowMessage: FlowMessage | null = null;

  setFlowMessage(flowMessage: FlowMessage) {
    this._flowMessage = flowMessage;
  }

  show(flowMessageShowProps: FlowMessageShowProps) {
    if (this._flowMessage) {
      this._flowMessage.show(flowMessageShowProps);
    }
  }
}

export const showFlowMessage = (flowMessageShowProps: FlowMessageShowProps) => {
  FlowMessageManager.getInstance().show(flowMessageShowProps);
};

// export const FlowMessageManagerContext = React.createContext(FlowMessageManager.getInstance());

// export const useFlowMessageManager = () => {
// 	return React.useContext(FlowMessageManagerContext);
// };

export default class FlowMessage extends React.Component<
  FlowMessageProps,
  FlowMessageState
> {
  duration: number = 1500;
  position: "top" | "bottom" = "top";

  constructor(props: FlowMessageProps) {
    super(props);

    this.state = {
      messages: [],
    };

    FlowMessageManager.getInstance().setFlowMessage(this);

    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
  }

  show(flowMessageShowProps: FlowMessageShowProps) {
    const now = Date.now();
    const message = {
      type: flowMessageShowProps.type ?? "info",
      title: flowMessageShowProps.title,
      message: flowMessageShowProps.message,
      duration: flowMessageShowProps.duration || this.duration,
      key: flowMessageShowProps.message + now,
    };

    this.setState(
      (prevState: any) => {
        return {
          messages: [...prevState.messages, message],
        };
      },
      () => {
        setTimeout(() => this.hide(message), message.duration);
      }
    );
  }

  hide(message: FlowMessageShowItemProps) {
    this.setState((prevState: any) => {
      const index = prevState.messages.findIndex((msg: any) => msg === message);
      if (index > -1) {
        prevState.messages.splice(index, 1);
      }
      return {
        messages: [...prevState.messages],
      };
    });
  }

  render() {
    return (
      <View style={{ position: "relative", zIndex: 9999 }}>
        <View style={styles.container}>
          {this.state.messages.map((message: FlowMessageShowItemProps) => (
            <TouchableOpacity
              style={{
                ...styles.wrapper,
                ...styles[`wrapper_${message.type}`],
              }}
              onPress={() => this.hide(message)}
              key={message.key}
            >
              {message.type === "success" && (
                <AntDesign name="check" size={24} color="#FFF" />
              )}
              {message.type === "error" && (
                <MaterialIcons name="error-outline" size={24} color="#FFF" />
              )}
              {message.type === "info" && (
                <AntDesign name="infocirlceo" size={24} color="#FFF" />
              )}
              {message.type === "warning" && (
                <AntDesign name="warning" size={24} color="#FFF" />
              )}

              <View style={styles.text_container}>
                {!!message.title && (
                  <Text
                    style={{
                      ...styles.title,
                      ...styles[`title_${message.type}`],
                    }}
                  >
                    {message.title}
                  </Text>
                )}
                <Text
                  style={{ ...styles.text, ...styles[`text_${message.type}`] }}
                >
                  {message.message}
                </Text>
              </View>
            </TouchableOpacity>
          ))}
        </View>
      </View>
    );
  }
}

interface FlowMessageProps {
  position?: "top" | "bottom";
  duration?: number;
}

interface FlowMessageShowProps {
  type?: "success" | "error" | "warning" | "info";
  title?: string;
  message: string;
  duration?: number;
}

interface FlowMessageShowItemProps {
  type: "success" | "error" | "warning" | "info";
  title?: string;
  message: string;
  duration?: number;
  key: string;
}

// interface FlowMessageShowItemProps extends FlowMessageShowProps {
// 	type: 'success' | 'error' | 'warning' | 'info';
// }

interface FlowMessageState {
  messages: FlowMessageShowItemProps[];
}

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    backgroundColor: "#FFF",
    gap: 1,
  },
  wrapper: {
    flex: 1,
    flexDirection: "row",
    padding: 20,
    gap: 10,
  },
  title: {
    fontSize: 16,
    fontWeight: "700",
  },
  text_container: {
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  text: {
    fontSize: 16,
    fontWeight: "500",
  },
  wrapper_success: {
    backgroundColor: "#198754",
  },
  text_success: {
    color: "white",
  },
  title_success: {
    color: "white",
  },
  wrapper_error: {
    backgroundColor: "#dc3545",
  },
  text_error: {
    color: "white",
  },
  title_error: {
    color: "white",
  },
  wrapper_info: {
    backgroundColor: "#0d6efd",
  },
  text_info: {
    color: "white",
  },
  title_info: {
    color: "white",
  },
  wrapper_warning: {
    backgroundColor: "#fd7e14",
  },
  text_warning: {
    color: "white",
  },
  title_warning: {
    color: "white",
  },
});

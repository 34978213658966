import dayjs, { ManipulateType } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import config from '../config';
dayjs.extend(utc);


export const now = () => {
  // return dayjs().add(config.serverTimeZone, 'minutes');
  // return dayjs().add(-config.serverTimeDiff, 'milliseconds');
  return dayjs();
};

export const newDayjs = (date: string | Date | number | null) => {
  return dayjs(date).add(-config.serverTimeDiff, 'milliseconds');
};
newDayjs.unix = (date: number) => {
  return dayjs.unix(date);
};

export const newDayjsWithFormat = (date: string | Date | number | null) => {
  return dayjs(date).add(-config.serverTimeDiff, 'milliseconds').format('YYYY-MM-DD HH:mm');
};
export const newDayjsWithTodayFormat = (date: string | Date | number | null) => {
  return dayjs(date).add(-config.serverTimeDiff, 'milliseconds').format('HH:mm:ss');
};
export const newDayjsNowWithFormat = (format: string) => {
  return dayjs().format(format);
};
export const newDayjsNowWithSubtractAndFormat = (subtractValue: number, subtractKey: ManipulateType, format: string) => {
  return dayjs().subtract(subtractValue, subtractKey).format(format);
};

export const dateUtil = (date: string | Date | number | null) => {
  return dayjs(date).add(-config.serverTimeDiff, 'milliseconds');
};

export const dateStr = (milisecond: String) => {
  return dayjs.unix(Number(milisecond)).format('YYYY-MM-DD HH:mm');
}

export const dateToISO = (date: Date) => {
  return dayjs(date).add(-config.serverTimeDiff, 'milliseconds').toISOString();
}

export {dayjs};
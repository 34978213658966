import React, {Dispatch} from 'react';
import {Pressable, PressableStateCallbackType, StyleProp, StyleSheet, Text, TextStyle, ViewStyle} from 'react-native';
import {useTheme} from '../../context/Theme/ThemeProvider';
import {IColors} from '../../Global';

interface IMainButton {
  onPress: () => void;
  buttonStyleFullControl?: StyleProp<ViewStyle> | ((state: PressableStateCallbackType) => StyleProp<ViewStyle>) | undefined;
  buttonStyle?: StyleProp<ViewStyle> | undefined;

  text: string;
  textStyle?: StyleProp<TextStyle> | undefined;

  disabled?: boolean;
}

const index = ({onPress, buttonStyle, buttonStyleFullControl, text, textStyle, disabled}: IMainButton) => {
  const {colors} = useTheme();
  const defaultStyles = DefaultStyles(colors);

  if (buttonStyleFullControl) {
    return (
      <Pressable style={buttonStyleFullControl} onPress={onPress}>
        <Text style={textStyle ? textStyle : defaultStyles.text}> {text} </Text>
      </Pressable>
    );
  } else {
    return (
      <Pressable
        disabled={disabled}
        style={({pressed}) => [
          {
            opacity: pressed ? 0.8 : 1,
          },
          disabled?{opacity:0.4}:{opacity:1},
          buttonStyle ? buttonStyle : defaultStyles.pressable,
        ]}
        onPress={onPress}>
        <Text style={textStyle ? textStyle : defaultStyles.text}> {text} </Text>
      </Pressable>
    );
  }
};

const DefaultStyles = (colors: IColors) =>
  StyleSheet.create({
    pressable: {
      backgroundColor: colors.MAIN_BUTTON_BACKGORUND,
      borderRadius: 5,
    },
    text: {
      color: colors.MAIN_BUTTON_TEXT,
      padding: 10,
      textAlign: 'center',
    },
  });

export default index;

/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 */

import React from "react";
import { SafeAreaView } from "react-native";
import FlowMessage from "./components/FlowMessage";
import Router from "./Router";
import { LanguageProvider } from "./context/Language/LanguageProvider";
import { ThemeProvider } from "./context/Theme/ThemeProvider";
import { AuthProvider } from "./context/AuthContext";

function App(): JSX.Element {
  return (
    <AuthProvider>
      <SafeAreaView style={{ flex: 1 }}>
        <FlowMessage />
        <ThemeProvider>
          <LanguageProvider>
            <Router />
          </LanguageProvider>
        </ThemeProvider>
      </SafeAreaView>
    </AuthProvider>
  );
}

export default App;

import { IColors, IGroupSymbol } from '../Global';
import { newDayjs, now } from './dateUtil';

export const marketStatusControllerConst = [
	{ title: 'Market Kapalı', id: 0, isClosed: true, buyBlock: true, sellBlock: true},
	{ title: 'Sadece Alış İşlemi Yapılabilir', id: 1, isClosed: false, buyBlock: false, sellBlock: true},
	{ title: 'Sadece Satış İşlemi Yapılabilir', id: 2, isClosed: false, buyBlock: true, sellBlock: false},
	{ title: 'Yalnızca Kapatma İşlemi Yapılabilir', id: 3, isClosed: false, buyBlock: true, sellBlock: true},
	{ title: 'İşleme Açık', id: 4, isClosed: false, buyBlock: false, sellBlock: true},
  { title: 'Kotasyon Bekleniyor', id: 5, isClosed: true, buyBlock: true, sellBlock: true},
];

export function marketStatusController(groupSymbol: IGroupSymbol, lastTickDate: number) {
  const tradeMode = groupSymbol.TradeMode;
  if (tradeMode == 0) {
    return marketStatusControllerConst[0];
  }

  const isClosedForSessions = !isMarketOpen(groupSymbol.Symbol.Sessions);
  if (isClosedForSessions) {
    return marketStatusControllerConst[0];
  }
  
  const isClosedForLastTickDate = newDayjs.unix(lastTickDate).add(groupSymbol.Symbol.QuotesTimeout, 'seconds').isBefore(now());
  if (isClosedForLastTickDate) {
    return marketStatusControllerConst[5];
  }

  
	if (tradeMode == 1 || tradeMode == 2 || tradeMode == 3) {
    return marketStatusControllerConst[tradeMode];
  }
  
  return marketStatusControllerConst[4];
}

export const isMarketOpen = (sessions: any) => {
	const today = new Date();
	const dayOfWeek = today.getDay();
	const todaysSessions = sessions[dayOfWeek];

	let isMarketOpen = false;
	for (let i = 0; i < todaysSessions.length; i++) {
		const { Open, Close } = todaysSessions[i];
		const openDate = new Date();
		const closeDate = new Date();
		openDate.setHours(0, Number(Open), 0);
		closeDate.setHours(0, Number(Close), 0);
		if (today > openDate && today < closeDate) {
			isMarketOpen = true;
			break;
		}
	}
	return isMarketOpen;
};

export const findTradeMode = (mode: number, isClosed: boolean, colors: IColors) => {
	if (isClosed) {
		return {
			title: 'Kotasyon güncelleniyor.',
			color: colors.TRADE_MODE_ONLY_BUY_TEXT,
			bgColor: colors.TRADE_MODE_ONLY_BUY,
			isClosed: true,
			isShowing: true,
		};
	} else {
		switch (mode) {
			case 0:
				return {
					title: 'Market Kapalı',
					color: colors.TRADE_MODE_CLOSED_TEXT,
					bgColor: colors.TRADE_MODE_CLOSED,
					isClosed: true,
					isShowing: true,
				};
			case 1:
				return {
					title: 'Sadece Alış İşlemi Yapılabilir',
					color: colors.TRADE_MODE_ONLY_BUY_TEXT,
					bgColor: colors.TRADE_MODE_ONLY_BUY,
					isClosed: false,
					isShowing: true,
				};
			case 2:
				return {
					title: 'Sadece Satış İşlemi Yapılabilir',
					color: colors.TRADE_MODE_ONLY_SELL_TEXT,
					bgColor: colors.TRADE_MODE_ONLY_SELL,
					isClosed: false,
					isShowing: true,
				};
			case 3:
				return {
					title: 'Yalnızca Kapatma İşlemi Yapılabilir',
					color: colors.TRADE_MODE_ONLY_CLOSE_TEXT,
					bgColor: colors.TRADE_MODE_ONLY_CLOSE,
					isClosed: true,
					isShowing: true,
				};
			case 4:
				return {
					title: 'İşleme Açık',
					color: colors.TRADE_MODE_BUY_SELL_TEXT,
					bgColor: colors.TRADE_MODE_BUY_SELL,
					isClosed: false,
					isShowing: false,
				};

			default:
				return {
					title: 'İşleme Açık',
					color: colors.TRADE_MODE_BUY_SELL_TEXT,
					bgColor: colors.TRADE_MODE_BUY_SELL,
					isClosed: false,
					isShowing: false,
				};
		}
	}
};

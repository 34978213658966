import {View, Text, StyleSheet, Pressable} from 'react-native';
import React from 'react';
import { useTheme } from '../../context/Theme/ThemeProvider';

const ReconmmendedActions = () => {
  const {colors} = useTheme();
  const styles = Styles(colors);

  return (
    <View style={styles.view}>
      <Pressable
        style={({pressed}) => [
          {
            opacity: pressed ? 0.8 : 1,
          },
          styles.container,
        ]}>
        <Text style={styles.text}>Günün Önerilen İşlemleri (3)</Text>
      </Pressable>
    </View>
  );
};

export default ReconmmendedActions;

const Styles = (colors:any) => StyleSheet.create({
  view: {
    flex: 1,
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.MAIN_BUTTON_BACKGORUND,
    borderRadius: 4,
    padding: 6,
  },

  text: {
    color: colors.MAIN_BUTTON_TEXT,
    fontSize: 18,
    fontWeight: '500',
  },
});

import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { useTheme } from "../../context/Theme/ThemeProvider";
import { showMessage } from "react-native-flash-message";
import {
  AntDesign,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons";
import { NoVisitorsAllowed } from "../../config/visitor";
import { showFlowMessage } from "../FlowMessage";
import { useLanguage } from "../../context/Language/LanguageProvider";

interface Props {
  route: string;
	name: string;
	Icon: any;
	isInvestor: boolean;
	navigation?: any;
	extraProp?: any;
}

const routes = [
  "DepositSelection",
  "Withdraw",
  "Profile",
  "Transaction",
  "CustomerComplaint",
  "References",
  "ChangePassword",
  "Settings",
];

const index: React.FC<Props> = ({ route, name, isInvestor, Icon, extraProp, navigation }: Props) => {
  if (isInvestor && NoVisitorsAllowed.includes(route)) {
		return <View style={{ display: 'none' }}></View>;
	}
  const { colors } = useTheme();
  const { words } = useLanguage();
  const styles = Styles(colors);

  const menuItems = [
    {
      id: 0,
      name: "Arkadaşlarınla Paylaş",
      value: "share",
      icon: <MaterialCommunityIcons name="share" size={24} color={colors.TEXT}/>,
    },
    {
      id: 1,
      name: "Para Yatır",
      value: "DepositSelection",
      icon: <MaterialCommunityIcons name="cash-fast" size={24} color={colors.TEXT}/>,
    },
    {
      id: 2,
      name: "Para Çek",
      value: "Withdraw",
      icon: <MaterialCommunityIcons name="cash-fast" size={24} color={colors.TEXT}/>,
    },
    {
      id: 3,
      name: "Bildirimler",
      value: "notifications",
      icon: <Ionicons name="notifications" size={24} color={colors.TEXT}/>,
    },
    {
      id: 4,
      name: "Hesap Özeti",
      value: "Profile",
      icon: <MaterialCommunityIcons name="cash-register" size={24} color={colors.TEXT}/>,
    },
    {
      id: 5,
      name: "Şifre Değiştir",
      value: "ChangePassword",
      icon: <MaterialCommunityIcons name="onepassword" size={24} color={colors.TEXT}/>,
    },
    {
      id: 6,
      name: "Traderlar",
      value: "Traders",
      icon: <Ionicons name="people" size={24} color={colors.TEXT}/>,
    },
    {
      id: 7,
      name: "Robotlar",
      value: "Robots",
      icon: <MaterialCommunityIcons name="robot-excited-outline" size={24} color={colors.TEXT}/>,
    },
    {
      id: 8,
      name: "Canlı Destek",
      value: "LiveSupport",
      icon: <MaterialIcons name="live-help" size={24} color={colors.TEXT}/>,
    },
    {
      id: 9,
      name: "Müşteri Şikayet",
      value: "CustomerComplaint",
      icon: <AntDesign name="customerservice" size={24} color={colors.TEXT}/>,
    },
    {
      id: 10,
      name: "İşlemlerim",
      value: "Transaction",
      icon: <MaterialCommunityIcons name="history" size={24} color={colors.TEXT}/>,
    },
    {
      id: 11,
      name: "Referanslar",
      value: "References",
      icon: <MaterialCommunityIcons name="share-variant" size={24} color={colors.TEXT}/>,
    },
    // {
    //   id: 9,
    //   name: "Ayarlar",
    //   value: "Settings",
    //   icon: <MaterialIcons name="settings" size={24} color={colors.TEXT}/>,
    // },
  ];

  const handlePress = () => {
    try {
      if (routes.includes(route)) {
        navigation.navigate(route, extraProp);
      } else {
        showFlowMessage({
					message: words['FlowMessage_UnderConstruction'],
					type: 'warning',
					duration: 1500,
				});
			}
		} catch (err) {
			showFlowMessage({
				message: words['FlowMessage_UnderConstruction'],
				type: 'warning',
				duration: 1500,
			});
      console.log(err);
    }
    // }
  };

  return (
    <TouchableOpacity style={styles.menuItem} onPress={handlePress}>
      {menuItems.find((item) => item.name == name)?.icon}
      <Text style={styles.menuText}>{name}</Text>
    </TouchableOpacity>
  );
};

export default index;

const Styles = (COLOR: any) =>
  StyleSheet.create({
    menuItem: {
      flexDirection: "row",
      alignItems: "center",
      gap: 10,
      paddingHorizontal: 10,
      paddingVertical: 9,
      borderRadius: 5,

      backgroundColor: COLOR.CARD_BACKGROUND1,
      color: COLOR.TEXT,
    },
    menuText: {
      fontSize: 16,
      color: COLOR.TEXT,
    },
  });

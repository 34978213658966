import React, {useContext, useEffect, useState} from 'react';
import {Button, View} from 'react-native';
import InputCard from '../components/InputCard';
import InfoCard from '../components/InfoCard';
import {showMessage} from 'react-native-flash-message';
import {AuthContext} from '../context/AuthContext';
import {useTheme} from '../context/Theme/ThemeProvider';
import MainButton from '../components/MainButton';
import apiCall from '../utils/apiCall';
import { showFlowMessage } from '../components/FlowMessage';

const ChangePassword = () => {
  const {logout, user, userPass} = useContext(AuthContext);
  const {colors} = useTheme();

  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordCHK, setNewPasswordCHK] = useState('');

  const [buttonsState, setButtonsState] = useState<boolean>(false);

  function save() {
    console.log('lololo');

    apiCall(
      '/change-password',
      {
        password,
        newPassword,
      },
      'POST',
    )
      .then(response => {
        console.log(response);
        logout({message:'Şifreniz Değiştirildi. Yeniden Giriş Yapınız.', type: 'success'})
      })
      .catch(err => {
        console.error(err);
        showFlowMessage({
          message: 'Bir Sorun Meydana Geldi. Şifreniz değiştirilemedi. Daha Sonra Tekrar Deneyiniz.',
          type: 'warning',
          duration: 800,
        });
      });
  }

  useEffect(() => {
    if (newPassword == newPasswordCHK && newPassword != userPass && password == userPass) {
      let check = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/;
      if (newPassword.match(check)) {
        console.log('Your password is strong.');
        setButtonsState(true);
      } else {
        console.log('Meh, not so much.');
      }
    } else {
      setButtonsState(false);
    }
  }, [newPassword, newPasswordCHK, password]);

  useEffect(() => {
    setButtonsState(false);
  }, [user]);

  return (
    <View style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 20, padding: 10, flex: 1, backgroundColor: colors.SCREEN_BACKGROUND}}>
      <View style={{width: '100%', gap: 5}}>
        <InfoCard
          type="basic"
          args={{title: 'Müşteri No', value: user?.ID}}
        />
        <InputCard
          type="password"
          args={{title: 'Güncel Şifre', value: password}}
          response={setPassword}
        />
        <InputCard
          type="password"
          args={{title: 'Yeni Şifre', value: newPassword}}
          response={setNewPassword}
        />
        <InputCard
          type="password"
          args={{title: 'Yeni Şifre Tekrar', value: newPasswordCHK}}
          response={setNewPasswordCHK}
        />
      </View>
      <View style={{width: '100%', flexDirection: 'row', justifyContent: 'center'}}>
        <View style={{flexBasis: '45%', borderRadius: 10}}>
          <MainButton
            disabled={!buttonsState}
            onPress={save}
            text={'Kaydet'}
          />
        </View>
      </View>
    </View>
  );
};

export default ChangePassword;

// icons
const infoCards = [
  {
    id: 1,
    title: 'Bakiye',
    price: 0.0,
    key: 'Balance',
    
  },
  {
    id: 2,
    title: 'Varlık', // Net Değer
    price: 0.0,
    key: 'Equity',
  },
  {
    id: 3,
    title: 'Kredi',
    key: 'Credit',
    price: 0.0,
  },
  {
    id: 4,
    title: 'Kullanılan Teminat',
    key: 'Margin',
    price: 0.0,
  },
  {
    id: 5,
    title: 'Serbest Teminat', // Kullanılabilir
    price: 0.0,
    key: 'MarginFree',
  },
  {
    id: 6,
    title: 'Risk Rasyosu',
    price: 0.0,
    key: 'MarginLevel',
  },
];

const menuItems = [
  {
    id: 0,
    name: 'Arkadaşlarınla Paylaş',
    value: 'ShareWithFriends',
  },
 {
   id: 1,
   name: 'Para Yatır',
   value: 'DepositSelection',
 },
 {
   id: 2,
   name: 'Para Çek',
   value: 'Withdraw',
 },
  // {
  //   id: 3,
  //   name: 'Kişisel Bilgilerim',
  //   value: 'PersonalInformation',
  //   icon: Profil,
  // },
  {
    id: 3,
    name: 'Şifre Değiştir',
    value: 'ChangePassword',
  },
  {
    id: 4,
    name: 'İşlemlerim',
    value: 'Transaction',
  },
  //{
  //  id: 5,
  //  name: 'Kimlik Doğrulama',
  //  value: 'traders',
  //},
  {
    id: 5,
    name: 'Referanslar',
    value: 'References',
  },
];
const kapananPozisyonlar = [
  {
    id: 1,
    name: 'İşlem Tipi',
    key: 'Action',
  },
  {
    id: 2,
    name: 'Sembol',
    key: 'Symbol',
  },
  {
    id: 4,
    name: 'Tarih',
    key: 'Time',
  },
  {
    id: 5,
    name: 'Hacim',
    key: 'Volume',
  },
  {
    id: 6,
    name: 'Fiyat',
    key: 'Price',
  },
  {
    id: 7,
    name: 'Kar/Zarar',
    key: 'Profit',
  },
];

const transactions = [
  {
    Deal: '1538072',
    ExternalID: '',
    Login: '90518',
    Dealer: '0',
    Order: '1520759',
    Action: '0',
    Entry: '1',
    Reason: '0',
    Digits: '5',
    DigitsCurrency: '2',
    ContractSize: '100000.00',
    Time: '1683223925',
    TimeMsc: '1683223925255',
    Symbol: 'EURUSD',
    Price: '1.09957',
    Volume: '1000',
    VolumeExt: '10000000',
    Profit: '+43.50',
    Storage: '0.00',
    Commission: '0.00',
    Fee: '0.00',
    RateProfit: '1.00000000',
    RateMargin: '1.09957000',
    ExpertID: '0',
    PositionID: '1520757',
    Comment: '',
    ProfitRaw: '-43.50',
    PricePosition: '1.10392',
    PriceSL: '0.00000',
    PriceTP: '0.00000',
    VolumeClosed: '1000',
    VolumeClosedExt: '10000000',
    TickValue: '0.00000000',
    TickSize: '0.00000000',
    Flags: '0',
    Gateway: '',
    PriceGateway: '0.00000',
    ModifyFlags: '0',
    Value: '0.00',
    ApiData: [
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
    ],
    MarketBid: '1.09957',
    MarketAsk: '1.09967',
    MarketLast: '0.00000',
  },
  {
    Deal: '1538108',
    ExternalID: '',
    Login: '90518',
    Dealer: '0',
    Order: '1520797',
    Action: '1',
    Entry: '1',
    Reason: '2',
    Digits: '5',
    DigitsCurrency: '2',
    ContractSize: '100000.00',
    Time: '1683732567',
    TimeMsc: '1683732567730',
    Symbol: 'EURUSD',
    Price: '1.09440',
    Volume: '100000',
    VolumeExt: '1000000000',
    Profit: '-9520.00',
    Storage: '0.00',
    Commission: '0.00',
    Fee: '0.00',
    RateProfit: '1.00000000',
    RateMargin: '1.09440000',
    ExpertID: '0',
    PositionID: '1520755',
    Comment: '',
    ProfitRaw: '-9520.00',
    PricePosition: '1.10392',
    PriceSL: '0.00000',
    PriceTP: '0.00000',
    VolumeClosed: '100000',
    VolumeClosedExt: '1000000000',
    TickValue: '0.00000000',
    TickSize: '0.00000000',
    Flags: '0',
    Gateway: '',
    PriceGateway: '0.00000',
    ModifyFlags: '0',
    Value: '0.00',
    ApiData: [
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
    ],
    MarketBid: '1.09440',
    MarketAsk: '1.09450',
    MarketLast: '0.00000',
  },
  {
    Deal: '1538121',
    ExternalID: '',
    Login: '90518',
    Dealer: '0',
    Order: '1520810',
    Action: '1',
    Entry: '1',
    Reason: '2',
    Digits: '5',
    DigitsCurrency: '2',
    ContractSize: '100000.00',
    Time: '1683799275',
    TimeMsc: '1683799275847',
    Symbol: 'EURUSD',
    Price: '1.09319',
    Volume: '1',
    VolumeExt: '10000',
    Profit: '-0.11',
    Storage: '0.00',
    Commission: '0.00',
    Fee: '0.00',
    RateProfit: '1.00000000',
    RateMargin: '1.09319000',
    ExpertID: '0',
    PositionID: '1520756',
    Comment: '',
    ProfitRaw: '-0.11',
    PricePosition: '1.10393',
    PriceSL: '0.00000',
    PriceTP: '0.00000',
    VolumeClosed: '1',
    VolumeClosedExt: '10000',
    TickValue: '0.00000000',
    TickSize: '0.00000000',
    Flags: '0',
    Gateway: '',
    PriceGateway: '0.00000',
    ModifyFlags: '0',
    Value: '0.00',
    ApiData: [
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
    ],
    MarketBid: '1.09319',
    MarketAsk: '1.09329',
    MarketLast: '0.00000',
  },
  {
    Deal: '1538122',
    ExternalID: '',
    Login: '90518',
    Dealer: '0',
    Order: '1520811',
    Action: '1',
    Entry: '1',
    Reason: '2',
    Digits: '5',
    DigitsCurrency: '2',
    ContractSize: '100000.00',
    Time: '1683799414',
    TimeMsc: '1683799414935',
    Symbol: 'EURGBP',
    Price: '0.86797',
    Volume: '2',
    VolumeExt: '20000',
    Profit: '-0.03',
    Storage: '0.00',
    Commission: '0.00',
    Fee: '0.00',
    RateProfit: '1.25914000',
    RateMargin: '1.09308000',
    ExpertID: '0',
    PositionID: '1520809',
    Comment: '',
    ProfitRaw: '-0.02',
    PricePosition: '0.86894',
    PriceSL: '0.00000',
    PriceTP: '0.00000',
    VolumeClosed: '2',
    VolumeClosedExt: '20000',
    TickValue: '0.00000000',
    TickSize: '0.00000000',
    Flags: '0',
    Gateway: '',
    PriceGateway: '0.00000',
    ModifyFlags: '0',
    Value: '0.00',
    ApiData: [
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
    ],
    MarketBid: '0.86797',
    MarketAsk: '0.86817',
    MarketLast: '0.00000',
  },
  {
    Deal: '1538124',
    ExternalID: '',
    Login: '90518',
    Dealer: '0',
    Order: '1520813',
    Action: '1',
    Entry: '1',
    Reason: '2',
    Digits: '5',
    DigitsCurrency: '2',
    ContractSize: '100000.00',
    Time: '1683799654',
    TimeMsc: '1683799654711',
    Symbol: 'EURGBP',
    Price: '0.86806',
    Volume: '2',
    VolumeExt: '20000',
    Profit: '-0.01',
    Storage: '0.00',
    Commission: '0.00',
    Fee: '0.00',
    RateProfit: '1.25894000',
    RateMargin: '1.09297000',
    ExpertID: '0',
    PositionID: '1520806',
    Comment: '',
    ProfitRaw: '-0.01',
    PricePosition: '0.86853',
    PriceSL: '0.00000',
    PriceTP: '0.00000',
    VolumeClosed: '2',
    VolumeClosedExt: '20000',
    TickValue: '0.00000000',
    TickSize: '0.00000000',
    Flags: '0',
    Gateway: '',
    PriceGateway: '0.00000',
    ModifyFlags: '0',
    Value: '0.00',
    ApiData: [
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
      {
        AppID: '0',
        ID: '0',
        ValueInt: '0',
        ValueUInt: '0',
        ValueDouble: '0.00000000',
      },
    ],
    MarketBid: '0.86806',
    MarketAsk: '0.86828',
    MarketLast: '0.00000',
  },
];

const reportTypes = [
  'Anlaşmalar',
  'Kapanan Pozisyonlar',
  'Kapanan Emirler',
  'Bakiye Geçmişi',
];

export {infoCards, kapananPozisyonlar, reportTypes, transactions, menuItems};

interface ILanguage {
	[key: string]: string;
}

export const Arabic: ILanguage = {
	Exit: 'خروج',
	Login: 'تسجيل الدخول',
	LoginID: 'ID',
	UserName: 'اسم المستخدم',
	Password: 'كلمة المرور',
	Buy: 'شراء',
	Sell: 'بيع',
	Profit: 'ربح',
	Loss: 'خسارة',
	Home: 'الرئيسية',
	Markets: 'الأسواق',
	Transaction: 'معاملاتي',
	Profile: 'الملف الشخصي',
	Spread: 'الفارق',
	Search: 'بحث',
	Name: 'الاسم',
	FirstName: 'الاسم الأول',
	LastName: 'الاسم الأخير',
	Email: 'البريد الإلكتروني',
	Phone: 'الهاتف',
	Amount: 'المبلغ',
	Button_SubmitDepositRequest: 'تقديم طلب الإيداع',
	Button_SubmitWithdrawRequest: 'تقديم طلب سحب الأموال',
	Button_SubmitYourComplaint: 'تقديم شكواك',
	Position: 'الوضع',
	Order: 'الأمر',
	Login_RegisteredAccounts: 'الحسابات المسجلة',
	Login_Servers: 'الخوادم',
	Login_NoRegisteredAccounts: 'لم يتم العثور على حسابات مسجلة.',
	Login_ToolTip_1: 'تم تحديث شاشة الدخول.',
	Login_ToolTip_2: 'بعد إدخال اسم المستخدم وكلمة المرور، يقوم النقر فوق زر "تسجيل الدخول" بتسجيل الدخول وحفظ معلومات الدخول الخاصة بك.',
	Login_ToolTip_3: 'إذا قمت بالضغط والاحتفاظ بزر "تسجيل الدخول"، يتم تسجيل الدخول دون حفظ معلومات الدخول.',
	Login_ToolTip_4: 'عندما تكون هناك مستخدمين مسجلين، يمكنك تسجيل الدخول باستخدام أي مستخدم تريده.',
	Login_ToolTip_5: 'إذا قمت بالضغط والاحتفاظ بمستخدم مسجل، يمكنك إزالة الحساب المضغوط من الحسابات المحفوظة.',
	Login_ToolTip_6: 'يجب عليك اختيار خادم من هنا.',
	Login_ToolTip_7: 'كن حذرًا خاصةً فيما يتعلق بالخادم المحدد عند تسجيل الدخول.',
	SideMenu_Deposit: 'الإيداع',
	SideMenu_Withdraw: 'السحب',
	SideMenu_Notifications: 'الإشعارات',
	SideMenu_BankStatement: 'كشف الحساب البنكي',
	SideMenu_Transaction: 'تاريخ العمليات ',
	SideMenu_ChangePassword: 'تغيير كلمة المرور',
	SideMenu_Traders: 'التجار',
	SideMenu_Robots: 'الروبوتات',
	SideMenu_LiveSupport: 'الدعم الحي',
	SideMenu_CustomerComplaint: 'شكوى العميل',
	SideMenu_Settings: 'الإعدادات',
	SideMenu_Share: 'مشاركة مع الأصدقاء',
	SideMenu_References: 'المراجع',
	Favorites: 'المفضلة',
	Forex: 'الفوركس',
	Crypto: 'العملات الرقمية',
	Indices: 'المؤشرات',
	Metal: 'المعادن',
	Energy: 'الطاقة',
	Stock: 'الأسهم',
	History: 'التاريخ',
	Positions: 'المراكز المفتوحة',
	Orders: 'الأوامر المفتوحة',
	Home_RecommendedActions: 'الإجراءات الموصى بها لليوم',
	Home_Announcement: 'يمكنك تحديد الزوج الذي ترغب في متابعته.',
	Router_Login: 'تسجيل الدخول',
	Router_TransactionDetails: 'تفاصيل معاملتي',
	Router_Trade: 'تداول',
	Router_TradingView: 'رسم بياني مفصل',
	Router_DepositSelection: 'الإيداع',
	Router_DepositWithCash: 'الإيداع بواسطة التحويل البنكي',
	Router_Withdraw: 'السحب بواسطة التحويل البنكي',
	Router_CurrencySelection: 'الإيداع بواسطة التحويل البنكي',
	Router_BankTransaction: 'الإيداع بواسطة التحويل البنكي',
	Router_DepositWithCrypto: 'الإيداع بواسطة العملات الرقمية',
	Router_ChangePassword: 'تغيير كلمة المرور',
	Router_CustomerComplaint: 'شكوى العميل',
	Router_References: 'المراجع',
	Router_AddReferences: 'إضافة مراجع',
	Router_Settings: 'الإعدادات',
	Router_Home: 'الرئيسية',
	Router_Symbols: 'الأسواق',
	Router_Transaction: 'معاملاتي',
	Balance: 'الرصيد',
	Equity: 'حق السكن',
	Credit: 'الائتمان',
	Margin: 'الهامش المستخدم',
	Margin_Free: 'الهامش الحر',
	Margin_Level: 'مستوى الهامش',
	Share: 'مشاركة مع الأصدقاء',
	Floating: 'ربح/خسارة عائمة',
	UserCard_PhoneError: 'رقم الهاتف غير مسجل!',
	DepositSelection_WithCash: 'الإيداع بواسطة التحويل البنكي',
	DepositSelection_WithCrypto: 'الإيداع بواسطة العملات الرقمية',
	DepositSelection_Warning: 'يرجى اختيار وسيلة الإيداع المناسبة.',
	DepositWithCash_Warning: 'للحصول على معلومات التحويل البنكي المناسبة، يرجى اختيار البنك الذي ترغب في تمويله، ثم اختيار العملة.',
	CurrencySelection_Warning: 'للحصول على معلومات التحويل البنكي المناسبة، يرجى اختيار البنك الذي ترغب في تمويله، ثم اختيار العملة.',
	DepositTransaction_BankName: 'اسم البنك',
	DepositTransaction_Name: 'اسم المستلم',
	DepositTransaction_Currency: 'العملة',
	DepositTransaction_IBAN: 'الرقم الدولي للحساب المصرفي (IBAN)',
	DepositTransaction_Description: 'الوصف',
	DepositWithCrypto_Warning_1: 'للحصول على معلومات العملة الرقمية المناسبة، يرجى اختيار العملة الرقمية التي ترغب في تمويلها، ثم اختيار الشبكة.',
	DepositWithCrypto_Warning_2: 'بعد اختيار وحدة الشبكة، يمكنك نسخها من بطاقة المعلومات.',
	DepositWithCrypto_SelectCrypto: 'اختر عملة رقمية',
	DepositWithCrypto_SelectNetwork: 'اختر وحدة الشبكة',
	DepositWithCrypto_SelectNetwork_Warning: 'اختر عملة رقمية أولاً',
	DepositWithCrypto_Message: 'رسالة',
	CustomerComplaint_Message: 'رسالتك',
	CustomerComplaint_Warning: 'يمكنك نقل قضيتك عن طريق كتابتها في الحقل أدناه. سيتم نقلها عبر WhatsApp.',
	Settings_Language: 'اللغة',
	Settings_SearchLanguage: 'ابحث عن اللغة...',
	Settings_DarkMode: 'الوضع الداكن',
	Settings_Notifications: 'الإشعارات',
	Trade_AdditionalTransactions: 'معاملات إضافية',
	Trade_MarketOrder: 'أمر السوق',
	Trade_TakeProfit: 'جني الربح',
	Trade_StopLoss: 'وقف الخسارة',
	Trade_Create: 'إنشاء',
	Trade_BuyOrder: 'إنشاء أمر شراء',
	Trade_SellOrder: 'إنشاء أمر بيع',
	Trade_BuyLimit: 'إنشاء أمر شراء بحد معين',
	Trade_SelllLimit: 'إنشاء أمر بيع بحد معين',
	Trade_BuyStop: 'إنشاء أمر شراء بوقف محدد',
	Trade_SellStop: 'إنشاء أمر بيع بوقف محدد',
	Trade_Type_0: 'شراء',
	Trade_Type_1: 'بيع',
	Trade_Type_2: 'شراء بحد',
	Trade_Type_3: 'بيع بحد',
	Trade_Type_4: 'شراء بوقف',
	Trade_Type_5: 'بيع بوقف',
	Trade_Type_6: 'شراء بوقف وحد',
	Trade_Type_7: 'بيع بوقف وحد',
	Trade_Type_8: 'إغلاق بواسطة الطلب',
	Positions_TimeCreate: 'التاريخ',
	Positions_Position: 'المركز',
	Positions_VolumeCurrent: 'الحجم',
	Positions_PriceCurrent: 'السعر الحالي',
	Positions_PriceSL: 'وقف الخسارة',
	Positions_PriceTP: 'جني الربح',
	Positions_Swap: 'سواب',
	Positions_Profit: 'ربح/خسارة',
	Position_PartialClose: 'إغلاق جزئي',
	Position_ClosePosition: 'إغلاق المركز',
	Position_ModifyPosition: 'تعديل',
	ModifyPosition_Title: 'تعديل المركز',
	ModifyPosition_Position: 'رقم المركز',
	ModifyPosition_PriceCurrent: 'السعر الحالي',
	ModifyPosition_PriceSL: 'وقف الخسارة',
	ModifyPosition_PriceTP: 'جني الربح',
	ModifyPosition_Accept: 'قبول',
	ModifyPosition_Cancel: 'إلغاء',
	ModifyOrder_Title: 'تعديل الطلب',
	ModifyOrder_Order: 'رقم الطلب',
	ModifyOrder_PriceCurrent: 'السعر الحالي',
	ModifyOrder_PriceOrder: 'سعر الطلب',
	ModifyOrder_PriceSL: 'وقف الخسارة',
	ModifyOrder_PriceTP: 'جني الربح',
	ModifyOrder_LastTime: 'تاريخ الانتهاء',
	ModifyOrder_LastTimeMessage: 'حدد تاريخ انتهاء لطلبك.',
	ModifyOrder_Accept: 'قبول',
	ModifyOrder_Cancel: 'إلغاء',
	ModifyOrder_LastTimeOk: 'موافق',
	ModifyOrder_LastTimeCancel: 'إلغاء',
	Orders_Order: 'تذكرة',
	Orders_Lot: 'الكمية',
	Orders_PriceOrder: 'سعر الطلب',
	Orders_PriceSL: 'وقف الخسارة',
	Orders_PriceTP: 'جني الربح',
	Orders_PriceCurrent: 'السعر الحالي',
	Orders_Reason: 'السبب',
	Orders_Comment: 'التعليق',
	Orders_DeleteOrder: 'حذف الطلب',
	Orders_ModifyOrder: 'تعديل',
	Orders_Type_0: 'العميل',
	Orders_Type_1: 'الخبير',
	Orders_Type_2: 'البائع',
	Orders_Type_3: 'SL',
	Orders_Type_4: 'TP',
	Orders_Type_5: 'SO',
	Orders_Type_6: 'التقريب',
	Orders_Type_7: 'العميل الخارجي',
	Orders_Type_8: 'فترة التسامح',
	SymbolProperties_SwapRates: 'أسعار السواب',
	SymbolProperties_Sessions: 'الفترات',
	SymbolProperties_SessionsTrade: 'تجارة',
	SymbolProperties_ContractSize: 'حجم العقد',
	SymbolProperties_Spread: 'الفارق',
	SymbolProperties_StopsLevel: 'مستوى الوقف',
	SymbolProperties_CurrencyMargin: 'هامش العملة',
	SymbolProperties_VolumeMin: 'الحد الأدنى للحجم',
	SymbolProperties_VolumeMax: 'الحد الأقصى للحجم',
	SymbolProperties_Monday: 'الاثنين',
	SymbolProperties_Tuesday: 'الثلاثاء',
	SymbolProperties_Wednesday: 'الأربعاء',
	SymbolProperties_Thursday: 'الخميس',
	SymbolProperties_Friday: 'الجمعة',
	SymbolProperties_Saturday: 'السبت',
	SymbolProperties_Sunday: 'الأحد',
	History_Button: 'تصفية',
	History_Positions: 'المراكز المغلقة',
	History_Balance: 'تاريخ الرصيد',
	History_MyTransactions: 'معاملاتي',
	History_DatePicker_StartTitle: 'حدد تاريخ البداية',
	History_DatePicker_EndTitle: 'حدد تاريخ الانتهاء',
	History_DatePicker_Ok: 'موافق',
	History_DatePicker_Cancel: 'إلغاء',
	History_NoData: 'لا توجد تاريخ لعرضه.',
	HistoryPositions_Symbol: 'الرمز',
	HistoryPositions_Deal: 'الصفقة',
	HistoryPositions_Volume: 'الكمية',
	HistoryPositions_Price: 'سعر الفتح',
	HistoryPositions_PricePosition: 'سعر الإغلاق',
	HistoryPositions_PriceSL: 'وقف الخسارة',
	HistoryPositions_PriceTP: 'جني الربح',
	HistoryPositions_Swap: 'التخزين',
	HistoryPositions_Profit: 'ربح/خسارة',
	HistoryPositions_Action_0: 'بيع',
	HistoryPositions_Action_1: 'شراء',
	HistoryPositions_Action_2: 'بيع بحد معين',
	HistoryPositions_Action_3: 'شراء بحد معين',
	HistoryPositions_Action_4: 'بيع بوقف محدد',
	HistoryPositions_Action_5: 'شراء بوقف محدد',
	HistoryPositions_Action_6: 'بيع بحد معين بوقف محدد',
	HistoryPositions_Action_7: 'شراء بحد معين بوقف محدد',
	HistoryPositions_Action_8: 'إغلاق بواسطة الطلب',
	HistoryBalance_Time: 'التاريخ',
	HistoryBalance_Ticket: 'الصفقة',
	HistoryBalance_Profit: 'السعر',
	HistoryBalance_Type: 'النوع',
	HistoryBalance_Comment: 'التعليق',
	HistoryBalance_Action_2: 'الرصيد',
	HistoryBalance_Action_3: 'الائتمان',
	HistoryBalance_Action_4: 'رسوم/سحب إضافية',
	HistoryBalance_Action_5: 'تصحيح',
	HistoryBalance_Action_6: 'مكافآت',
	HistoryBalance_Action_7: 'عمولة',
	ChangePassword_CurrentPassword: 'كلمة المرور الحالية',
	ChangePassword_NewPassword: 'كلمة المرور الجديدة',
	ChangePassword_ConfirmNewPassword: 'تأكيد كلمة المرور الجديدة',
	ChangePassword_Button: 'حفظ',
	ReferencesAdd_Button: 'إضافة مرجع',
	ReferencesAdd_Error: 'خطأ في إضافة المرجع\nيرجى المحاولة مرة أخرى لاحقًا.',
	FlowMessage_Timeout: 'لم يتم استلام استجابة بعد الصفقة.',
	FlowMessage_Success: 'تمت الصفقة بنجاح.',
	FlowMessage_Error: 'حدث خطأ أثناء الصفقة. يرجى المحاولة مرة أخرى لاحقًا.',
	FlowMessage_InvalidPrice: 'الرجاء إدخال سعر صحيح.',
	FlowMessage_InfoCardCopied: 'تم نسخ القيمة',
	FlowMessage_UnderConstruction: 'الصفحة التي تريد الانتقال إليها قيد الإنشاء.',
	FlowMessage_InvalidLogin: 'اسم المستخدم أو كلمة المرور غير صحيحة.',
	FlowMessage_LoginSuccess: 'تم تسجيل الدخول. مرحبًا بك!',
	FlowMessage_LoginError: 'حدث خطأ أثناء تسجيل الدخول! يرجى المحاولة مرة أخرى لاحقًا.',
	FlowMessage_UnfilledFieldWarning: 'الرجاء ملء جميع الحقول.',
	FlowMessage_ModifySuccess: 'تم تعديلك بنجاح.',
	FlowMessage_PositionClose: 'تم إغلاق المركز بنجاح.',
	FlowMessage_OrderDelete: 'تم حذف الطلب بنجاح.',
	FlowMessage_PositionCloseRejected: 'فشل إغلاق المركز.',
	FlowMessage_OrderDeleteRejected: 'فشل حذف الطلب.',
	FlowMessage_Logout_N: 'تم تسجيل الخروج بنجاح.',
	FlowMessage_Logout_R: 'تم تسجيل الخروج. يتم تسجيل الدخول مرة أخرى...',
	FlowMessage_Logout_0: 'تم تسجيل الخروج بسبب الصيانة على الخادم.',
	FlowMessage_Logout_1: 'تم تسجيل الخروج بسبب فقدان الاتصال. يتم تسجيل الدخول مرة أخرى...',
	FlowMessage_ReLogin_DataSourceIsNotReady: 'انتهت جلستك. يرجى تسجيل الدخول مرة أخرى.',
	FlowMessage_ReLogin_GroupChanged: 'تم تسجيل الخروج بسبب فقدان الاتصال. يتم تسجيل الدخول مرة أخرى...',
	FlowMessage_ReLogin_LoginFromAnotherDevice: 'تم تسجيل الدخول من جهاز آخر. تم تسجيل الخروج.',
	FlowMessage_ChangeInformationSuccess: 'تم تحديث معلوماتك. \nيتم توجيهك إلى شاشة تسجيل الدخول.',
	FlowMessage_ChangeInformationError: 'حدثت مشكلة. لم يمكن تغيير كلمة المرور الخاصة بك. يرجى المحاولة مرة أخرى لاحقًا.',
	FlowMessage_CustomerComplaintSuccess: 'تم تقديم شكواك.',
	FlowMessage_InvestorError: 'لا يمكنك التداول على حساب المستثمر.',
	FlowMessage_Trade_Buy_MarketLimit: 'قيمة السوق الحدودي لا يمكن أن تكون أقل من أو تساوي سعر الشراء.',
	FlowMessage_Trade_Buy_StopLoss: 'قيمة وقف الخسارة لا يمكن أن تكون أكبر من أو تساوي سعر الشراء.',
	FlowMessage_Trade_Buy_TakeProfit: 'قيمة جني الربح لا يمكن أن تكون أقل من أو تساوي سعر الشراء.',
	FlowMessage_Trade_Sell_MarketLimit: 'قيمة السوق الحدودي لا يمكن أن تكون أقل من أو تساوي سعر البيع.',
	FlowMessage_Trade_Sell_StopLoss: 'قيمة وقف الخسارة لا يمكن أن تكون أقل من أو تساوي سعر البيع.',
	FlowMessage_Trade_Sell_TakeProfit: 'قيمة جني الربح لا يمكن أن تكون أكبر من أو تساوي سعر البيع.',
	FlowMessage_Deposit: 'بعد إشعار الإيداع، أبلغ خبير الاستثمار الخاص بك.',
	FlowMessage_Withdraw: 'بعد إشعار السحب، أبلغ خبير الاستثمار الخاص بك.',
	FlowMessage_PhoneError: 'رقم هاتف غير صالح!\nيجب أن يتكون رقم هاتفك من أرقام فقط.',
	FlowMessage_EmailError: 'عنوان البريد الإلكتروني غير صالح!\nيرجى إدخال عنوان بريد إلكتروني صالح.',
	FlowMessage_ReferenceAdded: 'تمت إضافة المرجع بنجاح.',
	FlowMessage_ReferenceAllReadyCollected: 'لقد جمعت بالفعل مكافأتك!',
	FlowMessage_ReferenceUnderReview: 'مكافأتك قيد المراجعة حاليًا!',
	FlowMessage_ReferenceNotEnough: 'ليس لديك مراجع كافية للحصول على المكافأة!',
	FlowMessage_InvalidComplaint: 'يرجى إدخال شكوى صالحة.',
	Retcode_0: '',
	Retcode_10001: 'الطلب قيد التنفيذ.',
	Retcode_10002: 'تم قبول الطلب.',
	Retcode_10003: 'الطلب قيد المعالجة.',
	Retcode_10004: 'تم استلام عرض رد على الطلب.',
	Retcode_10005: 'الأسعار المقدمة ردًا على الطلب.',
	Retcode_10006: 'تم رفض الطلب.',
	Retcode_10007: 'تم إلغاء الطلب.',
	Retcode_10008: 'تم وضع أمر نتيجة للطلب.',
	Retcode_10009: 'تم الانتهاء من الطلب.',
	Retcode_10010: 'تم تنفيذ الطلب جزئيًا.',
	Retcode_10011: 'خطأ عام في الطلب.',
	Retcode_10012: 'انتهت مهلة الطلب.',
	Retcode_10013: 'طلب غير صالح.',
	Retcode_10014: 'حجم طلب غير صالح.',
	Retcode_10015: 'سعر طلب غير صالح.',
	Retcode_10016: 'مستويات وقف خسارة أو سعر غير صحيحة.',
	Retcode_10017: 'التداول معطل.',
	Retcode_10018: 'السوق مغلق.',
	Retcode_10019: 'رصيد غير كافٍ.',
	Retcode_10020: 'تغير السعر.',
	Retcode_10021: 'السعر غير متاح.',
	Retcode_10022: 'فترة الطلب غير صالحة.',
	Retcode_10023: 'تم تعديل الطلب.',
	Retcode_10024: 'الكثير من طلبات التداول. على سبيل المثال، يمكن أن يحدث هذا الخطأ عند محاولة إرسال أكثر من 128 طلب تداول من خلال نسخة واجهة برمجة تطبيقات المسؤول.',
	Retcode_10025: 'الطلب لا يحتوي على تغييرات.',
	Retcode_10026: 'تم تعطيل التداول التلقائي على الخادم.',
	Retcode_10027: 'تم تعطيل التداول التلقائي على الجانب العميل.',
	Retcode_10028: 'تم حظر الطلب من قبل الوكيل.',
	Retcode_10029: 'فشلت عملية التعديل بسبب قرب الطلب أو المركز من السوق.',
	Retcode_10030: 'وضع التعبئة غير مدعوم.',
	Retcode_10031: 'لا يوجد اتصال.',
	Retcode_10032: 'مسموح فقط للحسابات الحقيقية.',
	Retcode_10033: 'تم الوصول إلى حد الطلبات.',
	Retcode_10034: 'تم الوصول إلى حد الحجم.',
	Retcode_10035: 'نوع الطلب غير صالح أو محظور.',
	Retcode_10036: 'تم إغلاق المركز بالفعل. على سبيل المثال، يحدث هذا الخطأ عند محاولة تغيير مستويات التوقف لمركز.',
	Retcode_10037: 'يستخدم لأغراض داخلية.',
	Retcode_10038: 'حجم الإغلاق يتجاوز الحجم الحالي للمركز المفتوح.',
	Retcode_10039: 'هناك بالفعل أمر لإغلاق المركز. يحدث هذا الخطأ في وضع التحوية: عند محاولة إغلاق مركز بمركز معاكس أو محاولة إغلاق كل أو جزء من مركز قائم، يتم إنشاء الخطأ إذا كان إجمالي حجم الطلبات المغلقة الحالية يتجاوز الحجم الحالي للمركز والطلب المقدم حديثًا.',
	Retcode_10040:
		'يمكن أن يتم تحديد الحد الأقصى لعدد المراكز التي يمكن فتحها في نفس الوقت في حساب من خلال إعدادات المجموعة. عند الوصول إلى الحد، يعيد الخادم  عند محاولة وضع أمر. تعمل القيود بشكل مختلف اعتمادًا على نوع حساب الحساب: التسوية - يؤخذ في اعتباره عدد المراكز المفتوحة. عند الوصول إلى الحد، تقوم المنصة بتعطيل وضع صفقات يمكن أن تزيد من عدد المراكز المفتوحة. في الواقع، تسمح المنصة فقط بوضع الطلبات للرموز التي لديها بالفعل مراكز مفتوحة. لا يتم النظر في الطلبات المعلقة الحالية لأنه على الرغم من أنها قد تؤدي إلى تغييرات في المراكز الحالية، إلا أنها لا يمكن أن تزيد من عدد المراكز. التحوية - تُعتبر الطلبات المعلقة بالإضافة إلى المراكز المفتوحة، حيث يؤدي تنشيط الطلب المعلق دائمًا إلى فتح مركز جديد. عند الوصول إلى الحد، تعطل المنصة كل من وضع أوامر السوق لفتح مراكز جديدة ووضع الطلبات المعلقة.',
	Retcode_10041: 'تم رفض الطلب، تم إلغاء الأمر. يتم إرجاع هذا الرمز عند تطبيق الإجراء في قاعدة التوجيه.',
	Retcode_10042: 'تم رفض الطلب بسبب تعيين قاعدة "السماح بالمراكز الطويلة فقط" للرمز .',
	Retcode_10043: 'تم رفض الطلب بسبب تعيين قاعدة "السماح بالمراكز القصيرة فقط" للرمز .',
	Retcode_10044: 'تم رفض الطلب بسبب تعيين قاعدة "السماح بإغلاق المراكز فقط" للرمز .',
	Retcode_10045: 'إغلاق المركز لا يتوافق مع قاعدة FIFO. يتم استخدام هذا للمجموعات حيث تكون خيارات  ممكّنة. مع هذا الخيار، يجب إغلاق جميع المراكز بتسلسل: يتم إغلاق أقدم مركز أولاً، ثم القادم، وهكذا.',
	Retcode_10046: 'لا يمكن فتح مركز أو وضع أمر معلق بسبب حظر المراكز الهيدج. يتم إرجاع الخطأ عندما يتم تمكين علم  للمجموعة، ويحاول المستخدم إجراء صفقة عندما يكون هناك بالفعل أمر أو مركز معاكس لنفس الرمز.',
};

import { useContext } from "react";
import { Image, Pressable, ScrollView, StyleSheet, Text, View } from "react-native";

import Swiper from "react-native-web-swiper";
import ProminentInvestors from "../components/ProminentInvestors";
import ReconmmendedActions from "../components/RecommendedActions";
import SymbolListItem from "../components/Symbol/SymbolListItem";
import { AuthContext } from "../context/AuthContext";
import { IGroupSymbol, ISymbol } from "../Global";
import { useTheme } from "../context/Theme/ThemeProvider";
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import { showFlowMessage } from "../components/FlowMessage";
import { NoVisitorsAllowed } from "../config/visitor";
import { InvestorWarningBox } from "../components/InvestorWarningBox";

const Home = ({ navigation }: any) => {
  const { group, user } = useContext(AuthContext);
  const { colors } = useTheme();
  const styles = Styles(colors);

  if (!group?.Symbols || !user || !user?.FavoriteSymbols) {
    return <View></View>;
  }

  const menuObj = [
    {
      id: 1,
      name: "Para Yatır",
      value: 'DepositSelection',
      icon: (
        <MaterialCommunityIcons
          name="cash-fast"
          size={24}
          color={colors.CONTENT_ICON}
        />
      ),
      onClick: () => {
        navigation.navigate("DepositSelection");
      },
    },
    {
      id: 2,
      name: "Para Çek",
      value: 'Withdraw',
      icon: (
        <MaterialCommunityIcons
          name="cash-fast"
          size={24}
          color={colors.CONTENT_ICON}
        />
      ),
      onClick: () => {
        navigation.navigate("Withdraw", { user });
      },
    },
    {
      id: 3,
      name: "Traderlar",
      value: 'Traders',
      icon: <Ionicons name="people" size={24} color={colors.CONTENT_ICON} />,
      onClick: () => {
        showFlowMessage({
					message: 'Geçmek istediğiniz ekran yapım aşamasındadır.',
					type: 'warning',
					duration: 750,
				});
      },
    },
    {
      id: 4,
      name: "Robotlar",
      value: 'Robots',
      icon: (
        <MaterialCommunityIcons
          name="robot-excited-outline"
          size={24}
          color={colors.CONTENT_ICON}
        />
      ),
      onClick: () => {
        showFlowMessage({
					message: 'Geçmek istediğiniz ekran yapım aşamasındadır.',
					type: 'warning',
					duration: 750,
				});
      },
    },
  ];

  return (
    <View style={{ flex: 1, backgroundColor: colors.SCREEN_BACKGROUND }}>
      {user.IsInvestor && <InvestorWarningBox/>}
      <ScrollView
        showsVerticalScrollIndicator={false}
        nestedScrollEnabled={true}
      >
        <View style={styles.container}>
          <View style={styles.carousel}>
            <Swiper
              vertical={false}
              loop={true}
              timeout={8}
              controlsEnabled={false}
              containerStyle={styles.wrapper}
            >
              <View style={styles.slide}>
                <Image
                  style={styles.slideImage}
                  source={require("../assets/slide/brillanttrade/s1.png")}
                />
              </View>
              <View style={styles.slide}>
                <Image
                  style={styles.slideImage}
                  source={require("../assets/slide/brillanttrade/s2.png")}
                />
              </View>
              <View style={styles.slide}>
                <Image
                  style={styles.slideImage}
                  source={require("../assets/slide/brillanttrade/s3.png")}
                />
              </View>
              <View style={styles.slide}>
                <Image
                  style={styles.slideImage}
                  source={require("../assets/slide/brillanttrade/s4.png")}
                />
              </View>
              <View style={styles.slide}>
                <Image
                  style={styles.slideImage}
                  source={require("../assets/slide/brillanttrade/s5.png")}
                />
              </View>
            </Swiper>
          </View>

          {/* Verifiction Alert */}
          {/* <VerificationAlert /> */}
          {/* Buttons */}
          <View style={styles.menuWrapper}>
            {menuObj.map((item: any) => {
              if (user?.IsInvestor && NoVisitorsAllowed.includes(item.value)) {
								return null;
							}
              console.log(item.value);
              return (
              <View key={item.name} style={styles.menuItem}>
                <Pressable
                  key={item.name}
                  style={styles.menuItemButton}
                  onPress={item.onClick}
                >
                  <View>{item.icon}</View>
                  <Text style={styles.menuItemText}>{item.name}</Text>
                </Pressable>
              </View>
            )})}
            {/* Recommeded Action Button */}
          </View>
          <ReconmmendedActions />
          <ProminentInvestors />
          <View style={styles.infoWrapper}>
            <Image
              source={require("../assets/campaign.png")}
              style={{ width: 25, height: 25, marginLeft: 6 }}
            />
            <Text style={{ color: colors.TEXT, fontSize: 12.8 }}>
              Takip etmek istediğiniz pariteyi yıldızlayabilirsiniz.
            </Text>
          </View>
          <View>
            {group.Symbols?.filter((item: IGroupSymbol) =>
              item?.Symbol.Category.includes("popular")
            )
              .slice(0, 10)
              .map((item: IGroupSymbol, index: number) => {
                return (
                  <SymbolListItem
                    key={item.Symbol.Symbol}
                    groupSymbol={item}
                    navigation={navigation}
                    backgroundColor={
                      index % 2 === 0
                        ? colors.CARD_BACKGROUND1
                        : colors.CARD_BACKGROUND2
                    }
                    textColor={
                      index % 2 === 0 ? colors.CARD_TEXT1 : colors.CARD_TEXT2
                    }
                  />
                );
              })}
          </View>
        </View>
      </ScrollView>
    </View>
  );
};

export default Home;

const Styles = (COLOR: any) =>
  StyleSheet.create({
    container: {
      flex: 1,
      gap: 10,
      padding: 10,
      backgroundColor: COLOR.SCREEN_BACKGROUND,
    },
    carousel: {
      height: 180,
      borderRadius: 10,
    },
    menuWrapper: {
      flexDirection: "row",
      flexWrap: "wrap",
      gap: 6,
    },
    slideImage: {
      flex: 1,
      width: 300,
      height: 100,
      resizeMode: "cover",
    },
    menuItem: {
      flexShrink: 0,
      flexGrow: 1,
    },
    menuItemButton: {
      backgroundColor: COLOR.CARD_BACKGROUND1,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      gap: 10,
      padding: 10,

      flex:1,
      borderRadius: 5,
      shadowOffset: { width: -2, height: 4 },
      shadowColor: COLOR.SHADOW1,
      shadowOpacity: 0.08,
    },
    menuItemText: {
      color: COLOR.TEXT,
      fontSize: 16,
    },
    infoWrapper: {
      flexDirection: "row",
      alignItems: "center",
      padding: 4,
      gap: 8,
      backgroundColor: COLOR.CARD_BACKGROUND1,
      borderRadius: 4,
    },
    wrapper: {},
    slide: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "transparent",
    },

    text: {
      color: COLOR.TEXT_OPPOSITE,
      fontSize: 30,
      fontWeight: "bold",
    },
  });

import React, {useEffect, useState} from 'react';
import {Pressable, StyleSheet, TextInput, View} from 'react-native';

//icons
import { AntDesign } from '@expo/vector-icons';
import {useTheme} from '../../context/Theme/ThemeProvider';

//constants

interface IPriceInput {
  name?: string;
  visible?: boolean;
  placeHolder?: string;
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
  min?: number;
  max?: number;
  step?: number; // (float) default 1
  digits?: number; // (float) default 2
  withButtons?: boolean;
  defaultValue?: number;
}

const PriceInput: React.FC<IPriceInput> = ({placeHolder, visible = true, setValue, value, min = 0, max = 999999999999999, step = 0.01, digits = 4, withButtons = true, defaultValue = 0.0}: IPriceInput) => {
  const {colors} = useTheme();
  const styles = Styles(colors);
  const [textValue, setTextValue] = useState<string>();

  const checkPrice = (priceStr: string, defaultVal: number, maxVal: number, minVal: number, isLast: boolean = false) => {
    const regex = /^\d+(\.\d+)*$/gm;
    const test = regex.test(priceStr);
    if (test) {
      if (parseFloat(priceStr) > maxVal) {
        setValue(maxVal);
        if (isLast) {
          setTextValue(maxVal.toString());
        }
      } else if (parseFloat(priceStr) < minVal) {
        setValue(minVal);
        if (isLast) {
          setTextValue(minVal.toString());
        }
      } else {
        setValue(Number(Number(priceStr).toFixed(digits)));
        if (isLast) {
          setTextValue(Number(priceStr).toFixed(digits));
        }
      }
    } else {
      setValue(Number(defaultVal.toFixed(digits)));
      if (isLast) {
        setTextValue(defaultVal.toFixed(digits));
      }
    }
  };

  useEffect(() => {
    if (value > 0 && textValue == null) {
      setTextValue(value.toString());
    }
  }, [value]);

  const [isTextFocused, setIsTextFocused] = useState<boolean>(false);
  useEffect(() => {
    setTextValue(value.toFixed(digits));
  }, [isTextFocused]);

  const [increaseLongPressState, setIncreaseLongPressState] = useState<boolean>(false);
  const [decreaseLongPressState, setDecreaseLongPressState] = useState<boolean>(false);

  const increase = () => {
    if (value + step > max) {
      setValue(max);
    } else {
      setValue(prev => Number((prev + step).toFixed(digits)));
    }
  };
  const increaseStart = () => {
    setIncreaseLongPressState(true);
  };
  const increaseEnd = () => {
    setIncreaseLongPressState(false);
  };

  const decrease = () => {
    if (value - step < min) {
      setValue(min);
    } else {
      setValue(prev => Number((prev - step).toFixed(digits)));
    }
  };
  const decreaseStart = () => {
    setDecreaseLongPressState(true);
  };
  const decreaseEnd = () => {
    setDecreaseLongPressState(false);
  };

  const [myInterval, setMyInterval] = useState<any>(null);
  useEffect(() => {
    if (increaseLongPressState) {
      setMyInterval(
        setInterval(() => {
          if (value + step < max) {
            increase();
          } else {
            clearInterval(myInterval);
            setValue(max);
          }
        }, 100),
      );
    } else {
      clearInterval(myInterval);
    }

    return () => {
      clearInterval(myInterval);
    };
  }, [increaseLongPressState]);

  useEffect(() => {
    if (decreaseLongPressState) {
      setMyInterval(
        setInterval(() => {
          if (value - step > min) {
            decrease();
          } else {
            clearInterval(myInterval);
            setValue(min);
          }
        }, 100),
      );
    } else {
      clearInterval(myInterval);
    }

    return () => {
      clearInterval(myInterval);
    };
  }, [decreaseLongPressState]);

  return (
    <View style={[styles.inputWrapper]}>
      {withButtons && (
        <Pressable
          disabled={!visible}
          style={({pressed}) => [
            {
              opacity: pressed ? 0.3 : 1,
            },
          ]}
          onPress={decrease}
          onLongPress={decreaseStart}
          onPressOut={decreaseEnd}>
          <AntDesign name='minussquareo'
            style={styles.button}
            color={colors.TEXT}
            size={30}
          />
        </Pressable>
      )}
      <TextInput
        editable={visible}
        selectTextOnFocus={visible}
        style={styles.input}
        textAlign="center"
        defaultValue={textValue ? textValue : '0.0'}
        placeholder={placeHolder}
        keyboardType="numeric"
        //selection={{start: value?.length, end: value?.length}}
        multiline={false}
        numberOfLines={1}
        onFocus={() => setIsTextFocused(true)}
        onBlur={(e: any) => {
          checkPrice(e.target.value, defaultValue, max, min);
          setTimeout(() => {
            setIsTextFocused(false);
          }, 100);
        }}
        onChangeText={text => {
          setTextValue(text);
          if (text.length > 0) {
            checkPrice(text, defaultValue, max, min);
          }
        }}
        {...(isTextFocused ? {value: textValue} : {value: value.toString()})}
      />

      {withButtons && (
        <Pressable
          disabled={!visible}
          onPress={increase}
          onLongPress={increaseStart}
          onPressOut={increaseEnd}>
          <AntDesign name='plussquareo'
            style={styles.button}
            color={colors.TEXT}
            size={30}
          />
        </Pressable>
      )}
    </View>
  );
};

export default PriceInput;

const Styles = (COLOR: any) =>
  StyleSheet.create({
    inputWrapper: {
      padding: 10,
      gap: 5,
      backgroundColor: COLOR.INPUT_BACKGROUND,
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: 5,
      paddingHorizontal: 10,
      shadowColor: COLOR.TEXT,
      shadowOffset: {width: 0, height: 0},
      shadowOpacity: 0.1,
    },
    input: {
      flex: 1,
      borderRadius: 5,
      fontSize: 14,
      padding: 10,
      color: COLOR.TEXT,
      textAlign: 'center',
    },
    button: {
      fontSize: 24,
      fontWeight: 'bold',
      color: COLOR.TEXT,
      borderColor: COLOR.CARD_BACKGROUND2,
      flex: 1,
      backgroundColor: COLOR.CARD_BACKGROUND1,
      borderRadius: 5,
    },
  });

/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Pressable,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import CheckBox from "../components/Checkbox";
import SymbolCard from "../components/SymbolCard";

import PriceInputV2 from "../components/PriceInputV2";

import Slider from "@react-native-community/slider";
import { showMessage } from "react-native-flash-message";
import { IColors, IOpenRequest } from "../Global";

import { getActionTypeWithName } from "../components/OrderPositionsItem/utils";
import PriceFormat from "../components/PriceFormat";
import getTradeReturnCodeDesc from "../config/error-handle";
import socket from "../config/socket";
import { AuthContext } from "../context/AuthContext";
import { useTheme } from "../context/Theme/ThemeProvider";
import apiCall from "../utils/apiCall";
import { newDayjs, now } from "../utils/dateUtil";
import { useFocusEffect } from "@react-navigation/native";
import { showFlowMessage } from "../components/FlowMessage";
import { useLanguage } from "../context/Language/LanguageProvider";
import { findTradeMode, marketStatusController } from '../utils/marketUtils';
import { Audio } from "expo-av";

async function SoundTrade() {
  console.log("Loading Sound");
  const { sound } = await Audio.Sound.createAsync(
    require("../assets/sounds/soundstrade.mp3")
  );
  console.log("Playing Sound");
  await sound.playAsync();
}

async function SoundError() {
  console.log("Loading Sound");
  const { sound } = await Audio.Sound.createAsync(
    require("../assets/sounds/soundserror.mp3")
  );
  console.log("Playing Sound");
  await sound.playAsync();
}

interface ITrade {
  route: any;
  navigation: any;
}

const Trade: React.FC<ITrade> = ({
  route: {
    params: { groupSymbol: groupSymbolParam },
  },
}) => {
  const { words } = useLanguage();
	const { group, user, setFavorite, openRequests, setOpenRequests } = useContext(AuthContext);
	const [groupSymbol, setGroupSymbol] = useState(groupSymbolParam);
	const [minimumValue, setMinimumValue] = useState(0.01);
	const [maximumValue, setMaximumValue] = useState(5);
	const [sliderMaximumValue, setSliderMaximumValue] = useState(5);
	const [step, setStep] = useState(0.01);
	const [digits, setDigits] = useState(2);
	const [tradeMode, setTradeMode] = useState<{
		title: string;
		color: string;
		bgColor: string;
		isClosed: boolean;
		isShowing: boolean;
	}>({
		title: 'İşleme Açık',
		color: 'white',
		bgColor: 'red',
		isClosed: false,
		isShowing: false,
	});
	const [selected, setSelected] = useState<'buy' | 'sell'>('sell');
	const [amount, setAmount] = useState(0);
	const [orderType, setOrderType] = useState('sell-order');
	const [marketLimit, setMarketLimit] = useState(false);
	const [stopLoss, setStopLoss] = useState(false);
	const [takeProfit, setTakeProfit] = useState(false);

	// input states
	const [marketLimitValue, setMarketLimitValue] = useState<number>(0);
	const [stopLossValue, setStopLossValue] = useState<number>(0);
	const [takeProfitValue, setTakeProfitValue] = useState<number>(0);

	const { colors } = useTheme();
	const styles = Styles(colors);

	const [lastTick, setLastTick] = React.useState({
		Bid: groupSymbol?.Symbol?.Tick?.Bid,
		Ask: groupSymbol?.Symbol?.Tick?.Ask,
		Datetime: groupSymbol?.Symbol?.Tick?.Datetime,
	});

	const [nowTime, setNowTime] = useState(now().add(-1, 'second').format('YYYY-MM-DDTHH:mm:ssZ'));
	const [isClosed, setIsClosed] = useState(false);

	useEffect(() => {
		const groupSymbol = group?.Symbols.find((symbol) => symbol.Path === groupSymbolParam.Path);
		if (groupSymbol) {
			setGroupSymbol(groupSymbol);
		}
	}, [group]);
	useEffect(() => {
		setNowTime(now().format('YYYY-MM-DDTHH:mm:ssZ'));
		const intervalId = setInterval(() => {
			setNowTime(now().format('YYYY-MM-DDTHH:mm:ssZ'));
		}, 1000);
		return () => {
			clearInterval(intervalId);
		};
	}, []);

	useEffect(() => {
		console.log('NOW TIME: ', nowTime);
		const isClosed = newDayjs.unix(lastTick.Datetime).add(groupSymbol.Symbol.QuotesTimeout, 'seconds').isBefore(now());
		setIsClosed(isClosed);
	}, [nowTime]);

	useEffect(() => {
		if (groupSymbol?.Symbol) {
			setMinimumValue(groupSymbol.Symbol.VolumeMin / 10000);
			setMaximumValue(groupSymbol.Symbol.VolumeMax / 10000);
			setSliderMaximumValue(Math.min(5, groupSymbol.Symbol.VolumeMax / 10000));
			setStep(groupSymbol.Symbol.VolumeStep / 10000);
			setDigits(groupSymbol.Symbol.Digits);
			setAmount(groupSymbol.Symbol.VolumeMin / 10000);
		}
	}, [groupSymbol?.Symbol]);

	useEffect(() => {
		console.log('GROUP SYMBOL ON TRADE', groupSymbol);
		setTradeMode(findTradeMode(groupSymbol.TradeMode ?? groupSymbol.Symbol.TradeMode, isClosed, colors));
	}, [groupSymbol.TradeMode, groupSymbol.Symbol.TradeMode]);

	useFocusEffect(
		useCallback(() => {
			if (!groupSymbol?.Symbol?.Symbol) {
				console.log('Symbol not set!');
				return;
			}

			socket.on(groupSymbol.Symbol.Symbol, (data: any) => {
				setLastTick(data);
			});

			// return () => {
			// 	socket.off(groupSymbol.Symbol.Symbol);
			// };
		}, [groupSymbol?.Symbol?.Symbol]),
	);

	useEffect(() => {
		if (marketLimit) {
			setMarketLimitValue(selected === 'sell' ? Number(lastTick.Bid) : Number(lastTick.Ask));
		} else {
			setMarketLimitValue(0);
		}
	}, [marketLimit]);
	useEffect(() => {
		if (stopLoss) {
			setStopLossValue(selected === 'sell' ? Number(lastTick.Bid) : Number(lastTick.Ask));
		} else {
			setStopLossValue(0);
		}
	}, [stopLoss]);
	useEffect(() => {
		if (takeProfit) {
			setTakeProfitValue(selected === 'sell' ? Number(lastTick.Bid) : Number(lastTick.Ask));
		} else {
			setTakeProfitValue(0);
		}
	}, [takeProfit]);

	useEffect(() => {
		let type;
		if (!marketLimit) {
			type = selected;
		} else {
			if (selected === 'sell' && marketLimitValue < lastTick.Bid) {
				type = 'sell-stop';
			} else if (selected === 'buy' && marketLimitValue > lastTick.Ask) {
				type = 'buy-stop';
			} else if (selected === 'buy' && marketLimitValue < lastTick.Ask) {
				type = 'buy-limit';
			} else {
				type = 'sell-limit';
			}
		}
		setOrderType(type);
	}, [marketLimitValue, marketLimit, selected]);

	const [isFavorited, setIsFavorited] = useState(false);

	useEffect(() => {
		if (user && user.FavoriteSymbols) {
			const isFavorited = user.FavoriteSymbols.indexOf(groupSymbol.Symbol.Symbol) > -1;
			setIsFavorited(isFavorited);
		}
	}, [user, user?.FavoriteSymbols]);

	const handleFavoriteChange = (isFavorited: boolean) => {
		setIsFavorited(isFavorited);
		setFavorite(groupSymbol.Symbol.Symbol, isFavorited);
	};

	

	const handleSubmit = async () => {
		try {
			if (user?.IsInvestor) {
				SoundError();
				showFlowMessage({
					message: 'Investor Hesabında İşlem Yapamazsınız!',
					type: 'warning',
					duration: 2000,
				});
				return;
			}

			const martketStatus = marketStatusController(groupSymbol, lastTick.Datetime);
			if (martketStatus.isClosed) {
				showFlowMessage({
					message: martketStatus.title,
					type: 'warning',
					duration: 2000,
				});
				return;
			}

			if (selected == 'buy') {
				if (marketLimit && marketLimitValue >= lastTick.Ask) {
					SoundError();
					showFlowMessage({
						message: 'Market Limit değeri alış fiyatından küçük veya eşit olamaz',
						type: 'warning',
						duration: 3000,
					});
					return;
				}
				if (stopLoss && stopLossValue >= lastTick.Ask) {
					SoundError();
					showFlowMessage({
						message: 'Zarar Durdur değeri alış fiyatından büyük veya eşit olamaz',
						type: 'warning',
						duration: 3000,
					});
					return;
				}
				if (takeProfit && takeProfitValue <= lastTick.Ask) {
					SoundError();
					showFlowMessage({
						message: 'Kar Al değeri alış fiyatından küçük veya eşit olamaz',
						type: 'warning',
						duration: 3000,
					});
					return;
				}
			} else {
				if (marketLimit && marketLimitValue <= lastTick.Bid) {
					SoundError();
					showFlowMessage({
						message: 'Market Limit değeri satış fiyatından küçük veya eşit olamaz',
						type: 'warning',
						duration: 3000,
					});
					return;
				}
				if (stopLoss && stopLossValue <= lastTick.Bid) {
					SoundError();
					showFlowMessage({
						message: 'Zarar Durdur değeri satış fiyatından küçük veya eşit olamaz',
						type: 'warning',
						duration: 3000,
					});
					return;
				}
				if (takeProfit && takeProfitValue >= lastTick.Bid) {
					SoundError();
					showFlowMessage({
						message: 'Kar Al değeri satış fiyatından büyük veya eşit olamaz',
						type: 'warning',
						duration: 3000,
					});
					return;
				}
			}

			setOpenRequests((prevOpenRequests) => {
				prevOpenRequests[groupSymbol.Symbol.Symbol] = { ID: -1, Datetime: Date.now() };
				return { ...prevOpenRequests };
			});
			const response = await apiCall(
				'/create-trade',
				{
					symbol: groupSymbol.Symbol.Symbol,
					Digits: digits,
					volume: amount,
					type: orderType,
					priceOrder: marketLimitValue || '0.00',
					comment: '',
					timeExpiration: 0,
					typeTime: 0,
					priceSl: stopLoss && stopLossValue,
					priceTp: takeProfit && takeProfitValue,
				},
				'POST',
			);
			if (response.Retcode) {
				SoundError();
				showFlowMessage({
					message: getTradeReturnCodeDesc(response.Retcode),
					type: 'info',
					duration: 3000,
				});
				setOpenRequests((prevOpenRequests) => {
					delete prevOpenRequests[groupSymbol.Symbol.Symbol];
					return { ...prevOpenRequests };
				});
			} else {
				SoundTrade();
				setOpenRequests((prevOpenRequests) => {
					prevOpenRequests[groupSymbol.Symbol.Symbol] = { ID: response.ID, Datetime: Date.now() };
					return { ...prevOpenRequests };
				});
				// Clear open request timeout
				setTimeout(() => {
					setOpenRequests((prevOpenRequests) => {
						delete prevOpenRequests[groupSymbol.Symbol.Symbol];
						return { ...prevOpenRequests };
					});
				}, 5 * 60 * 1000);
				showFlowMessage({
					// message: `${getActionTypeWithName(orderType)} işleminiz başarıyla gerçekleşmiştir.\n${getTradeReturnCodeDesc(response.Retcode)}`,
					message: `Emir işleniyor lütfen bekleyiniz...`,
					type: 'success',
					duration: 1000,
				});
			}
		} catch (error) {
			SoundError();
			console.log('error', error);
			showFlowMessage({
				message: 'İşlem yapılırken bir sorun oluştu',
				type: 'error',
				duration: 1000,
			});
			setOpenRequests((prevOpenRequests) => {
				delete prevOpenRequests[groupSymbol.Symbol.Symbol];
				return { ...prevOpenRequests };
			});
		}
	};

  return (
    <SafeAreaView style={styles.safeAreaView}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={styles.scrollView}
      >
        <View style={styles.container}>
          {tradeMode.isShowing && (
            <View
              style={[
                styles.priceWrapper,
                { backgroundColor: tradeMode.bgColor },
              ]}
            >
              <Text
                style={{
                  fontWeight: "500",
                  color: tradeMode.color,
                }}
              >
                {tradeMode.title}
              </Text>
            </View>
          )}
          {/* Sembol Inform Area */}
          <SymbolCard
            Symbol={groupSymbol.Symbol}
            symbolDescription={groupSymbol.Symbol.Description}
            isFavorited={isFavorited}
            onFavoriteChange={handleFavoriteChange}
          />
          {/* Price Area */}

          {/* Buy Sell Area */}
          <View style={styles.buysellWrapper}>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <View style={styles.buySellContainer}>
                <TouchableOpacity
                  style={[
                    styles.button,
                    selected === "sell"
                      ? styles.sellSelected
                      : styles.notSelected,
                  ]}
                  onPress={() => {
                    setSelected("sell");
                  }}
                >
                  <View
                    style={{
                      alignItems: "center",
                      flexDirection: "row",
                      gap: 15,
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "600",
                        marginLeft: 10,
                        color: colors.TEXT,
                      }}
                    >
                      <PriceFormat
                        value={lastTick?.Bid}
                        digits={groupSymbol?.Symbol?.Digits}
                        showCurrency={false}
                      />
                    </Text>
                    <View
                      style={[
                        styles.sellTextWrapper,
                        selected === "sell"
                          ? styles.sellButtonSelected
                          : styles.notButtonSelected,
                      ]}
                    >
                      <Text style={styles.sellText}>{words["Sell"]}</Text>
                    </View>
                  </View>
                </TouchableOpacity>

                <View
                  style={{
                    alignContent: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: 12,
                      color: colors.TEXT,
                    }}
                  >
                    {words["Spread"]}
                  </Text>
                  <Text
                    style={{
                      textAlign: "center",
                      fontSize: 11,
                      color: colors.TEXT,
                    }}
                  >
                    {Math.round(
                      Math.abs(lastTick?.Bid - lastTick?.Ask) *
                        Math.pow(10, groupSymbol.Symbol.Digits)
                    )}
                  </Text>
                </View>

                <TouchableOpacity
                  style={[
                    styles.button,
                    selected === "buy"
                      ? styles.buySelected
                      : styles.notSelected,
                  ]}
                  onPress={() => setSelected("buy")}
                >
                  <View
                    style={{
                      alignItems: "center",
                      flexDirection: "row",
                      gap: 15,
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "600",
                        marginLeft: 10,
                        color: colors.TEXT,
                      }}
                    >
                      <PriceFormat
                        value={lastTick?.Ask}
                        digits={groupSymbol?.Symbol?.Digits}
                        showCurrency={false}
                      />
                    </Text>
                    <View
                      style={[
                        styles.buyTextWrapper,
                        selected === "buy"
                          ? styles.buyButtonSelected
                          : styles.notButtonSelected,
                      ]}
                    >
                      <Text style={styles.buyText}>{words["Buy"]}</Text>
                    </View>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          </View>
          {/* Buy Sell Input Area */}
          <View style={styles.inputContainer}>
            {/* <Text>{amount}</Text> */}
            <Slider
              style={{}}
              thumbTintColor={colors.TEXT2}
              maximumTrackTintColor={colors.TEXT2}
              minimumTrackTintColor={colors.TAKE_PROFIT}
              minimumValue={minimumValue}
              lowerLimit={minimumValue}
              maximumValue={sliderMaximumValue}
              upperLimit={maximumValue}
              step={step}
              value={Number(amount)}
              onValueChange={(value: number) =>
                setAmount(
                  Number(
                    value.toFixed(step.toString().split(".")[1].length || 0.1)
                  )
                )
              }
            />
            <PriceInputV2
              placeHolder={words["Amount"]}
              min={minimumValue}
              max={maximumValue}
              step={step}
              digits={step.toString().split(".")[1].length || 0.1}
              value={Number(amount)}
              defaultValue={Number(amount)}
              setValue={setAmount}
            />
          </View>
          <View style={styles.checkboxContainer}>
            <Text style={styles.checkboxTitle}>
              {words["Trade_AdditionalTransactions"]}
            </Text>
            <View style={styles.checkboxWrapper}>
              <CheckBox
                onPress={() => setMarketLimit(!marketLimit)}
                checked={marketLimit}
                label={words["Trade_MarketOrder"]}
              />
              {marketLimit && (
                <PriceInputV2
                  placeHolder={words["Amount"]}
                  value={marketLimitValue}
                  defaultValue={marketLimitValue}
                  step={digits > 0 ? step : Math.pow(10, digits)}
                  setValue={setMarketLimitValue}
                  min={0}
                  digits={digits}
                />
              )}
            </View>
            <View style={styles.checkboxWrapper}>
              <CheckBox
                onPress={() => setStopLoss(!stopLoss)}
                checked={stopLoss}
                label={words["Trade_StopLoss"]}
              />
              {stopLoss && (
                <PriceInputV2
                  placeHolder={words["Amount"]}
                  value={stopLossValue}
                  defaultValue={stopLossValue}
                  step={digits > 0 ? step : Math.pow(10, digits)}
                  setValue={setStopLossValue}
                  min={0}
                  digits={digits}
                />
              )}
            </View>
            <View style={styles.checkboxWrapper}>
              <CheckBox
                onPress={() => setTakeProfit(!takeProfit)}
                checked={takeProfit}
                label={words["Trade_TakeProfit"]}
              />
              {takeProfit && (
                <PriceInputV2
                  placeHolder={words["Amount"]}
                  value={takeProfitValue}
                  defaultValue={takeProfitValue}
                  step={digits > 0 ? step : Math.pow(10, digits)}
                  setValue={setTakeProfitValue}
                  min={0}
                  digits={digits}
                />
              )}
            </View>
          </View>
        </View>
      </ScrollView>
      <Pressable
        disabled={
          tradeMode.isClosed || !!openRequests[groupSymbol.Symbol.Symbol]
        }
        style={{
          ...styles.buySellButton,
          backgroundColor: selected === "sell" ? colors.SELL : colors.BUY,
          opacity:
            !tradeMode.isClosed && !openRequests[groupSymbol.Symbol.Symbol]
              ? 1
              : 0.5,
        }}
        onPress={handleSubmit}
      >
        <Text
          style={{
            fontWeight: "700",
            color:
              selected === "sell"
                ? colors.SELL_ORDER_TEXT
                : colors.BUY_ORDER_TEXT,
          }}
        >
          {words[getActionTypeWithName(orderType)]}
        </Text>
      </Pressable>
    </SafeAreaView>
  );
};

export default Trade;

const Styles = (COLOR: IColors) =>
  StyleSheet.create({
    safeAreaView: {
      flex: 1,
      backgroundColor: COLOR.SCREEN_BACKGROUND,
    },
    scrollView: {},
    container: {
      flex: 1,
      gap: 10,
      padding: 10,
    },
    priceWrapper: {
      flex: 1,
      backgroundColor: COLOR.SCREEN_BACKGROUND,
      padding: 15,
      borderRadius: 5,
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      gap: 10,
      shadowColor: COLOR.SHADOW3,
      shadowOffset: { width: -2, height: 4 },
      shadowOpacity: 0.1,
      shadowRadius: 2,
      maxHeight: 100,
    },
    priceCardWrapper: {
      flex: 1,
      justifyContent: "space-between",
      flexDirection: "row",
      color: COLOR.TEXT,
    },
    pricesInfo: {
      flexDirection: "row",
    },
    // graphic: {
    //   flex: 1,
    //   backgroundColor: '#3c3a3a',
    //   padding: 15,
    //   borderRadius: 5,
    //   flexDirection: 'row',
    //   alignItems: 'center',
    //   gap: 10,
    //   shadowColor: '#171717',
    //   shadowOffset: {width: -2, height: 4},
    //   shadowOpacity: 0.1,
    //   shadowRadius: 2,
    //   maxHeight: 220,
    // },
    buysellWrapper: {
      marginVertical: 5,
    },
    buySellContainer: {
      width: "100%",
      gap: 20,
      flexDirection: "row",
      padding: 15,
      backgroundColor: COLOR.CARD_BACKGROUND1,
      justifyContent: "space-around",
      shadowColor: COLOR.SHADOW3,
      shadowOffset: { width: -2, height: 4 },
      shadowOpacity: 0.1,
      shadowRadius: 2,
      borderRadius: 5,
    },
    button: {
      padding: 10,
      borderRadius: 5,
      width: 150,
      backgroundColor: COLOR.CARD_BACKGROUND1,
      borderStyle: "solid",
      borderWidth: 2,
    },
    buySelected: {
      borderColor: COLOR.BUY,
    },
    sellSelected: {
      borderColor: COLOR.SELL,
    },
    notSelected: {
      borderColor: "grey",
    },
    buyButtonSelected: {
      backgroundColor: COLOR.BUY,
    },
    sellButtonSelected: {
      backgroundColor: COLOR.SELL,
    },
    notButtonSelected: {
      backgroundColor: "grey",
    },
    buyText: {
      color: "white",
      textAlign: "center",
      fontWeight: "bold",
    },
    sellTextWrapper: {
      borderRadius: 5,
      width: 50,
      padding: 5,
      fontSize: 16,
      fontWeight: "bold",
    },
    buyTextWrapper: {
      borderRadius: 5,
      width: 50,
      padding: 5,
      fontSize: 16,
      fontWeight: "bold",
    },
    sellText: {
      color: "white",
      textAlign: "center",
      fontWeight: "bold",
    },
    inputContainer: {
      backgroundColor: COLOR.CARD_BACKGROUND1,
      paddingHorizontal: 10,
      borderRadius: 5,
      flexDirection: "column",
      shadowColor: COLOR.SHADOW3,
      shadowOffset: { width: -2, height: 4 },
      shadowOpacity: 0.1,
      shadowRadius: 2,
      height: 110,
    },
    inputText: {
      textAlign: "center",
    },
    checkboxContainer: {
      flexDirection: "column",
      marginBottom: 10,
      width: "100%",
      gap: 10,
      padding: 10,
      backgroundColor: COLOR.CARD_BACKGROUND1,
      borderRadius: 5,
    },
    checkboxTitle: {
      fontSize: 16,
      fontWeight: "bold",
      color: COLOR.TEXT,
    },
    label: {
      margin: 8,
    },
    checkboxWrapper: {
      display: "flex",
      justifyContent: "flex-start",
      borderRadius: 5,
      borderWidth: 1,
      paddingHorizontal: 10,
      paddingVertical: 20,
      borderColor: COLOR.CARD_LOWER_OPACITY,
      borderStyle: "dashed",
      gap: 10,
    },
    buySellButton: {
      width: "100%",
      height: 40,
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 5,
      position: "absolute",
      bottom: 0,
      alignSelf: "center",
    },
  });

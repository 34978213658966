import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { Modal, Pressable, SafeAreaView, StyleSheet, Text, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native';
import DatePicker from 'react-native-date-picker';
import { FONTSIZE, IAuthContext, IColors } from '../../Global';
import getTradeReturnCodeDesc from '../../config/error-handle';
import { AuthContext } from '../../context/AuthContext';
import apiCall from '../../utils/apiCall';
import CheckBox from '../Checkbox';
import { showFlowMessage } from '../FlowMessage';
import PriceFormat from '../PriceFormat';
import PriceInput from '../PriceInputV2';
import { fields } from './constants';
import { useLanguage } from '../../context/Language/LanguageProvider';
import { Audio } from 'expo-av';

async function SoundError() {
	console.log("Loading Sound");
	const { sound } = await Audio.Sound.createAsync(
	  require("../../assets/sounds/soundserror.mp3")
	);
	console.log("Playing Sound");
	await sound.playAsync();
  }
async function SoundSuccess() {
	console.log("Loading Sound");
	const { sound } = await Audio.Sound.createAsync(
	  require("../../assets/sounds/soundssuccess.mp3")
	);
	console.log("Playing Sound");
	await sound.playAsync();
  }

interface IOrderPositionEditModal {
	priceCurrent: number;
	data: any;
	visible: boolean;
	setVisible: (visible: boolean) => void;
	fieldOption: 'open-positions' | 'open-orders';
	colors: IColors;
}

const OrderPositionEditModal: React.FC<IOrderPositionEditModal> = ({ visible, setVisible, priceCurrent, data, fieldOption, colors }) => {
	const { words, language } = useLanguage();
	const defaultVal = fields[fieldOption].reduce((acc: any, field: any) => {
		return {
			...acc,
			[field.key]: data[field.key],
		};
	}, {});

	const { group } = useContext<IAuthContext>(AuthContext);

	const [isEndDateOpen, setIsEndDateOpen] = useState<boolean>(false);
	const [endDate, setEndDate] = useState<Date>(dayjs().startOf('minute').add(1, 'day').toDate());

	// const {colors} = useTheme();
	const styles = Styles(colors);

	const currentSymbol = group?.Symbols.find((s) => s.Symbol.Symbol === data.Symbol);

	// ==> Take Profit ve Stop Loose checkbox'larının durumlarını tutarlar
	const [stopTP, setStopTP] = useState<boolean>(false);
	const [stopSL, setStopSL] = useState<boolean>(false);
	// <== Take Profit ve Stop Loose checkbox'larının durumlarını tutarlar

	const [priceTP, setPriceTP] = useState<number>(0.0);
	const [priceSL, setPriceSL] = useState<number>(0.0);
	const [priceOrder, setPriceOrder] = useState<number>(0.1);
	const [step, setStep] = useState<number>(0.1);
	const [digits, setDigits] = useState<number>(1);

	if (!currentSymbol) {
		return <View></View>;
	}

	useEffect(() => {
		if (!priceSL || priceSL == 0.0) {
			setPriceSL(isNaN(Number(priceCurrent)) ? Number(Number(data?.PriceOrder).toFixed(currentSymbol.Symbol.Digits)) : Number(Number(priceCurrent).toFixed(currentSymbol.Symbol.Digits)));
		}
	}, [stopSL]);

	useEffect(() => {
		if (!priceTP || priceTP == 0.0) {
			setPriceTP(isNaN(Number(priceCurrent)) ? Number(Number(data?.PriceOrder).toFixed(currentSymbol.Symbol.Digits)) : Number(Number(priceCurrent).toFixed(currentSymbol.Symbol.Digits)));
		}
	}, [stopTP]);

	useEffect(() => {
		if (Number(defaultVal['PriceTP']) > 0.0) {
			setStopTP(true);
			setPriceTP(isNaN(Number(defaultVal['PriceTP'])) ? 0.0 : Number(Number(defaultVal['PriceTP']).toFixed(currentSymbol.Symbol.Digits)));
		} else {
			setPriceTP(isNaN(Number(priceCurrent)) ? Number(Number(data?.PriceOrder).toFixed(currentSymbol.Symbol.Digits)) : Number(Number(priceCurrent).toFixed(currentSymbol.Symbol.Digits)));
		}

		if (Number(defaultVal['PriceSL']) > 0.0) {
			setStopSL(true);
			setPriceSL(isNaN(Number(defaultVal['PriceSL'])) ? 0.0 : Number(Number(defaultVal['PriceSL']).toFixed(currentSymbol.Symbol.Digits)));
		} else {
			setPriceSL(isNaN(Number(priceCurrent)) ? Number(Number(data?.PriceOrder).toFixed(currentSymbol.Symbol.Digits)) : Number(Number(priceCurrent).toFixed(currentSymbol.Symbol.Digits)));
		}

		if (fieldOption != 'open-positions') {
			setPriceOrder(isNaN(Number(defaultVal['PriceOrder'])) ? 0.0 : Number(Number(defaultVal['PriceOrder']).toFixed(currentSymbol.Symbol.Digits)));
		}

		setStep(currentSymbol.Symbol.VolumeStep / 10000);
		setDigits(currentSymbol.Symbol.Digits);

		// if (fieldOption != 'open-positions') {
		//   setPriceOrder(
		//     isNaN(Number(defaultVal['PriceOrder']))
		//       ? 0.0
		//       : Number(defaultVal['PriceOrder']),
		//   );
		// }
	}, [visible]);

	const handleModify = () => {
		apiCall(
			'/modify-trade',
			{
				login: data.ID,
				ticket: data.Position || data.Order,
				priceSl: stopSL ? priceSL : '0.0',
				priceTp: stopTP ? priceTP : '0.0',
				priceOrder: priceOrder,
				volume: data.VolumeCurrent,
				timeExpiration: dayjs(endDate).format('MM.DD.YYYY HH:mm'),
				typeTime: 2,
				modifyType: fieldOption === 'open-positions' ? 'modify-position' : 'modify-order',
			},
			'POST',
		)
			.then((response) => {
				setVisible(false);
				if (response.Retcode) {
					SoundError();
					showFlowMessage({
						message: words['FlowMessage_Timeout'],
						type: 'info',
						duration: 1000,
					});
				} else {
					SoundSuccess();
					showFlowMessage({
						message: `${fieldOption === 'open-positions' ? words['Position'] : words['Order']} ${words['FlowMessage_ModifySuccess']}\n${words['Retcode_' + response.Retcode]}`,
						type: 'success',
						duration: 1000,
					});
				}
			})
			.catch((error) => {
				SoundError();
				showFlowMessage({
					message: error,
					type: 'error',
					duration: 1000,
				});
			});
	};

	

	return (
		<Modal
			visible={visible}
			transparent
		>
			<SafeAreaView style={styles.safeArea}>
				<TouchableWithoutFeedback onPress={() => setVisible(false)}>
					<View style={styles.modalOverlay} />
				</TouchableWithoutFeedback>
				<View style={styles.content}>
					<View style={styles.infoHeader}>
						<Text style={styles.title}>{fieldOption === 'open-positions' ? words['ModifyPosition_Title'] : words['ModifyOrder_Title']}</Text>
						<Text style={styles.idText}>
							{fieldOption === 'open-positions' ? words['ModifyPosition_Position'] : words['ModifyOrder_Order']}
							{' :'}
							{fieldOption === 'open-positions' ? data?.Position : data?.Order}
						</Text>

						<Text style={styles.idText}>
							{words['ModifyPosition_PriceCurrent']} {' :'}
							<PriceFormat
								value={priceCurrent}
								digits={digits}
								style={styles.idText}
							/>
						</Text>
					</View>
					<View style={styles.formWrapper}>
						{fieldOption === 'open-positions' ? (
							<>
								<View style={styles.inputLabelWrapper}>
									<CheckBox
										label={words['ModifyPosition_PriceTP']}
										onPress={() => setStopTP(!stopTP)}
										checked={stopTP}
									/>
									<View style={{ opacity: stopTP ? 1 : 0.3 }}>
										<PriceInput
											digits={digits}
											step={step}
											placeHolder=''
											visible={stopTP}
											defaultValue={priceTP}
											value={priceTP}
											setValue={setPriceTP}
										/>
									</View>
								</View>
								<View style={styles.inputLabelWrapper}>
									<CheckBox
										label={words['ModifyPosition_PriceSL']}
										onPress={() => setStopSL(!stopSL)}
										checked={stopSL}
									/>
									<View style={{ opacity: stopSL ? 1 : 0.3 }}>
										<PriceInput
											digits={digits}
											step={step}
											placeHolder=''
											visible={stopSL}
											defaultValue={priceSL}
											value={priceSL}
											setValue={setPriceSL}
										/>
									</View>
								</View>
							</>
						) : (
							<>
								<View style={styles.inputLabelWrapper}>
									<Text
										style={{
											color: colors.TEXT,
											fontWeight: '400',
											fontSize: 18,
										}}
									>
										{words['ModifyOrder_PriceOrder']}
									</Text>
									<PriceInput
										digits={digits}
										step={step}
										placeHolder=''
										visible={true}
										defaultValue={priceOrder}
										value={priceOrder}
										setValue={setPriceOrder}
									/>
								</View>
								<View style={styles.inputLabelWrapper}>
									<CheckBox
										label={words['ModifyOrder_PriceTP']}
										onPress={() => setStopTP(!stopTP)}
										checked={stopTP}
									/>
									<View style={{ opacity: stopTP ? 1 : 0.3 }}>
										<PriceInput
											digits={digits}
											step={step}
											placeHolder=''
											visible={stopTP}
											defaultValue={priceTP}
											value={priceTP}
											setValue={setPriceTP}
										/>
									</View>
								</View>
								<View style={styles.inputLabelWrapper}>
									<CheckBox
										label={words['ModifyOrder_PriceSL']}
										onPress={() => setStopSL(!stopSL)}
										checked={stopSL}
									/>
									<View style={{ opacity: stopSL ? 1 : 0.3 }}>
										<PriceInput
											digits={digits}
											step={step}
											visible={stopSL}
											defaultValue={priceSL}
											value={priceSL}
											setValue={setPriceSL}
										/>
									</View>
								</View>
								<View>
									<Text
										style={{
											color: colors.TEXT,
											fontWeight: '400',
											fontSize: 18,
										}}
									>
										{words['ModifyOrder_LastTime']}
									</Text>
									<TouchableOpacity onPress={() => setIsEndDateOpen(true)}>
										<Text
											style={{
												width: '100%',
												fontWeight: '500',
												fontSize: 16,
												padding: 10,
												textAlign: 'center',
												color: colors.TEXT,
											}}
										>
											{dayjs(endDate).format('YYYY-MM-DD HH:mm')}
										</Text>
									</TouchableOpacity>
									{/* <DatePicker
										//maximumDate={dayjs().add(1,"day").toDate()}
										modal
										title={words['ModifyOrder_LastTimeMessage']}
										locale={language}
										confirmText={words['ModifyOrder_LastTimeOk']}
										cancelText={words['ModifyOrder_LastTimeCancel']}
										mode='datetime'
										open={isEndDateOpen}
										date={endDate}
										onConfirm={(date) => {
											setEndDate(date);
											setIsEndDateOpen(false);
										}}
										onCancel={() => {
											setIsEndDateOpen(false);
										}}
									/> */}
								</View>
							</>
						)}
					</View>
					<View style={styles.actionWrapper}>
						<Pressable
							onPress={() => {
								setVisible(false);
							}}
							style={({ pressed }) => [styles.actionBtn, { backgroundColor: colors.REJECT }, { opacity: pressed ? 0.7 : 1 }]}
						>
							<Text style={[styles.actionBtnText, { backgroundColor: colors.REJECT, color: colors.REJECT_TEXT }]}>{fieldOption === 'open-positions' ? words['ModifyPosition_Cancel'] : words['ModifyOrder_Cancel']}</Text>
						</Pressable>
						<Pressable
							onPress={handleModify}
							style={({ pressed }) => [styles.actionBtn, { backgroundColor: colors.ACCEPT }, { opacity: pressed ? 0.7 : 1 }]}
						>
							<Text style={[styles.actionBtnText, { backgroundColor: colors.ACCEPT, color: colors.ACCEPT_TEXT }]}>{fieldOption === 'open-positions' ? words['ModifyPosition_Accept'] : words['ModifyOrder_Accept']}</Text>
						</Pressable>
					</View>
				</View>
			</SafeAreaView>
		</Modal>
	);
};

export default OrderPositionEditModal;

const Styles = (COLOR: IColors) =>
	StyleSheet.create({
		safeArea: {
			flex: 1,

			justifyContent: 'center',
			alignItems: 'center',
		},
		modalOverlay: {
			position: 'absolute',
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
			backgroundColor: COLOR.CARD_LOWER_OPACITY,
		},
		content: {
			width: '80%',
			backgroundColor: COLOR.CARD_BACKGROUND1,
			padding: 20,
			borderRadius: 10,
		},
		infoHeader: {
			gap: 4,
		},
		title: {
			color: COLOR.TEXT,
			fontSize: 16,
			fontWeight: 'bold',
			textAlign: 'center',
		},
		idText: {
			color: COLOR.TEXT,
			fontSize: FONTSIZE.l,
			textAlign: 'center',
		},
		formWrapper: {
			marginTop: 20,
			gap: 10,
		},
		inputLabelWrapper: {
			gap: 4,
		},
		actionWrapper: {
			flexDirection: 'row',
			justifyContent: 'space-between',
			marginTop: 20,
			gap: 10,
		},
		actionBtn: {
			flex: 1,
			borderRadius: 2,
			padding: 6,
		},
		actionBtnText: {
			textAlign: 'center',
			fontSize: 14,
			fontWeight: 'bold',
		},
	});

import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Pressable, StyleSheet, Text, TouchableOpacity, View } from "react-native";

import SymbolIcon from "./SymbolIcon";
// import {useNavigation} from '@react-navigation/native';
import { useFocusEffect } from "@react-navigation/native";
import socket from "../../../config/socket";
import { AuthContext } from "../../../context/AuthContext";
import { useTheme } from "../../../context/Theme/ThemeProvider";
import apiCall from "../../../utils/apiCall";
import { newDayjsWithTodayFormat, now } from "../../../utils/dateUtil";
import PriceFormat from "../../PriceFormat";
import { IGroupSymbol } from "../../../Global";
import { AntDesign } from "@expo/vector-icons";
import { useLanguage } from "../../../context/Language/LanguageProvider";
import { marketStatusController } from "../../../utils/marketUtils";
interface Props {
  groupSymbol: IGroupSymbol;
  navigation: any;
  backgroundColor: string;
  textColor: string;
  // spread:any;
}


const SymbolListItem: React.FC<Props> = ({
  groupSymbol,
  navigation,
  backgroundColor,
  textColor,
}) => {
  const { user, setFavorite } = useContext(AuthContext);
	const { colors } = useTheme();
	const { words } = useLanguage();
	const styles = Styles(colors);
	const [nowTime, setNowTime] = useState(now().add(-1, 'second').format('YYYY-MM-DDTHH:mm:ssZ'));
	// const marketOpen = groupSymbol.TradeMode !== 0 && isMarketOpen(groupSymbol?.Symbol?.Sessions);
	// const spread = React.useRef(0);
	const [lastTick, setLastTick] = React.useState({
		Bid: groupSymbol?.Symbol?.Tick?.Bid,
		Ask: groupSymbol?.Symbol?.Tick?.Ask,
		Datetime: groupSymbol?.Symbol?.Tick?.Datetime,
	});

	const [marketStatus, setMarketStatus] = useState(marketStatusController(groupSymbol, lastTick.Datetime));
	//const [marketOpen, setMarketOpen] = useState({buy: !marketStatusController(groupSymbol, lastTick.Datetime).isClosed, sell: !marketStatusController(groupSymbol, lastTick.Datetime).isClosed});

	const socketClosed = useRef(false);

	const lastUpdate = newDayjsWithTodayFormat(lastTick?.Datetime * 1000);

	useFocusEffect(() => {
		setNowTime(now().format('YYYY-MM-DDTHH:mm:ssZ'));
		const intervalId = setInterval(() => {
			setNowTime(now().format('YYYY-MM-DDTHH:mm:ssZ'));
		}, 5000);
		return () => {
			clearInterval(intervalId);
		};
	});

	useEffect(() => {
		setMarketStatus(marketStatusController(groupSymbol, lastTick.Datetime));
		// console.log('NOW TIME: ', nowTime);
		// const isClosed = newDayjs.unix(lastTick.Datetime).add(groupSymbol.Symbol.QuotesTimeout, 'seconds').isBefore(now());
		// setIsClosed(isClosed);
	}, [nowTime, lastTick]);

	// useFocusEffect(
	// 	useCallback(() => {
	// 		setMarketStatus(marketStatusController(groupSymbol, lastTick.Datetime));
	// 		//setMarketOpen(!marketStatusController(groupSymbol, lastTick.Datetime).isClosed);
	// 	}, [groupSymbol?.TradeMode, groupSymbol?.Symbol?.Sessions, lastTick]),
	// );

	useFocusEffect(
		useCallback(() => {
			if (!groupSymbol.Symbol.Symbol) {
				console.log('Symbol not set!');
				return;
			}
			console.log('TICK:', groupSymbol.Symbol.Symbol);

			socket.on(groupSymbol.Symbol.Symbol, (data: any) => {
				groupSymbol.Symbol.Tick.Datetime = data.Datetime;
				groupSymbol.Symbol.Tick.Bid = data.Bid;
				groupSymbol.Symbol.Tick.Ask = data.Ask;
				setLastTick({ ...data });
			});

			return () => {
				if (!socketClosed.current) {
					socket.off(groupSymbol.Symbol.Symbol);
				}
			};
		}, [groupSymbol?.Symbol?.Symbol]),
	);

	const [isFavorited, setIsFavorited] = useState(false);
	const pushPopFavorite = () => {
		if (user?.IsInvestor) {
			return;
		}

		apiCall(
			'/set-favorite',
			{
				symbol: groupSymbol.Symbol.Symbol,
				isFavorited: !isFavorited,
			},
			'POST',
		)
			.then((response) => {
				setFavorite(groupSymbol.Symbol.Symbol, response.isFavorited);
				setIsFavorited(response.isFavorited);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useFocusEffect(
		useCallback(() => {
			if (!user || !user.FavoriteSymbols) {
				return;
			}
			const isFavorited = user.FavoriteSymbols.indexOf(groupSymbol.Symbol.Symbol) > -1;
			setIsFavorited(isFavorited);
		}, [user, user?.FavoriteSymbols?.length]),
	);

	const navigateToTrade = () => {
		socketClosed.current = true;
		socket.off(groupSymbol.Symbol.Symbol);
		navigation.navigate('Trade', {
			groupSymbol,
		});
	};

  return (
    <View style={[styles.wrapper, { backgroundColor: backgroundColor }]}>
      {marketStatus.id == 0 && (
				<View style={styles.closedMarketWrapper}>
					<Text
						style={{
							textTransform: 'uppercase',
							fontWeight: 'bold',
							letterSpacing: 2,
							color: colors.TEXT,
						}}
					>
						{marketStatus.title}
					</Text>
				</View>
			)}
      <View>
        {!user?.IsInvestor && (
          <TouchableOpacity onPress={pushPopFavorite}>
            {!isFavorited ? (
              <AntDesign name="staro" size={24} color={colors.TEXT} />
            ) : (
              <AntDesign name="star" size={24} color={colors.TEXT} />
            )}
          </TouchableOpacity>
        )}
      </View>
      <View style={{ flex: 1 }}>
        <Pressable onPress={navigateToTrade} style={[styles.rightSide]}>
          <View style={styles.infoWrapper}>
            <SymbolIcon
              Symbol={groupSymbol.Symbol.Symbol}
              CurrencyBase={groupSymbol.Symbol.CurrencyBase}
              CurrencyProfit={groupSymbol.Symbol.CurrencyProfit}
              test={groupSymbol.Symbol}
              Category={groupSymbol.Symbol.Category}
            />
            <View>
              <Text style={[styles.text, { color: textColor }]}>
                {groupSymbol.Symbol.Symbol}
              </Text>
              <Text style={[styles.spreadText, { color: textColor }]}>
                {words["Spread"]}{" "}
                {Math.round(
                  Math.abs(lastTick?.Bid - lastTick?.Ask) *
                    Math.pow(10, groupSymbol.Symbol.Digits)
                )}
              </Text>
              <Text style={[styles.spreadText, { color: textColor }]}>
                {lastUpdate}
              </Text>
            </View>
          </View>
          <View style={styles.pricesWrapper}>
            <View style={styles.salesWrapper}>
              <Text
                style={[
                  styles.text,
                  styles.priceTitleText,
                  { color: textColor },
                ]}
              >
                {words["Sell"]}
              </Text>
              <Text
                style={[styles.text, styles.priceText, { color: colors.SELL }]}
              >
                <PriceFormat
                  value={lastTick?.Bid}
                  digits={groupSymbol?.Symbol?.Digits}
                  showCurrency={false}
                  textColor={colors.SELL}
                />
                {/* <SymbolPrice symbol={groupSymbol?.Symbol} defaultPrice={groupSymbol?.Symbol?.Tick?.Bid} priceType="Bid"/> */}
              </Text>
            </View>
            <View style={styles.buyWrapper}>
              <Text
                style={[
                  styles.text,
                  styles.priceTitleText,
                  { color: textColor },
                ]}
              >
                {words["Buy"]}
              </Text>
              <Text
                style={[styles.text, styles.priceText, { color: colors.BUY }]}
              >
                {/* <SymbolPrice symbol={groupSymbol?.Symbol} defaultPrice={groupSymbol?.Symbol?.Tick?.Ask} priceType="Ask"/> */}
                <PriceFormat
                  value={lastTick?.Ask}
                  digits={groupSymbol?.Symbol?.Digits}
                  showCurrency={false}
                  textColor={colors.BUY}
                />
              </Text>
            </View>
          </View>
        </Pressable>
      </View>
    </View>
  );
};
export default SymbolListItem;

const Styles = (COLOR: any) =>
  StyleSheet.create({
    wrapper: {
      position: "relative",
      flex: 1,
      padding: 15,
      borderRadius: 5,
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      marginVertical: 5,
      gap: 10,
      shadowColor: COLOR.SHADOW3,
      shadowOffset: { width: -2, height: 4 },
      shadowOpacity: 0.1,
      shadowRadius: 2,
    },
    rightSide: {
      flex: 1,
      justifyContent: "space-between",
      flexDirection: "row",
      alignItems: "center",
    },
    closedMarketWrapper: {
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: COLOR.CLOSED_MARKET,
      elevation: 2,
      zIndex: 2,
    },
    infoWrapper: {
      flexDirection: "row",
      alignItems: "center",
      gap: 12,
    },
    pricesWrapper: {
      flex: 1,
      justifyContent: "space-between",
      flexDirection: "row",
      gap: 20,
    },
    salesWrapper: {
      flex: 1,
      gap: 3,
    },
    buyWrapper: {
      flex: 1,
      gap: 3,
    },
    text: {
      fontWeight: "500",
    },
    spreadText: {
      fontWeight: "500",
      fontSize: 9,
    },
    priceTitleText: {
      fontWeight: "500",
      textAlign: "right",
    },
    priceText: {
      fontWeight: "600",
      textAlign: "right",
    },
  });

import React from "react";
import { StyleProp, StyleSheet, Text, TextStyle, TouchableOpacity, View } from "react-native";
import Clipboard from "@react-native-clipboard/clipboard";
import { FONTSIZE, IColors } from "../../Global";
import { useTheme } from "../../context/Theme/ThemeProvider";
import { showFlowMessage } from "../FlowMessage";
import { useLanguage } from "../../context/Language/LanguageProvider";

interface Prop {
  type:
    | "basic"
    | "route"
    | "basicWithCopy"
    | "basicWithCopyXL"
    | "oneWithCustomStyle";
  args?: any;
  titleStyle?: StyleProp<TextStyle> | undefined;
  valueStyle?: StyleProp<TextStyle> | undefined;
}

const InfoCard: React.FC<Prop> = ({ type, args, titleStyle, valueStyle }: Prop, { navigation }) => {
  const { words } = useLanguage();
  const { colors } = useTheme();

  const style = Style(colors);

  function saveToClipboard(key: string, value: string) {
    Clipboard.setString(value);
    showFlowMessage({
      message: key + ` ${words["FlowMessage_InfoCardCopied"]} `,
      type: "success",
    });
  }

  if (type == "basic") {
    return (
      <View
        style={[
          style.BgColors,
          {
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 5,
          },
        ]}
      >
        <Text style={[style.Key, titleStyle]}>{args?.title}</Text>
        <Text style={[style.Value, valueStyle]}>{args?.value}</Text>
      </View>
    );
  } else if (type == "route") {
    return (
      <TouchableOpacity
        onPress={() => navigation.navigate(args?.route, args?.params)}
        style={[
          style.BgColors,
          {
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 5,
          },
        ]}
      >
        <Text style={[style.Key, titleStyle]}>{args?.title}</Text>
        <Text style={[style.Value, valueStyle]}>{args?.value}</Text>
      </TouchableOpacity>
    );
  } else if (type == "oneWithCustomStyle") {
    return (
      <View
        style={[
          style.BgColors,
          {
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 5,
          },
        ]}
      >
        <Text style={args?.valueStyle}>{args?.value}</Text>
      </View>
    );
  }
  if (type == "basicWithCopy") {
    return (
      <TouchableOpacity
        onPress={() => {
          saveToClipboard(args?.title, args?.value);
        }}
        style={[
          style.BgColors,
          {
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 5,
          },
        ]}
      >
        <Text style={[style.Key, titleStyle]}>{args?.title}</Text>
        <Text style={[style.Value, valueStyle]}>{args?.value}</Text>
      </TouchableOpacity>
    );
  } else if (type == "basicWithCopyXL") {
    return (
      <View style={[style.BgColors, { flexDirection: "row" }]}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 5,
          }}
        >
          <Text style={[style.Key, titleStyle]}>{args?.title}</Text>
          <Text style={[style.Value, valueStyle]}>{args?.value}</Text>
        </View>
        <View></View>
      </View>
    );
  }
};

export default InfoCard;

const Style = (COLOR: IColors) =>
  StyleSheet.create({
    BgColors: {
      backgroundColor: COLOR.CARD_BACKGROUND1,
      paddingVertical: "3%",
      paddingHorizontal: "4%",
      borderRadius: 5,
    },
    Key: {
      color: COLOR.TEXT,
      fontSize: FONTSIZE.l,
      textAlign: "justify",
      flexBasis: "25%",
    },
    Value: {
      color: COLOR.TEXT,
      fontSize: FONTSIZE.l,
      fontWeight: "600",
      flexBasis: "75%",
      textAlign: "right",
    },
  });

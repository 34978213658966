import { Image, StyleSheet, Text, View } from "react-native";
import { useTheme } from "../../context/Theme/ThemeProvider";

const ProminentInvestors = () => {
  const { colors } = useTheme();
  const styles = Styles(colors);

  return (
    <View>
      <View style={styles.headerWrapper}>
        <Text style={styles.title}>Öne Çıkan Traderlar</Text>
      </View>
      <View style={styles.contentWrapper}>
        <View style={styles.traderItemWrapper}>
          <Image
            source={require("../../assets/trader1.png")}
            style={styles.traderImage}
          />
          <Text style={styles.nameText}>Murat Erdoğan</Text>
          <Text style={styles.ratio}>%223.32</Text>
        </View>
        <View style={styles.traderItemWrapper}>
          <Image
            source={require("../../assets/trader2.png")}
            style={styles.traderImage}
          />
          <Text style={styles.nameText}>Hüseyin Turan</Text>
          <Text style={styles.ratio}>%198.45</Text>
        </View>
        <View style={styles.traderItemWrapper}>
          <Image
            source={require("../../assets/trader3.png")}
            style={styles.traderImage}
          />
          <Text style={styles.nameText}>Sadullah Ateş</Text>
          <Text style={styles.ratio}>%186.83</Text>
        </View>
      </View>
    </View>
  );
};

export default ProminentInvestors;

const Styles = (COLOR: any) =>
  StyleSheet.create({
    headerWrapper: {
      borderBottomWidth: 1,
      borderBottomColor: COLOR.TEXT,
      paddingBottom: 5,
      alignSelf: "flex-start",
    },
    title: {
      color: COLOR.TEXT,
      fontSize: 20,
      fontWeight: "bold",
      marginTop: 10,
    },
    contentWrapper: {
      flexDirection: "row",
      marginTop: 14,
      gap: 8,
      flexWrap: "wrap",
    },
    traderItemWrapper: {
      gap: 6,
      alignItems: "center",
    },
    traderImage: {
      width: 50,
      height: 50,
      borderRadius: 50,
    },
    ratio: {
      color: COLOR.GREEN,
      fontSize: 12,
    },
    nameText: {
      color: COLOR.TEXT,
      fontSize: 12,
    },
  });

interface ILanguage {
	[key: string]: string;
}

export const English: ILanguage = {
	// General ==>
	Exit: 'Exit',
	Login: 'Login',
	LoginID: 'ID',
	UserName: 'Username',
	Password: 'Password',
	Buy: 'Buy',
	Sell: 'Sell',
	Profit: 'Profit',
	Loss: 'Loss',
	Home: 'Home',
	Markets: 'Markets',
	Transaction: 'My Transactions',
	Profile: 'Profile',
	Spread: 'Spread',
	Search: 'Search',
	Name: 'Name',
	FirstName: 'First Name',
	LastName: 'Last Name',
	Email: 'Email',
	Phone: 'Phone',
	Amount: 'Amount',
	Button_SubmitDepositRequest: 'Submit Deposit Request',
	Button_SubmitWithdrawRequest: 'Submit Withdraw Request',
	Button_SubmitYourComplaint: 'Submit Your Complaint',
	Position: 'Position',
	Order: 'Order',
	// <== General
	// Login ==>
	Login_RegisteredAccounts: 'Registered Accounts',
	Login_Servers: 'Servers',
	Login_NoRegisteredAccounts: 'No registered accounts found.',
	Login_ToolTip_1: 'The login screen has been refreshed.',
	Login_ToolTip_2: 'After entering the username and password, clicking the "Login" button logs you in and saves your login information.',
	Login_ToolTip_3: 'If you press and hold the "Login" button, you log in without saving your login information.',
	Login_ToolTip_4: 'When you have registered users, you can log in with any user you want.',
	Login_ToolTip_5: 'If you press and hold a registered user, you remove the pressed account from the saved ones.',
	Login_ToolTip_6: 'You need to select a server from here.',
	Login_ToolTip_7: 'Be especially attentive to the selected server when logging in.',
	// <== Login
	// Side Menu ==>
	SideMenu_Deposit: 'Deposit',
	SideMenu_Withdraw: 'Withdraw',
	SideMenu_Notifications: 'Notifications',
	SideMenu_BankStatement: 'Bank Statement',
	SideMenu_Transaction: 'Transaction History',
	SideMenu_ChangePassword: 'Change Password',
	SideMenu_Traders: 'Traders',
	SideMenu_Robots: 'Robots',
	SideMenu_LiveSupport: 'Live Support',
	SideMenu_CustomerComplaint: 'Customer Complaint',
	SideMenu_Settings: 'Settings',
	SideMenu_Share: 'Share with Friends',
	SideMenu_References: 'References',
	// <== Side Menu
	// Tabs ==>
	Favorites: 'Favorites',
	Forex: 'Forex',
	Crypto: 'Crypto',
	Indices: 'Indices',
	Metal: 'Metals',
	Energy: 'Energy',
	Stock: 'Stocks',
	History: 'History',
	Positions: 'Open Positions',
	Orders: 'Open Orders',
	// <== Tabs
	// Home Screen ==>
	Home_RecommendedActions: 'Recommended Actions of the Day',
	Home_Announcement: 'You can star the pair you want to follow.',
	// <== Home Screen
	// Router ==>
	Router_Login: 'Login',
	Router_TransactionDetails: 'My Transactions',
	Router_Trade: 'Trade',
	Router_TradingView: 'Detailed Chart',
	Router_DepositSelection: 'Deposit',
	Router_DepositWithCash: 'Deposit with Bank Transfer',
	Router_Withdraw: 'Withdraw with Bank Transfer',
	Router_CurrencySelection: 'Deposit with Bank Transfer',
	Router_BankTransaction: 'Deposit with Bank Transfer',
	Router_DepositWithCrypto: 'Deposit with Crypto',
	Router_ChangePassword: 'Change Password',
	Router_CustomerComplaint: 'Customer Complaint',
	Router_References: 'References',
	Router_AddReferences: 'Add References',
	Router_Settings: 'Settings',
	Router_Home: 'Home',
	Router_Symbols: 'Markets',
	Router_Transaction: 'My Transactions',
	// <== Router
	// Profile ==>
	Balance: 'Balance',
	Equity: 'Equity',
	Credit: 'Credit',
	Margin: 'Used Margin',
	Margin_Free: 'Free Margin',
	Margin_Level: 'Margin Level',
	Share: 'Share with Friends',
	Floating: 'Floating Profit/Loss',
	UserCard_PhoneError: 'Phone number is not registered!',
	// <== Profile
	// Deposit Selection ==>
	DepositSelection_WithCash: 'Deposit with Bank Transfer',
	DepositSelection_WithCrypto: 'Deposit with Crypto',
	DepositSelection_Warning: 'Please select the appropriate deposit method.',
	// <== Deposit Selection
	// Deposit With Cash ==>
	DepositWithCash_Warning: 'To get the appropriate bank transfer information, please select the bank you want to fund, and then select the currency.',
	// <== Deposit With Cash
	// Currency Selection ==>
	CurrencySelection_Warning: 'To get the appropriate bank transfer information, please select the bank you want to fund, and then select the currency.',
	// <== Currency Selection
	// Deposit_Trasaction ==>
	DepositTransaction_BankName: 'Bank Name',
	DepositTransaction_Name: 'Recipient Name',
	DepositTransaction_Currency: 'Currency',
	DepositTransaction_IBAN: 'IBAN',
	DepositTransaction_Description: 'Description',
	// <== Deposit_Trasaction
	// Deposit_With_Crypto ==>
	DepositWithCrypto_Warning_1: 'To get the appropriate crypto information, please select the crypto you want to fund, and then select the network.',
	DepositWithCrypto_Warning_2: 'After selecting the network unit, you can copy it from the information card.',
	DepositWithCrypto_SelectCrypto: 'Select Crypto',
	DepositWithCrypto_SelectNetwork: 'Select Network Unit',
	DepositWithCrypto_SelectNetwork_Warning: 'Select a Crypto First',
	DepositWithCrypto_Message: 'Message',
	// <== Deposit_With_Crypto
	// Customer Complaint ==>
	CustomerComplaint_Message: 'Your Message',
	CustomerComplaint_Warning: 'You can convey your issue by writing it in the field below. It will be transmitted via WhatsApp.',
	// <== Customer Complaint
	// Settings ==>
	Settings_Language: 'Language',
	Settings_SearchLanguage: 'Search Language...',
	Settings_DarkMode: 'Dark Mode',
	Settings_Notifications: 'Notifications',
	// <== Settings
	// Trade ==>
	Trade_AdditionalTransactions: 'Additional Transactions',
	Trade_MarketOrder: 'Market Order',
	Trade_TakeProfit: 'Take Profit',
	Trade_StopLoss: 'Stop Loss',
	Trade_Create: 'Create',
	Trade_BuyOrder: 'Create Buy Order',
	Trade_SellOrder: 'Create Sell Order',
	Trade_BuyLimit: 'Create Limit Buy Order',
	Trade_SelllLimit: 'Create Limit Sell Order',
	Trade_BuyStop: 'Create Stop Buy Order',
	Trade_SellStop: 'Create Stop Sell Order',

	Trade_Type_0: 'Buy',
	Trade_Type_1: 'Sell',
	Trade_Type_2: 'Buy Limit',
	Trade_Type_3: 'Sell Limit',
	Trade_Type_4: 'Buy Stop',
	Trade_Type_5: 'Sell Stop',
	Trade_Type_6: 'Buy Stop Limit',
	Trade_Type_7: 'Sell Stop Limit',
	Trade_Type_8: 'Close by Order',
	// <== Trade
	// MyTransactions Positions ==>
	Positions_TimeCreate: 'Date',
	Positions_Position: 'Position',
	Positions_VolumeCurrent: 'Lot',
	Positions_PriceCurrent: 'Current Price',
	Positions_PriceSL: 'Stop Loss',
	Positions_PriceTP: 'Take Profit',
	Positions_Swap: 'Swap',
	Positions_Profit: 'Profit/Loss',
	Position_PartialClose: 'Partial Close',
	Position_ClosePosition: 'Close Position',
	Position_ModifyPosition: 'Modify',
	// <== MyTransactions Positions
	// MyTransactions PositionsModify ==>
	ModifyPosition_Title: 'Modify Position',
	ModifyPosition_Position: 'Position No',
	ModifyPosition_PriceCurrent: 'Current Price',
	ModifyPosition_PriceSL: 'Stop Loss',
	ModifyPosition_PriceTP: 'Take Profit',
	ModifyPosition_Accept: 'Accept',
	ModifyPosition_Cancel: 'Cancel',
	// <== MyTransactions PositionsModify
	// MyTransactions OrderModify ==>
	ModifyOrder_Title: 'Modify Order',
	ModifyOrder_Order: 'Order No',
	ModifyOrder_PriceCurrent: 'Current Price',
	ModifyOrder_PriceOrder: 'Order Price',
	ModifyOrder_PriceSL: 'Stop Loss',
	ModifyOrder_PriceTP: 'Take Profit',
	ModifyOrder_LastTime: 'Expiration Date',
	ModifyOrder_LastTimeMessage: 'Select an expiration date for your order.',
	ModifyOrder_Accept: 'Accept',
	ModifyOrder_Cancel: 'Cancel',
	ModifyOrder_LastTimeOk: 'OK',
	ModifyOrder_LastTimeCancel: 'Cancel',
	// <== MyTransactions OrderModify
	// MyTransactions Orders ==>
	Orders_Order: 'Ticket',
	Orders_Lot: 'Lot',
	Orders_PriceOrder: 'Order Price',
	Orders_PriceSL: 'Stop Loss',
	Orders_PriceTP: 'Take Profit',
	Orders_PriceCurrent: 'Current Price',
	Orders_Reason: 'Reason',
	Orders_Comment: 'Comment',
	Orders_DeleteOrder: 'Delete Order',
	Orders_ModifyOrder: 'Modify',
	Orders_Type_0: 'Customer',
	Orders_Type_1: 'Expert',
	Orders_Type_2: 'Seller',
	Orders_Type_3: 'SL',
	Orders_Type_4: 'TP',
	Orders_Type_5: 'SO',
	Orders_Type_6: 'Rounding',
	Orders_Type_7: 'External Customer',
	Orders_Type_8: 'V Tolerance',
	// <== MyTransactions Orders
	// Symbol Properties ==>
	SymbolProperties_SwapRates: 'Swap Rates',
	SymbolProperties_Sessions: 'Sessions',
	SymbolProperties_SessionsTrade: 'Trade',
	SymbolProperties_ContractSize: 'Contract Size',
	SymbolProperties_Spread: 'Spread',
	SymbolProperties_StopsLevel: 'Stops Level',
	SymbolProperties_CurrencyMargin: 'Currency Margin',
	SymbolProperties_VolumeMin: 'Minimum Volume',
	SymbolProperties_VolumeMax: 'Maximum Volume',
	SymbolProperties_Monday: 'Monday',
	SymbolProperties_Tuesday: 'Tuesday',
	SymbolProperties_Wednesday: 'Wednesday',
	SymbolProperties_Thursday: 'Thursday',
	SymbolProperties_Friday: 'Friday',
	SymbolProperties_Saturday: 'Saturday',
	SymbolProperties_Sunday: 'Sunday',
	// <== Symbol Properties
	// History ==>
	History_Button: 'Filter',
	History_Positions: 'Closed Positions',
	History_Balance: 'Balance History',
	History_MyTransactions: 'My Transactions',
	History_DatePicker_StartTitle: 'Select Start Date',
	History_DatePicker_EndTitle: 'Select End Date',
	History_DatePicker_Ok: 'Ok',
	History_DatePicker_Cancel: 'Cancel',
	History_NoData: 'No history to show.',
	// <== History
	// History Positions ==>
	HistoryPositions_Symbol: 'Symbol',
	// HistoryPositions_Deal: 'Deal',
	HistoryPositions_PositionID: 'Position',
	HistoryPositions_Volume: 'Lot',
	HistoryPositions_Price: 'Opening Price',
	HistoryPositions_PricePosition: 'Closing Price',
	HistoryPositions_PriceSL: 'Stop Loss',
	HistoryPositions_PriceTP: 'Take Profit',
	HistoryPositions_Swap: 'Swap',
	HistoryPositions_Profit: 'Profit/Loss',
	HistoryPositions_Commission: 'Commissions',
	HistoryPositions_Action_0: 'Sell',
	HistoryPositions_Action_1: 'Buy',
	HistoryPositions_Action_2: 'Limit Sell',
	HistoryPositions_Action_3: 'Limit Buy',
	HistoryPositions_Action_4: 'Stop Sell',
	HistoryPositions_Action_5: 'Stop Buy',
	HistoryPositions_Action_6: 'Stop Limit Sell',
	HistoryPositions_Action_7: 'Stop Limit Buy',
	HistoryPositions_Action_8: 'Close by Order',
	// <== History Positions
	// History Balance ==>
	HistoryBalance_Time: 'Date',
	HistoryBalance_Ticket: 'Deal',
	HistoryBalance_Profit: 'Price',
	HistoryBalance_Type: 'Type',
	HistoryBalance_Comment: 'Comment',
	HistoryBalance_Action_2: 'Balance',
	HistoryBalance_Action_3: 'Credit',
	HistoryBalance_Action_4: 'Additional charges/withdrawals',
	HistoryBalance_Action_5: 'Correcting',
	HistoryBalance_Action_6: 'Bonuses',
	HistoryBalance_Action_7: 'Commission',
	// <== History Balance
	// Personal Information ==>
	// <== Personal Information
	// Change Password ==>
	ChangePassword_CurrentPassword: 'Current Password',
	ChangePassword_NewPassword: 'New Password',
	ChangePassword_ConfirmNewPassword: 'Confirm New Password',
	ChangePassword_Button: 'Save',
	// <== Change Password
	// References add ==>
	ReferencesAdd_Button: 'Add Reference',
	ReferencesAdd_Error: 'Adding Reference Error\nPlease try again later.',
	// <== References add
	// FlowMessage ==>
	FlowMessage_Timeout: 'No response received after the transaction.',
	FlowMessage_Success: 'Transaction completed successfully.',
	FlowMessage_Error: 'An error occurred during the transaction. Please try again later.',
	FlowMessage_InvalidPrice: 'Please enter a valid price.',
	FlowMessage_InfoCardCopied: 'Value Copied',
	FlowMessage_UnderConstruction: 'The page you want to switch to is under construction.',
	FlowMessage_InvalidLogin: 'Incorrect username or password.',
	FlowMessage_LoginSuccess: 'Logged in. Welcome!',
	FlowMessage_LoginError: 'An error occurred while logging in! Please try again later.',
	FlowMessage_UnfilledFieldWarning: 'Please fill in all fields.',
	FlowMessage_ModifySuccess: 'Your modification was successful.',
	FlowMessage_PositionClose: 'Position closed successfully.',
	FlowMessage_OrderDelete: 'Order deleted successfully.',
	FlowMessage_PositionCloseRejected: 'Failed to close position.',
	FlowMessage_OrderDeleteRejected: 'Failed to delete order.',
	FlowMessage_Logout_N: 'Logged out successfully.',
	FlowMessage_Logout_R: 'Logged out. Logging in again...',
	FlowMessage_Logout_0: 'You have been logged out due to maintenance on the server.',
	FlowMessage_Logout_1: 'You have been logged out because your connection was lost. Logging in again...',
	FlowMessage_ReLogin_DataSourceIsNotReady: 'Your session has ended. Please log in again.',
	FlowMessage_ReLogin_GroupChanged: 'You have been logged out because your connection was lost. Logging in again...',
	FlowMessage_ReLogin_LoginFromAnotherDevice: 'Logged in from another device. You have been logged out.',
	FlowMessage_ChangeInformationSuccess: 'Your information has been updated. \nYou are being redirected to the login screen.',
	FlowMessage_ChangeInformationError: 'An issue occurred. Your password could not be changed. Please try again later.',
	FlowMessage_CustomerComplaintSuccess: 'Your complaint has been submitted.',
	FlowMessage_InvestorError: 'You cannot trade on an investor account.',
	FlowMessage_Trade_Buy_MarketLimit: 'Market Limit value cannot be less than or equal to the buy price.',
	FlowMessage_Trade_Buy_StopLoss: 'Stop Loss value cannot be greater than or equal to the buy price.',
	FlowMessage_Trade_Buy_TakeProfit: 'Take Profit value cannot be less than or equal to the buy price.',
	FlowMessage_Trade_Sell_MarketLimit: 'Market Limit value cannot be less than or equal to the sell price.',
	FlowMessage_Trade_Sell_StopLoss: 'Stop Loss value cannot be less than or equal to the sell price.',
	FlowMessage_Trade_Sell_TakeProfit: 'Take Profit value cannot be greater than or equal to the sell price.',
	FlowMessage_Deposit: 'After making the deposit notification, inform your investment expert.',
	FlowMessage_Withdraw: 'After making the withdrawal notification, inform your investment expert.',
	FlowMessage_PhoneError: 'Invalid phone number!\nYour phone number should consist of digits only.',
	FlowMessage_EmailError: 'Invalid email address!\nPlease enter a valid email address.',
	FlowMessage_ReferenceAdded: 'Reference successfully added.',
	FlowMessage_ReferenceAllReadyCollected: 'You have already collected your bonus!',
	FlowMessage_ReferenceUnderReview: 'Your bonus is still under review!',
	FlowMessage_ReferenceNotEnough: 'You do not have enough references for the bonus!',
	FlowMessage_InvalidComplaint: 'Please enter a valid complaint.',
	// <== FlowMessage
	// Retcodes ==>
	Retcode_0: '',
	Retcode_10001: 'Request is on the way.',
	Retcode_10002: 'Request accepted.',
	Retcode_10003: 'Request is being processed.',
	Retcode_10004: 'Offer received in response to the request.',
	Retcode_10005: 'Prices provided in response to the request.',
	Retcode_10006: 'Request rejected.',
	Retcode_10007: 'Request canceled.',
	Retcode_10008: 'An order placed as a result of the request.',
	Retcode_10009: 'Request completed.',
	Retcode_10010: 'Request partially fulfilled.',
	Retcode_10011: 'General request error.',
	Retcode_10012: 'Request timed out.',
	Retcode_10013: 'Invalid request.',
	Retcode_10014: 'Invalid volume.',
	Retcode_10015: 'Invalid price.',
	Retcode_10016: 'Incorrect stop levels or price.',
	Retcode_10017: 'Trading is disabled.',
	Retcode_10018: 'Market is closed.',
	Retcode_10019: 'Insufficient funds.',
	Retcode_10020: 'Price has changed.',
	Retcode_10021: 'Price is not available.',
	Retcode_10022: 'Invalid order duration.',
	Retcode_10023: 'Order modified.',
	Retcode_10024: 'Too many trade requests. For example, this error may occur when trying to send more than 128 trade requests from an Administrator API instance.',
	Retcode_10025: 'Request does not contain changes.',
	Retcode_10026: 'Auto trading is disabled on the server.',
	Retcode_10027: 'Auto trading is disabled on the client side.',
	Retcode_10028: 'Request blocked by the dealer.',
	Retcode_10029: 'Modification failed due to the order or position being too close to the market.',
	Retcode_10030: 'Filling mode is not supported.',
	Retcode_10031: 'No connection.',
	Retcode_10032: 'Only allowed for real accounts.',
	Retcode_10033: 'Order count limit reached.',
	Retcode_10034: 'Volume limit reached.',
	Retcode_10035: 'Invalid or prohibited order type.',
	Retcode_10036: 'Position already closed. For example, this error occurs when trying to change the stop levels of a position.',
	Retcode_10037: 'Used for internal purposes.',
	Retcode_10038: 'The closing volume exceeds the current volume of the open position.',
	Retcode_10039: 'There is already an order to close the position. The error occurs in hedge mode: when trying to close a position with an opposite position or trying to close all or part of an existing position, the error is generated if the total volume of existing closing orders exceeds the current volume of the position and the newly placed order.',
	Retcode_10040:
		'The number of positions that can be simultaneously open in an account can be limited by group settings. When a limit is reached, the server returns Retcode_REQUEST_LIMIT_POSITIONS when trying to place an order. The limitation works differently depending on the position calculation type: Netting - the number of open positions is taken into account. When a limit is reached, the platform disables placing trades that could increase the number of open positions. In fact, the platform only allows placing orders for symbols that already have open positions. Existing pending orders are not considered because although they could lead to changes in existing positions, they cannot increase the position count. Hedging - pending orders are considered along with open positions, as activating a pending order always leads to opening a new position. When a limit is reached, the platform disables both placing new market orders to open new positions and placing pending orders.',
	Retcode_10041: 'Request rejected, order canceled. This code is returned when the IMTConRoute::ACTION_CANCEL_ORDER action is applied in a routing rule.',
	Retcode_10042: 'Request rejected because the "Only long positions allowed" rule is set for the symbol (IMTConSymbol::TRADE_LONGONLY).',
	Retcode_10043: 'Request rejected because the "Only short positions allowed" rule is set for the symbol (IMTConSymbol::TRADE_SHORTONLY).',
	Retcode_10044: 'Request rejected because the "Only closing positions allowed" rule is set for the symbol (IMTConSymbol::TRADE_CLOSEONLY).',
	Retcode_10045: 'Position closing does not comply with FIFO rule. This is used for groups where the IMTConGroup::TRADEFLAGS_FIFO_CLOSE option is enabled. With this option, all positions must be closed sequentially: the oldest position is closed first, then the next one, and so on.',
	Retcode_10046: 'It is not possible to open a position or place a pending order because hedge positions are prohibited. The error is returned when the IMTConGroup::TRADEFLAGS_HEDGE_PROHIBIT flag is enabled for the group, and the user attempts to perform a trade when there is already an opposite order or position for the same symbol.',
	// <== Retcodes
};

import React, {useEffect} from 'react';
// import {Turkish, English} from './languages';
import { Turkish } from './Languages/Turkish';
import { English } from './Languages/English';
import { SimpliedChinase } from './Languages/SimpliedChinase';
import { Espanol } from './Languages/Espanol';
import { Japanase } from './Languages/Japanase';
import { Arabic } from './Languages/Arabic';


export const LanguageContext = React.createContext({
  language: 'tr',
  words: Turkish,
  setLanguage: (language: string) => {},
});

export const LanguageProvider = ({children}: any) => {
  const [getLang, setLang] = React.useState('tr');

  // const getDeviceLanguage = (): Promise<string> => {
  //   const deviceLang =
  //     (Platform.OS) === 'ios'
  //       ? NativeModules.SettingsManager.settings.AppleLocale || NativeModules.SettingsManager.settings.AppleLanguages[0] // iOS 13
  //       : NativeModules.I18nManager.localeIdentifier;

  //   return deviceLang.split('_')[0];
  // };

  const getLanguageByCode = (code: string) => {
    console.log('getLanguageByCode:::: ', code)
    switch (code) {
      case 'tr':
        return Turkish;
      case 'en':
        return English;
      case 'ar':
        return Arabic;
      case 'es':
        return Espanol;
      case 'ja':
        return Japanase;
      case 'zh':
        return SimpliedChinase;
      default:
        return Turkish;
    }
  };

  const defaultLanguage = {
    language: getLang,
    words: getLanguageByCode(getLang),
    setLanguage: (language: string) => {
      setLang(language);
    },
  };

  return <LanguageContext.Provider value={defaultLanguage}>{children}</LanguageContext.Provider>;
};

export const useLanguage = () => React.useContext(LanguageContext);
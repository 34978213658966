export const SimpliedChinase = {
	Exit: '退出',
	Login: '登录',
	LoginID: 'ID',
	UserName: '用户名',
	Password: '密码',
	Buy: '买入',
	Sell: '卖出',
	Profit: '盈利',
	Loss: '亏损',
	Home: '首页',
	Markets: '市场',
	Transaction: '我的交易',
	Profile: '个人资料',
	Spread: '点差',
	Search: '搜索',
	Name: '名称',
	FirstName: '名字',
	LastName: '姓氏',
	Email: '电子邮件',
	Phone: '电话',
	Amount: '金额',
	Button_SubmitDepositRequest: '提交存款请求',
	Button_SubmitWithdrawRequest: '提交提款请求',
	Button_SubmitYourComplaint: '提交您的投诉',
	Position: '持仓',
	Order: '订单',
	Login_RegisteredAccounts: '已注册账户',
	Login_Servers: '服务器',
	Login_NoRegisteredAccounts: '找不到已注册账户。',
	Login_ToolTip_1: '登录屏幕已刷新。',
	Login_ToolTip_2: '输入用户名和密码后，单击“登录”按钮即可登录并保存登录信息。',
	Login_ToolTip_3: '如果按住“登录”按钮，您将登录但不保存登录信息。',
	Login_ToolTip_4: '当您拥有已注册用户时，您可以使用任何想要的用户登录。',
	Login_ToolTip_5: '如果按住已注册用户，则将从保存的用户中删除按下的帐户。',
	Login_ToolTip_6: '您需要从这里选择一个服务器。',
	Login_ToolTip_7: '登录时特别注意所选的服务器。',
	SideMenu_Deposit: '存款',
	SideMenu_Withdraw: '取款',
	SideMenu_Notifications: '通知',
	SideMenu_BankStatement: '银行对账单',
	SideMenu_Transaction: '交易历史',
	SideMenu_ChangePassword: '修改密码',
	SideMenu_Traders: '交易员',
	SideMenu_Robots: '交易机器人',
	SideMenu_LiveSupport: '在线支持',
	SideMenu_CustomerComplaint: '客户投诉',
	SideMenu_Settings: '设置',
	SideMenu_Share: '分享给朋友',
	SideMenu_References: '推荐',
	Favorites: '收藏夹',
	Forex: '外汇',
	Crypto: '加密货币',
	Indices: '指数',
	Metal: '贵金属',
	Energy: '能源',
	Stock: '股票',
	History: '历史',
	Positions: '持仓',
	Orders: '订单',
	Home_RecommendedActions: '今日推荐',
	Home_Announcement: '您可以收藏您想关注的交易对。',
	Router_Login: '登录',
	Router_TransactionDetails: '我的交易',
	Router_Trade: '交易',
	Router_TradingView: '详细图表',
	Router_DepositSelection: '存款',
	Router_DepositWithCash: '银行转账存款',
	Router_Withdraw: '银行转账取款',
	Router_CurrencySelection: '银行转账存款',
	Router_BankTransaction: '银行转账存款',
	Router_DepositWithCrypto: '加密货币存款',
	Router_ChangePassword: '修改密码',
	Router_CustomerComplaint: '客户投诉',
	Router_References: '推荐',
	Router_AddReferences: '添加推荐',
	Router_Settings: '设置',
	Router_Home: '首页',
	Router_Symbols: '市场',
	Router_Transaction: '我的交易',
	Balance: '余额',
	Equity: '净值',
	Credit: '信用',
	Margin: '已用保证金',
	Margin_Free: '可用保证金',
	Margin_Level: '保证金比例',
	Share: '分享给朋友',
	Floating: '浮动盈亏',
	UserCard_PhoneError: '电话号码未注册！',
	DepositSelection_WithCash: '银行转账存款',
	DepositSelection_WithCrypto: '加密货币存款',
	DepositSelection_Warning: '请选择适当的存款方式。',
	DepositWithCash_Warning: '要获取适当的银行转账信息，请选择要充值的银行，然后选择货币。',
	CurrencySelection_Warning: '要获取适当的银行转账信息，请选择要充值的银行，然后选择货币。',
	DepositTransaction_BankName: '银行名称',
	DepositTransaction_Name: '收件人姓名',
	DepositTransaction_Currency: '货币',
	DepositTransaction_IBAN: 'IBAN',
	DepositTransaction_Description: '描述',
	DepositWithCrypto_Warning_1: '要获取适当的加密货币信息，请选择要充值的加密货币，然后选择网络。',
	DepositWithCrypto_Warning_2: '选择网络单位后，您可以从信息卡中复制它。',
	DepositWithCrypto_SelectCrypto: '选择加密货币',
	DepositWithCrypto_SelectNetwork: '选择网络单位',
	DepositWithCrypto_SelectNetwork_Warning: '首先选择加密货币',
	DepositWithCrypto_Message: '消息',
	CustomerComplaint_Message: '您的消息',
	CustomerComplaint_Warning: '您可以在下面的字段中写下您的问题。它将通过 WhatsApp 传送。',
	Settings_Language: '语言',
	Settings_SearchLanguage: '搜索语言...',
	Settings_DarkMode: '深色模式',
	Settings_Notifications: '通知',
	Trade_AdditionalTransactions: '附加交易',
	Trade_MarketOrder: '市价订单',
	Trade_TakeProfit: '获利水平',
	Trade_StopLoss: '止损水平',
	Trade_Create: '创建',
	Trade_BuyOrder: '创建买入订单',
	Trade_SellOrder: '创建卖出订单',
	Trade_BuyLimit: '创建限价买入订单',
	Trade_SellLimit: '创建限价卖出订单',
	Trade_BuyStop: '创建止损买入订单',
	Trade_SellStop: '创建止损卖出订单',
	Trade_Type_0: '买入',
	Trade_Type_1: '卖出',
	Trade_Type_2: '限价买入',
	Trade_Type_3: '限价卖出',
	Trade_Type_4: '止损买入',
	Trade_Type_5: '止损卖出',
	Trade_Type_6: '买入止损限价',
	Trade_Type_7: '卖出止损限价',
	Trade_Type_8: '平仓订单',
	Positions_TimeCreate: '日期',
	Positions_Position: '持仓',
	Positions_VolumeCurrent: '手数',
	Positions_PriceCurrent: '当前价格',
	Positions_PriceSL: '止损价格',
	Positions_PriceTP: '获利价格',
	Positions_Swap: '过夜利息',
	Positions_Profit: '盈亏',
	Position_PartialClose: '部分平仓',
	Position_ClosePosition: '平仓',
	Position_ModifyPosition: '修改',
	ModifyPosition_Title: '修改持仓',
	ModifyPosition_Position: '持仓编号',
	ModifyPosition_PriceCurrent: '当前价格',
	ModifyPosition_PriceSL: '止损价格',
	ModifyPosition_PriceTP: '获利价格',
	ModifyPosition_Accept: '确认',
	ModifyPosition_Cancel: '取消',
	ModifyOrder_Title: '修改订单',
	ModifyOrder_Order: '订单号',
	ModifyOrder_PriceCurrent: '当前价格',
	ModifyOrder_PriceOrder: '订单价格',
	ModifyOrder_PriceSL: '止损',
	ModifyOrder_PriceTP: '获利',
	ModifyOrder_LastTime: '过期日期',
	ModifyOrder_LastTimeMessage: '为您的订单选择一个过期日期。',
	ModifyOrder_Accept: '确认',
	ModifyOrder_Cancel: '取消',
	ModifyOrder_LastTimeOk: '确定',
	ModifyOrder_LastTimeCancel: '取消',
	Orders_Order: '订单编号',
	Orders_Lot: '手数',
	Orders_PriceOrder: '订单价格',
	Orders_PriceSL: '止损',
	Orders_PriceTP: '获利',
	Orders_PriceCurrent: '当前价格',
	Orders_Reason: '原因',
	Orders_Comment: '备注',
	Orders_DeleteOrder: '删除订单',
	Orders_ModifyOrder: '修改订单',
	Orders_Type_0: '客户',
	Orders_Type_1: '专家',
	Orders_Type_2: '卖家',
	Orders_Type_3: 'SL',
	Orders_Type_4: 'TP',
	Orders_Type_5: 'SO',
	Orders_Type_6: '舍入',
	Orders_Type_7: '外部客户',
	Orders_Type_8: 'V 容忍',
	SymbolProperties_SwapRates: '隔夜利息',
	SymbolProperties_Sessions: '交易时段',
	SymbolProperties_SessionsTrade: '交易',
	SymbolProperties_ContractSize: '合约大小',
	SymbolProperties_Spread: '点差',
	SymbolProperties_StopsLevel: '止损水平',
	SymbolProperties_CurrencyMargin: '货币保证金',
	SymbolProperties_VolumeMin: '最小手数',
	SymbolProperties_VolumeMax: '最大手数',
	SymbolProperties_Monday: '周一',
	SymbolProperties_Tuesday: '周二',
	SymbolProperties_Wednesday: '周三',
	SymbolProperties_Thursday: '周四',
	SymbolProperties_Friday: '周五',
	SymbolProperties_Saturday: '周六',
	SymbolProperties_Sunday: '周日',
	History_Button: '筛选',
	History_Positions: '已平仓交易',
	History_Balance: '余额历史',
	History_MyTransactions: '我的交易',
	History_DatePicker_StartTitle: '选择开始日期',
	History_DatePicker_EndTitle: '选择结束日期',
	History_DatePicker_Ok: '确定',
	History_DatePicker_Cancel: '取消',
	History_NoData: '无历史记录。',
	HistoryPositions_Symbol: '交易对',
	HistoryPositions_Deal: '成交编号',
	HistoryPositions_Volume: '手数',
	HistoryPositions_Price: '开仓价格',
	HistoryPositions_PricePosition: '平仓价格',
	HistoryPositions_PriceSL: '止损',
	HistoryPositions_PriceTP: '获利',
	HistoryPositions_Swap: '隔夜利息',
	HistoryPositions_Profit: '盈亏',
	HistoryPositions_Action_0: '卖出',
	HistoryPositions_Action_1: '买入',
	HistoryPositions_Action_2: '限价卖出',
	HistoryPositions_Action_3: '限价买入',
	HistoryPositions_Action_4: '止损卖出',
	HistoryPositions_Action_5: '止损买入',
	HistoryPositions_Action_6: '停止限价卖出',
	HistoryPositions_Action_7: '停止限价买入',
	HistoryPositions_Action_8: '通过订单平仓',
	HistoryBalance_Time: '日期',
	HistoryBalance_Ticket: '交易',
	HistoryBalance_Profit: '价格',
	HistoryBalance_Type: '类型',
	HistoryBalance_Comment: '评论',
	HistoryBalance_Action_2: '余额',
	HistoryBalance_Action_3: '信用',
	HistoryBalance_Action_4: '附加费用/提款',
	HistoryBalance_Action_5: '校正',
	HistoryBalance_Action_6: '奖金',
	HistoryBalance_Action_7: '佣金',
	ChangePassword_CurrentPassword: '当前密码',
	ChangePassword_NewPassword: '新密码',
	ChangePassword_ConfirmNewPassword: '确认新密码',
	ChangePassword_Button: '保存',
	ReferencesAdd_Button: '添加推荐',
	ReferencesAdd_Error: '添加推荐错误\n请稍后重试。',
	FlowMessage_Timeout: '交易后未收到响应。',
	FlowMessage_Success: '交易成功完成。',
	FlowMessage_Error: '交易过程中出现错误。请稍后重试。',
	FlowMessage_InvalidPrice: '请输入有效价格。',
	FlowMessage_InfoCardCopied: '数值已复制',
	FlowMessage_UnderConstruction: '您要转到的页面正在建设中。',
	FlowMessage_InvalidLogin: '用户名或密码错误。',
	FlowMessage_LoginSuccess: '登录成功。欢迎！',
	FlowMessage_LoginError: '登录时发生错误！请稍后重试。',
	FlowMessage_UnfilledFieldWarning: '请填写所有字段。',
	FlowMessage_ModifySuccess: '您的修改已成功。',
	FlowMessage_PositionClose: '仓位成功关闭。',
	FlowMessage_OrderDelete: '订单成功删除。',
	FlowMessage_PositionCloseRejected: '无法关闭仓位。',
	FlowMessage_OrderDeleteRejected: '无法删除订单。',
	FlowMessage_Logout_N: '成功登出。',
	FlowMessage_Logout_R: '已登出。正在重新登录...',
	FlowMessage_Logout_0: '由于服务器维护，您已被登出。',
	FlowMessage_Logout_1: '由于连接丢失，您已被登出。正在重新登录...',
	FlowMessage_ReLogin_DataSourceIsNotReady: '您的会话已结束。请重新登录。',
	FlowMessage_ReLogin_GroupChanged: '由于连接丢失，您已被登出。正在重新登录...',
	FlowMessage_ReLogin_LoginFromAnotherDevice: '从另一设备登录。您已被登出。',
	FlowMessage_ChangeInformationSuccess: '您的信息已更新。\n正在重定向到登录界面。',
	FlowMessage_ChangeInformationError: '出现问题。密码无法更改。请稍后重试。',
	FlowMessage_CustomerComplaintSuccess: '您的投诉已提交。',
	FlowMessage_InvestorError: '您不能在投资者账户上进行交易。',
	FlowMessage_Trade_Buy_MarketLimit: '市场限价值不能小于或等于买入价格。',
	FlowMessage_Trade_Buy_StopLoss: '止损值不能大于或等于买入价格。',
	FlowMessage_Trade_Buy_TakeProfit: '获利点值不能小于或等于买入价格。',
	FlowMessage_Trade_Sell_MarketLimit: '市场限价值不能小于或等于卖出价格。',
	FlowMessage_Trade_Sell_StopLoss: '止损值不能小于或等于卖出价格。',
	FlowMessage_Trade_Sell_TakeProfit: '获利点值不能大于或等于卖出价格。',
	FlowMessage_Deposit: '完成存款通知后，请通知您的投资专家。',
	FlowMessage_Withdraw: '完成提款通知后，请通知您的投资专家。',
	FlowMessage_PhoneError: '无效的电话号码！\n您的电话号码应仅包含数字。',
	FlowMessage_EmailError: '无效的电子邮件地址！\n请输入有效的电子邮件地址。',
	FlowMessage_ReferenceAdded: '推荐成功添加。',
	FlowMessage_ReferenceAllReadyCollected: '您已经领取了您的奖金！',
	FlowMessage_ReferenceUnderReview: '您的奖金仍在审核中！',
	FlowMessage_ReferenceNotEnough: '您没有足够的推荐以获取奖金！',
	lowMessage_InvalidComplaint: '请输入有效的投诉。',
	Retcode_0: '',
	Retcode_10001: '请求已发送。',
	Retcode_10002: '请求已接受。',
	Retcode_10003: '请求正在处理中。',
	Retcode_10004: '以响应请求收到的报价。',
	Retcode_10005: '以响应请求提供的价格。',
	Retcode_10006: '请求被拒绝。',
	Retcode_10007: '请求已取消。',
	Retcode_10008: '作为请求结果下达的订单。',
	Retcode_10009: '请求已完成。',
	Retcode_10010: '请求部分完成。',
	Retcode_10011: '一般请求错误。',
	Retcode_10012: '请求超时。',
	Retcode_10013: '无效的请求。',
	Retcode_10014: '无效的交易量。',
	Retcode_10015: '无效的价格。',
	Retcode_10016: '停止单位或价格不正确。',
	Retcode_10017: '交易被禁用。',
	Retcode_10018: '市场已关闭。',
	Retcode_10019: '资金不足。',
	Retcode_10020: '价格已变动。',
	Retcode_10021: '价格不可用。',
	Retcode_10022: '无效的订单持续时间。',
	Retcode_10023: '订单已修改。',
	Retcode_10024: '交易请求过多。例如，当尝试从管理员 API 实例发送超过 128 个交易请求时可能发生此错误。',
	Retcode_10025: '请求不包含更改。',
	Retcode_10026: '服务器上已禁用自动交易。',
	Retcode_10027: '客户端端已禁用自动交易。',
	Retcode_10028: '请求被交易商阻止。',
	Retcode_10029: '由于订单或持仓与市场过于接近，修改失败。',
	Retcode_10030: '不支持填充模式。',
	Retcode_10031: '无连接。',
	Retcode_10032: '仅允许真实账户。',
	Retcode_10033: '订单计数限制已达到。',
	Retcode_10034: '交易量限制已达到。',
	Retcode_10035: '无效或禁止的订单类型。',
	Retcode_10036: '持仓已经关闭。例如，尝试更改持仓的止损水平时可能发生此错误。',
	Retcode_10037: '用于内部目的。',
	Retcode_10038: '平仓量超过了开仓持仓的当前量。',
	Retcode_10039: '已有平仓订单。在对冲模式下发生此错误：尝试使用相反仓位平仓，或尝试平仓现有仓位的全部或部分时，如果现有平仓订单的总手数超过了仓位的当前手数和新下的订单，则会生成此错误。',
	Retcode_10040:
		'账户中可以同时开放的仓位数可以受到组设置的限制。当达到限制时，服务器在尝试下单时将返回 Retcode_REQUEST_LIMIT_POSITIONS。限制根据仓位计算类型的不同而不同：净头寸 - 考虑到的是开放仓位的数量。当达到限制时，平台禁止下单，因为这可能会增加开放仓位的数量。实际上，平台仅允许为已有开放仓位的交易对下单。现有的挂单不会考虑在内，因为虽然它们可能导致对现有仓位的更改，但不能增加仓位数。对冲 - 考虑到挂单以及开放仓位，因为激活挂单总是导致开设新仓位。当达到限制时，平台禁止下达新的市价单以开设新仓位，同时也禁止下达挂单。',
	Retcode_10041: '请求被拒绝，订单已取消。在应用路由规则中使用 IMTConRoute::ACTION_CANCEL_ORDER 操作时返回此代码。',
	Retcode_10042: '请求被拒绝，因为为该交易对设置了“仅允许多头仓位”的规则（IMTConSymbol::TRADE_LONGONLY）。',
	Retcode_10043: '请求被拒绝，因为为该交易对设置了“仅允许空头仓位”的规则（IMTConSymbol::TRADE_SHORTONLY）。',
	Retcode_10044: '请求被拒绝，因为为该交易对设置了“仅允许平仓仓位”的规则（IMTConSymbol::TRADE_CLOSEONLY）。',
	Retcode_10045: '平仓不符合 FIFO 规则。这适用于启用了 IMTConGroup::TRADEFLAGS_FIFO_CLOSE 选项的组。使用此选项，必须按顺序关闭所有仓位：首先关闭最旧的仓位，然后是下一个，依此类推。',
	Retcode_10046: '不允许开仓或下挂单，因为对冲仓位被禁止。当启用了 IMTConGroup::TRADEFLAGS_HEDGE_PROHIBIT 标志并且用户尝试执行交易时，如果对于相同交易对已经存在相反的订单或仓位，则返回此错误。',
};

interface IRetCodes {
  type: string;
  retcode: number;
  desc: string;
}

const retCodes: IRetCodes[] = [
  {
    type: 'MT_RET_REQUEST_INWAY',
    retcode: 10001,
    desc: 'Talep yolda.',
  },
  {
    type: 'MT_RET_REQUEST_ACCEPTED',
    retcode: 10002,
    desc: 'Talep kabul edildi.',
  },
  {
    type: 'MT_RET_REQUEST_PROCESS',
    retcode: 10003,
    desc: 'Talep işleniyor.',
  },
  {
    type: 'MT_RET_REQUEST_REQUOTE',
    retcode: 10004,
    desc: 'Talebe yanıt olarak teklif yeniden alındı.',
  },
  {
    type: 'MT_RET_REQUEST_PRICES',
    retcode: 10005,
    desc: 'Talebe yanıt olarak fiyatlar verildi.',
  },
  {
    type: 'MT_RET_REQUEST_REJECT',
    retcode: 10006,
    desc: 'Talep reddedildi.',
  },
  {
    type: 'MT_RET_REQUEST_CANCEL',
    retcode: 10007,
    desc: 'Talep iptal edildi.',
  },
  {
    type: 'MT_RET_REQUEST_PLACED',
    retcode: 10008,
    desc: 'Talep sonucunda bir sipariş verildi.',
  },
  {
    type: 'MT_RET_REQUEST_DONE',
    retcode: 10009,
    desc: 'Talep tamamlandı.',
  },
  {
    type: 'MT_RET_REQUEST_DONE_PARTIAL',
    retcode: 1010,
    desc: 'Talep kısmen yerine getirildi.',
  },
  {
    type: 'MT_RET_REQUEST_ERROR',
    retcode: 10011,
    desc: 'Talep genel hatası.',
  },
  {
    type: 'MT_RET_REQUEST_TIMEOUT',
    retcode: 10012,
    desc: 'Talep zaman aşımına uğradı.',
  },
  {
    type: 'MT_RET_REQUEST_INVALID',
    retcode: 10013,
    desc: 'Geçersiz talep.',
  },
  {
    type: 'MT_RET_REQUEST_INVALID_VOLUME',
    retcode: 10014,
    desc: 'Geçersiz hacim.',
  },
  {
    type: 'MT_RET_REQUEST_INVALID_PRICE',
    retcode: 10015,
    desc: 'Geçersiz fiyat.',
  },
  {
    type: 'MT_RET_REQUEST_INVALID_STOPS',
    retcode: 10016,
    desc: 'Hatalı stop seviyeleri veya fiyatı.',
  },
  {
    type: 'MT_RET_REQUEST_TRADE_DISABLED',
    retcode: 10017,
    desc: 'Ticaret devre dışı.',
  },
  {
    type: 'MT_RET_REQUEST_MARKET_CLOSED',
    retcode: 10018,
    desc: 'Piyasa kapalı.',
  },
  {
    type: 'MT_RET_REQUEST_NO_MONEY',
    retcode: 10019,
    desc: 'Yeterli para yok.',
  },
  {
    type: 'MT_RET_REQUEST_PRICE_CHANGED',
    retcode: 10020,
    desc: 'Fiyat değişti.',
  },
  {
    type: 'MT_RET_REQUEST_PRICE_OFF',
    retcode: 10021,
    desc: 'Fiyat yok.',
  },
  {
    type: 'MT_RET_REQUEST_INVALID_EXP',
    retcode: 10022,
    desc: 'Geçersiz emir süresi.',
  },
  {
    type: 'MT_RET_REQUEST_ORDER_CHANGED',
    retcode: 10023,
    desc: 'Emir değiştirildi.',
  },
  {
    type: 'MT_RET_REQUEST_TOO_MANY',
    retcode: 10024,
    desc: "Çok fazla ticaret talebi. Örneğin, bir Yönetici API örneğinden 128'den fazla ticaret talebi göndermeye çalışıldığında bu hata dönebilir.",
  },
  {
    type: 'MT_RET_REQUEST_NO_CHANGES',
    retcode: 10025,
    desc: 'Talep değişiklik içermiyor.',
  },
  {
    type: 'MT_RET_REQUEST_AT_DISABLED_SERVER',
    retcode: 10026,
    desc: 'Sunucuda otomatik ticaret devre dışı.',
  },
  {
    type: 'MT_RET_REQUEST_AT_DISABLED_CLIENT',
    retcode: 10027,
    desc: 'İstemci tarafında otomatik ticaret devre dışı.',
  },
  {
    type: 'MT_RET_REQUEST_LOCKED',
    retcode: 10028,
    desc: 'Bayi tarafından engellenen talep.',
  },
  {
    type: 'MT_RET_REQUEST_FROZEN',
    retcode: 10029,
    desc: 'Sipariş veya pozisyon piyasa ile yakın olduğu için değiştirme başarısız oldu.',
  },
  {
    type: 'MT_RET_REQUEST_INVALID_FILL',
    retcode: 10030,
    desc: 'Doldurma modu desteklenmiyor.',
  },
  {
    type: 'MT_RET_REQUEST_CONNECTION',
    retcode: 10031,
    desc: 'Bağlantı yok.',
  },
  {
    type: 'MT_RET_REQUEST_ONLY_REAL',
    retcode: 10032,
    desc: 'Yalnızca gerçek hesaplar için izin verilir.',
  },
  {
    type: 'MT_RET_REQUEST_LIMIT_ORDERS',
    retcode: 10033,
    desc: 'Sipariş sayısı sınırına ulaşıldı.',
  },
  {
    type: 'MT_RET_REQUEST_LIMIT_VOLUME',
    retcode: 10034,
    desc: 'Hacim sınırına ulaşıldı.',
  },
  {
    type: 'MT_RET_REQUEST_INVALID_ORDER',
    retcode: 10035,
    desc: 'Geçersiz veya yasaklanmış emir türü.',
  },
  {
    type: 'MT_RET_REQUEST_POSITION_CLOSED',
    retcode: 10036,
    desc: 'Pozisyon zaten kapatıldı. Örneğin, bir pozisyonun stop seviyelerini değiştirmeye çalışırken bu hata ortaya çıkar.',
  },
  {
    type: 'MT_RET_REQUEST_EXECUTION_SKIPPED',
    retcode: 10037,
    desc: 'Dahili amaçlar için kullanılır.',
  },
  {
    type: 'MT_RET_REQUEST_INVALID_CLOSE_VOLUME',
    retcode: 10038,
    desc: 'Kapatılacak hacim mevcut pozisyonun mevcut hacmini aşıyor.',
  },
  {
    type: 'MT_RET_REQUEST_CLOSE_ORDER_EXIST',
    retcode: 10039,
    desc: 'Pozisyonu kapatmak için zaten bir emir mevcut. Hata, hedge modunda oluşur: Zaten bir pozisyonu kapatmak için karşıt bir pozisyon ile kapatmaya çalışırken veya mevcut bir pozisyonu kapatmanın tamamını veya bir kısmını kapatmaya çalışırken, pozisyonun mevcut hacmini aşan mevcut kapatma emirlerinin toplam hacmi ve yeni yerleştirilen emir.',
  },
  {
    type: 'MT_RET_REQUEST_LIMIT_POSITIONS',
    retcode: 10040,
    desc: 'Bir hesapta eş zamanlı olarak açık bulunabilecek pozisyon sayısı, bir grup ayarlarıyla sınırlanabilir. Bir sınırına ulaşıldığında, bir sipariş yerleştirilmeye çalışıldığında sunucu MT_TRADE_RETCODE_REQUEST_LIMIT_POSITIONS hatasını döner. Sınırlama, pozisyon hesaplama türüne bağlı olarak farklı şekilde işler: Netting - açık pozisyon sayısı dikkate alınır. Bir sınırına ulaşıldığında, platform, açık pozisyon sayısını artırabilecek işlemlerin yerleştirilmesini devre dışı bırakır. Aslında, platform yalnızca zaten açık pozisyonları olan semboller için sipariş yerleştirmeye izin verir. Mevcut bekleyen emirler, mevcut pozisyonlarda değişikliklere yol açabileceği halde, pozisyon sayısını artıramayacağından dikkate alınmaz. Hedging - bekleyen emirler, açık pozisyonlarla birlikte dikkate alınır, çünkü bekleyen emirin etkinleştirilmesi her zaman yeni bir pozisyon açılmasına yol açar. Bir sınırına ulaşıldığında, platform hem yeni pozisyonlar açmak için yeni piyasa emirlerini hem de bekleyen emirleri yerleştirmeyi devre dışı bırakır.',
  },
  {
    type: 'MT_RET_REQUEST_REJECT_CANCEL',
    retcode: 10041,
    desc: 'Talep reddedildi, emir iptal edildi. Bu kod, biryönlendirme kuralındaki IMTConRoute::ACTION_CANCEL_ORDER eylemi uygulandığında döndürülür.',
  },
  {
    type: 'MT_RET_REQUEST_LONG_ONLY',
    retcode: 10042,
    desc: 'İstek reddedildi, çünkü "Sadece uzun pozisyonlar izinli" kuralı sembol için ayarlandı (IMTConSymbol::TRADE_LONGONLY).',
  },
  {
    type: 'MT_RET_REQUEST_SHORT_ONLY',
    retcode: 10043,
    desc: 'İstek reddedildi, çünkü "Sadece kısa pozisyonlar izinli" kuralı sembol için ayarlandı (IMTConSymbol::TRADE_SHORTONLY).',
  },
  {
    type: 'MT_RET_REQUEST_CLOSE_ONLY',
    retcode: 10044,
    desc: 'İstek reddedildi, çünkü "Yalnızca pozisyon kapatma izinli" kuralı sembol için ayarlandı (IMTConSymbol::TRADE_CLOSEONLY).',
  },
  {
    type: 'MT_RET_REQUEST_PROHIBITED_BY_FIFO',
    retcode: 10045,
    desc: 'Pozisyon kapatma FIFO kuralına uygun değil. Bu, IMTConGroup::TRADEFLAGS_FIFO_CLOSE seçeneği etkinleştirilmiş gruplar için kullanılır. Bu seçenekle, tüm pozisyonlar sırasıyla kapatılmalıdır: en eski pozisyon önce kapatılır, ardından bir sonraki, vb.',
  },
  {
    type: 'MT_RET_REQUEST_HEDGE_PROHIBITED',
    retcode: 10046,
    desc: 'Bir pozisyon açmak veya bekleyen bir emir yerleştirmek mümkün değil çünkü hedge pozisyonları yasaklandı. Hata IMTConGroup::TRADEFLAGS_HEDGE_PROHIBIT bayrağı grubu için etkinleştirildiğinde ve kullanıcının aynı sembol için zaten ters bir siparişi veya pozisyonu olduğunda bir kullanıcı bir işlem gerçekleştirmeye çalıştığında geri döner.',
  },
];

export default function getTradeReturnCodeDesc(retcode: string | number) {
  const code = retCodes.find((code) => code.retcode == retcode);
  return code ? code.desc : 'Bilinmeyen hata';
}

import { useCallback, useContext, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { formatNumber } from "../../utils/numberUtil";

// import Markets from '../../assets/icons/home.svg';
import { useFocusEffect } from "@react-navigation/native";
import socket from "../../config/socket";
import { AuthContext } from "../../context/AuthContext";
import { useTheme } from "../../context/Theme/ThemeProvider";
import WalletCard from "../../components/WalletCard";
import { useLanguage } from "../../context/Language/LanguageProvider";

interface IAccountInfo {
  Balance: number;
  Credit: number;
  Equity: number;
  Margin: number;
  MarginFree: number;
  MarginLevel: number;
  Profit: number;
  Floating: number;
}

interface IInfoCard {
  portfolio?: boolean;
}

export default function InfoCard({ portfolio = false }: IInfoCard) {
  const { user } = useContext(AuthContext);
  const [accountInfo, setAccountInfo] = useState<IAccountInfo>(
    {} as IAccountInfo
  );
  const { colors } = useTheme();
  const { words } = useLanguage();

  useFocusEffect(
    useCallback(() => {
      socket.on("account-info", (data: IAccountInfo) => {
        setAccountInfo(data);
      });
    }, [])
  );

  if (!user) {
    return <View></View>;
  }

  if (portfolio) {
    return (
      <View style={styles.cardsContainer}>
        <InfoCardItem
          label={words["Balance"]}
          value={accountInfo.Balance || 0}
          subfix="$"
        />
        <InfoCardItem
          label={words["Credit"]}
          value={accountInfo.Credit}
          subfix="$"
        />
        <InfoCardItem
          label={words["Equity"]}
          value={accountInfo.Equity}
          subfix="$"
        />
        <InfoCardItem
          label={words["Margin"]}
          value={accountInfo.Margin}
          subfix="$"
        />
        <InfoCardItem
          label={words["Margin_Free"]}
          value={accountInfo.MarginFree}
          subfix="$"
        />
        <InfoCardItem
          label={words["Margin_Level"]}
          value={accountInfo.MarginLevel}
          subfix="%"
        />
        <InfoCardItem
          label={words["Floating"]}
          value={accountInfo.Floating}
          subfix="$"
        />
      </View>
    );
  } else {
    return (
      <View style={styles.cardsContainerTransaction}>
        <WalletCard
          
          title={"Bakiye"}
          price={formatNumber(accountInfo.Balance || 0)}
        />
        <WalletCard
          title={"Varlık"}
          price={formatNumber(accountInfo.Equity || 0)}
        />
        <WalletCard
          title={"Kredi"}
          price={formatNumber(accountInfo.Credit || 0)}
        />
        <WalletCard
          title={"Kullanılan Teminat"}
          price={formatNumber(accountInfo.Margin || 0)}
        />
        <WalletCard
          title={"Serbest Teminat"}
          price={formatNumber(accountInfo.MarginFree || 0)}
        />
        <WalletCard
          title={"Bakiye"}
          price={formatNumber(accountInfo.MarginLevel || 0)}
          isMarginLevel={true}
        />
      </View>
    );
  }
}

const InfoCardItem = ({ label = "", value = 0, subfix = "" }) => {
  const { colors } = useTheme();

  return (
    <View
      style={{ flexDirection: "row", justifyContent: "center" }}
      key={label + value}
    >
      <Text
        style={{
          flexBasis: "48%",
          textAlign: "left",
          fontWeight: "600",
          color: colors.TEXT,
        }}
      >
        {label}
      </Text>
      <Text
        style={{
          flexBasis: "48%",
          textAlign: "right",
          color: value < 0 ? colors.RED : colors.GREEN,
          fontWeight: "600",
        }}
      >
        {formatNumber(value)} {subfix}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  cardsContainer: {
    flexDirection: "column",
    marginVertical: 5,
  },
  cardsContainerTransaction: {
    marginVertical: 3,
    flexDirection: "row",
    gap: 7,
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
});

import React, { useContext, useEffect, useState } from 'react';
import { Button, StyleSheet, Switch, Text, View } from 'react-native';
import InputCard from '../../components/InputCard';
import InfoCard from '../../components/InfoCard';
import { AuthContext } from '../../context/AuthContext';
import { useTheme } from '../../context/Theme/ThemeProvider';
import MainButton from '../../components/MainButton';
import apiCall from '../../utils/apiCall';
import {SelectList} from 'react-native-dropdown-select-list';
import { ILanguage, Languages } from './constants';
import { FONTSIZE, GAP, IColors, PADDING, RADIUS } from '../../Global';
import { useLanguage } from '../../context/Language/LanguageProvider';
import AsyncStorage from '@react-native-async-storage/async-storage';

const setLanguageStorage = (lang: string) => {
	try {
		AsyncStorage.setItem('language', JSON.stringify(lang));
	} catch (e) {
		// error reading value
	}
};

const Settings = () => {
	const { logout, user, userPass } = useContext(AuthContext);
	const { darkMode, colors, setScheme } = useTheme();
	const { language, setLanguage, words } = useLanguage();

	const style = Styles(colors);

	const [selectedLanguage, setSelectedLanguage] = useState<ILanguage>(Languages.find((item) => item.code === language) || { id: 0, code: 'tr', name: 'Türkçe' });
	const [getNotificition, setGetNotificition] = useState<boolean>(false);


	const changeDarkMode = async () => {
		const dark = !darkMode;
		setScheme(dark);

		try {
			await AsyncStorage.setItem('darkMode', JSON.stringify(dark));
			console.log('DarkModeChanged: ', JSON.stringify(dark));
		} catch (e) {
			console.log('ChangeDarkModeError: \n', e);
		}
	};

	useEffect(() => {
		setLanguageStorage(selectedLanguage.code);
		setLanguage(selectedLanguage.code);
	}, [selectedLanguage]);
	

	return (
		<View style={style.container}>
			{/* <View style={style.subContainer}>
				<Text style={[style.mainText]}>{words['Settings_Language']}</Text>
				<Text style={style.seperator}>:</Text>
				<SelectList
					data={Languages}
					setSelected={(val: any) => setSelectedLanguage(val.code)}
          			save="key"
          			search={false}
          			placeholder="Kripto Seçiniz"
          			boxStyles={{backgroundColor: colors.CARD_BACKGROUND1}}
          			inputStyles={{backgroundColor: colors.CARD_BACKGROUND1, color: colors.TEXT}}
          			dropdownStyles={{backgroundColor: colors.CARD_BACKGROUND1}}
          			dropdownTextStyles={{color: colors.TEXT}}
				/>
			</View> */}

			<View style={style.subContainer}>
				<Text style={[style.mainText]}>{words['Settings_DarkMode']}</Text>
				<Text style={style.seperator}>:</Text>
				<Switch
					style={[style.cardContainer, { backgroundColor: 'transparent', flex: 3.5 }]}
					value={darkMode}
					onChange={() => {
						changeDarkMode();
					}}
				/>
			</View>

			<View style={style.subContainer}>
				<Text style={[style.mainText]}>{words['Settings_Notifications']}</Text>
				<Text style={style.seperator}>:</Text>
				<Switch
					disabled={true}
					style={[style.cardContainer, { backgroundColor: 'transparent', flex: 3.5 }]}
					value={getNotificition}
					onChange={() => {
						setGetNotificition(!getNotificition);
					}}
				/>
			</View>
			{/* <InfoCard
          type="basic"
          args={{title: 'Müşteri No', value: user?.Login}}
        />
        <InputCard
          type="password"
          args={{title: 'Güncel Şifre', value: password}}
          response={setPassword}
        /> */}
		</View>
	);
};

export default Settings;

const Styles = (colors: IColors) =>
	StyleSheet.create({
		container: {
			flex: 1,
			flexDirection: 'column',
			justifyContent: 'flex-start',
			alignItems: 'stretch',
			padding: PADDING.SCREEN,
			gap: GAP.XXL,
			backgroundColor: colors.SCREEN_BACKGROUND,
		},
		subContainer: {
			justifyContent: 'space-between',
			flexDirection: 'row',
		},
		cardContainer: {
			backgroundColor: colors.CARD_BACKGROUND1,
			paddingVertical: PADDING.XS,
			paddingHorizontal: PADDING.M,
			borderRadius: RADIUS.CARD,
		},
		cardSubContainer: {
			backgroundColor: colors.CARD_BACKGROUND1,
			paddingHorizontal: PADDING.M,
			borderBottomLeftRadius: RADIUS.CARD,
			borderBottomRightRadius: RADIUS.CARD,
		},
		cardText: {
			color: colors.TEXT,
			fontWeight: 'bold',
			fontSize: FONTSIZE.l,
		},
		cardSubText: {
			color: colors.TEXT,
			fontSize: FONTSIZE.l,
		},
		mainText: {
			color: colors.TEXT,
			fontSize: FONTSIZE.xl,
			alignSelf: 'center',
			flex: 2.3,
		},
		seperator: {
			color: colors.TEXT,
			fontSize: FONTSIZE.xl,
			alignSelf: 'center',
			flex: 0.2,
		},
	});

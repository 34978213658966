export const Espanol = {
  Exit: "Salir",
  Login: "Iniciar sesión",
  LoginID: "ID",
  UserName: "Nombre de usuario",
  Password: "Contraseña",
  Buy: "Comprar",
  Sell: "Vender",
  Profit: "Beneficio",
  Loss: "Pérdida",
  Home: "Inicio",
  Markets: "Mercados",
  Transaction: "Mis Transacciones",
  Profile: "Perfil",
  Spread: "Spread",
  Search: "Buscar",
  Name: "Nombre",
  FirstName: "Nombre",
  LastName: "Apellido",
  Email: "Correo electrónico",
  Phone: "Teléfono",
  Amount: "Cantidad",
  Button_SubmitDepositRequest: "Enviar Solicitud de Depósito",
  Button_SubmitWithdrawRequest: 'Enviar Solicitud de Retiro',
  Button_SubmitYourComplaint: "Enviar tu Queja",
  Position: "Posición",
  Order: "Orden",
  Login_RegisteredAccounts: "Cuentas Registradas",
  Login_Servers: "Servidores",
  Login_NoRegisteredAccounts: "No se encontraron cuentas registradas.",
  Login_ToolTip_1: "La pantalla de inicio de sesión se ha actualizado.",
  Login_ToolTip_2: 'Después de ingresar el nombre de usuario y la contraseña, hacer clic en el botón "Iniciar sesión" te conecta y guarda tu información de inicio de sesión.',
  Login_ToolTip_3: 'Si mantienes presionado el botón "Iniciar sesión", inicias sesión sin guardar tu información de inicio de sesión.',
  Login_ToolTip_4: "Cuando tienes usuarios registrados, puedes iniciar sesión con el usuario que desees.",
  Login_ToolTip_5: "Si mantienes presionado un usuario registrado, eliminas la cuenta presionada de las guardadas.",
  Login_ToolTip_6: "Debes seleccionar un servidor desde aquí.",
  Login_ToolTip_7: "Presta especial atención al servidor seleccionado al iniciar sesión.",
  SideMenu_Deposit: "Depósito",
  SideMenu_Withdraw: "Retiro",
  SideMenu_Notifications: "Notificaciones",
  SideMenu_BankStatement: "Estado de Cuenta Bancario",
  SideMenu_Transaction: 'Historial de transacciones',
  SideMenu_ChangePassword: "Cambiar Contraseña",
  SideMenu_Traders: "Traders",
  SideMenu_Robots: "Robots",
  SideMenu_LiveSupport: "Soporte en Vivo",
  SideMenu_CustomerComplaint: "Quejas del Cliente",
  SideMenu_Settings: "Configuración",
  SideMenu_Share: "Compartir con Amigos",
  SideMenu_References: "Referencias",
  Favorites: "Favoritos",
  Forex: "Forex",
  Crypto: "Cripto",
  Indices: "Índices",
  Metal: "Metales",
  Energy: "Energía",
  Stock: "Acciones",
  History: "Historial",
  Positions: "Posiciones Abiertas",
  Orders: "Órdenes Abiertas",
  Home_RecommendedActions: "Acciones Recomendadas del Día",
  Home_Announcement: "Puedes marcar el par que deseas seguir.",
  Router_Login: "Iniciar sesión",
  Router_TransactionDetails: "Detalles de mis Transacciones",
  Router_Trade: "Operar",
  Router_TradingView: "Gráfico Detallado",
  Router_DepositSelection: "Depósito",
  Router_DepositWithCash: "Depósito con Transferencia Bancaria",
  Router_Withdraw: "Retiro con Transferencia Bancaria",
  Router_CurrencySelection: "Depósito con Transferencia Bancaria",
  Router_BankTransaction: "Depósito con Transferencia Bancaria",
  Router_DepositWithCrypto: "Depósito con Cripto",
  Router_ChangePassword: "Cambiar Contraseña",
  Router_CustomerComplaint: "Quejas del Cliente",
  Router_References: "Referencias",
  Router_AddReferences: "Agregar Referencias",
  Router_Settings: "Configuración",
  Router_Home: "Inicio",
  Router_Symbols: "Mercados",
  Router_Transaction: "Mis Transacciones",
  Balance: "Balance",
  Equity: "Patrimonio",
  Credit: "Crédito",
  Margin: "Margen Utilizado",
  Margin_Free: "Margen Libre",
  Margin_Level: "Nivel de Margen",
  Share: "Compartir con Amigos",
  Floating: "Beneficio/Pérdida Flotante",
  UserCard_PhoneError: "¡Número de teléfono no registrado!",
  DepositSelection_WithCash: "Depositar con Transferencia Bancaria",
  DepositSelection_WithCrypto: "Depositar con Cripto",
  DepositSelection_Warning: "Por favor, seleccione el método de depósito adecuado.",
  DepositWithCash_Warning: "Para obtener la información de transferencia bancaria adecuada, seleccione el banco al que desea financiar y luego seleccione la moneda.",
  CurrencySelection_Warning: "Para obtener la información de transferencia bancaria adecuada, seleccione el banco al que desea financiar y luego seleccione la moneda.",
  DepositTransaction_BankName: "Nombre del Banco",
  DepositTransaction_Name: 'Nombre del Destinatario',
  DepositTransaction_Currency: "Moneda",
  DepositTransaction_IBAN: "IBAN",
  DepositTransaction_Description: "Descripción",
  DepositWithCrypto_Warning_1: "Para obtener la información de cripto adecuada, seleccione la cripto que desea financiar y luego seleccione la red.",
  DepositWithCrypto_Warning_2: "Después de seleccionar la unidad de red, puede copiarla desde la tarjeta de información.",
  DepositWithCrypto_SelectCrypto: "Seleccionar Cripto",
  DepositWithCrypto_SelectNetwork: "Seleccionar Unidad de Red",
  DepositWithCrypto_SelectNetwork_Warning: "Seleccione una Cripto Primero",
  DepositWithCrypto_Message: "Mensaje",
  CustomerComplaint_Message: "Tu Mensaje",
  CustomerComplaint_Warning: "Puedes transmitir tu problema escribiéndolo en el campo a continuación. Se transmitirá a través de WhatsApp.",
  Settings_Language: "Idioma",
  Settings_SearchLanguage: "Buscar Idioma...",
  Settings_DarkMode: "Modo Oscuro",
  Settings_Notifications: "Notificaciones",
  Trade_AdditionalTransactions: "Transacciones Adicionales",
  Trade_MarketOrder: "Orden de Mercado",
  Trade_TakeProfit: "Take Profit",
  Trade_StopLoss: "Stop Loss",
  Trade_Create: "Crear",
  Trade_BuyOrder: "Crear Orden de Compra",
  Trade_SellOrder: "Crear Orden de Venta",
  Trade_BuyLimit: "Crear Orden de Compra con Límite",
  Trade_SelllLimit: "Crear Orden de Venta con Límite",
  Trade_BuyStop: "Crear Orden de Compra Stop",
  Trade_SellStop: "Crear Orden de Venta Stop",
  Trade_Type_0: 'Comprar',
  Trade_Type_1: 'Vender',
  Trade_Type_2: 'Compra Límite',
  Trade_Type_3: 'Venta Límite',
  Trade_Type_4: 'Compra con Stop',
  Trade_Type_5: 'Venta con Stop',
  Trade_Type_6: 'Compra con Stop y Límite',
  Trade_Type_7: 'Venta con Stop y Límite',
  Trade_Type_8: 'Cerrar por Orden',
  Positions_TimeCreate: "Fecha",
  Positions_Position: "Posición",
  Positions_VolumeCurrent: "Lote",
  Positions_PriceCurrent: "Precio Actual",
  Positions_PriceSL: "Stop Loss",
  Positions_PriceTP: "Take Profit",
  Positions_Swap: "Swap",
  Positions_Profit: "Beneficio/Pérdida",
  Position_PartialClose: "Cierre Parcial",
  Position_ClosePosition: "Cerrar Posición",
  Position_ModifyPosition: "Modificar",
  ModifyPosition_Title: "Modificar Posición",
  ModifyPosition_Position: "Número de Posición",
  ModifyPosition_PriceCurrent: "Precio Actual",
  ModifyPosition_PriceSL: "Stop Loss",
  ModifyPosition_PriceTP: "Take Profit",
  ModifyPosition_Accept: "Aceptar",
  ModifyPosition_Cancel: "Cancelar",
  ModifyOrder_Title: "Modificar Orden",
  ModifyOrder_Order: "Número de Orden",
  ModifyOrder_PriceCurrent: "Precio Actual",
  ModifyOrder_PriceOrder: "Precio de la Orden",
  ModifyOrder_PriceSL: "Stop Loss",
  ModifyOrder_PriceTP: "Take Profit",
  ModifyOrder_LastTime: "Fecha de Vencimiento",
  ModifyOrder_LastTimeMessage: "Selecciona una fecha de vencimiento para tu orden.",
  ModifyOrder_Accept: "Aceptar",
  ModifyOrder_Cancel: "Cancelar",
  ModifyOrder_LastTimeOk: "OK",
  ModifyOrder_LastTimeCancel: "Cancelar",
  Orders_Order: "Ticket",
  Orders_Lot: "Lote",
  Orders_PriceOrder: "Precio de la Orden",
  Orders_PriceSL: "Stop Loss",
  Orders_PriceTP: "Take Profit",
  Orders_PriceCurrent: "Precio Actual",
  Orders_Reason: "Razón",
  Orders_Comment: "Comentario",
  Orders_DeleteOrder: "Eliminar Orden",
  Orders_ModifyOrder: "Modificar",
  Orders_Type_0: "Cliente",
  Orders_Type_1: "Experto",
  Orders_Type_2: "Vendedor",
  Orders_Type_3: "SL",
  Orders_Type_4: "TP",
  Orders_Type_5: "SO",
  Orders_Type_6: "Redondeo",
  Orders_Type_7: "Cliente Externo",
  Orders_Type_8: "V Tolerancia",
  SymbolProperties_SwapRates: "Tasas de Swap",
  SymbolProperties_Sessions: "Sesiones",
  SymbolProperties_SessionsTrade: "Sesiones de Trading",
  SymbolProperties_ContractSize: "Tamaño del Contrato",
  SymbolProperties_Spread: "Spread",
  SymbolProperties_StopsLevel: "Nivel de Stops",
  SymbolProperties_CurrencyMargin: "Margen de Divisa",
  SymbolProperties_VolumeMin: "Volumen Mínimo",
  SymbolProperties_VolumeMax: "Volumen Máximo",
  SymbolProperties_Monday: "Lunes",
  SymbolProperties_Tuesday: "Martes",
  SymbolProperties_Wednesday: "Miércoles",
  SymbolProperties_Thursday: "Jueves",
  SymbolProperties_Friday: "Viernes",
  SymbolProperties_Saturday: "Sábado",
  SymbolProperties_Sunday: "Domingo",
  History_Button: "Filtrar",
  History_Positions: "Posiciones Cerradas",
  History_Balance: "Historial de Balance",
  History_MyTransactions: "Mis Transacciones",
  History_DatePicker_StartTitle: "Seleccionar Fecha de Inicio",
  History_DatePicker_EndTitle: "Seleccionar Fecha de Fin",
  History_DatePicker_Ok: "Ok",
  History_DatePicker_Cancel: "Cancelar",
  History_NoData: "No hay historial para mostrar.",
  HistoryPositions_Symbol: "Símbolo",
  HistoryPositions_Deal: "Operación",
  HistoryPositions_Volume: "Lote",
  HistoryPositions_Price: "Precio de Apertura",
  HistoryPositions_PricePosition: "Precio de Cierre",
  HistoryPositions_PriceSL: "Stop Loss",
  HistoryPositions_PriceTP: "Take Profit",
  HistoryPositions_Swap: "Swap",
  HistoryPositions_Profit: "Beneficio/Pérdida",
  HistoryPositions_Action_0: "Venta",
  HistoryPositions_Action_1: "Compra",
  HistoryPositions_Action_2: "Venta con Límite",
  HistoryPositions_Action_3: "Compra con Límite",
  HistoryPositions_Action_4: "Venta Stop",
  HistoryPositions_Action_5: "Compra Stop",
  HistoryPositions_Action_6: "Venta Stop Límite",
  HistoryPositions_Action_7: "Compra Stop Límite",
  HistoryPositions_Action_8: "Cerrar por Orden",
  HistoryBalance_Time: "Fecha",
  HistoryBalance_Ticket: "Operación",
  HistoryBalance_Profit: "Precio",
  HistoryBalance_Type: "Tipo",
  HistoryBalance_Comment: "Comentario",
  HistoryBalance_Action_2: "Saldo",
  HistoryBalance_Action_3: "Crédito",
  HistoryBalance_Action_4: "Cargos/Retiros Adicionales",
  HistoryBalance_Action_5: "Corrección",
  HistoryBalance_Action_6: "Bonos",
  HistoryBalance_Action_7: "Comisión",
  ChangePassword_CurrentPassword: "Contraseña Actual",
  ChangePassword_NewPassword: "Contraseña Nueva",
  ChangePassword_ConfirmNewPassword: "Confirmar Nueva Contraseña",
  ChangePassword_Button: "Guardar",
  ReferencesAdd_Button: "Agregar Referencia",
  ReferencesAdd_Error: "Error al agregar la referencia.\nPor favor, inténtalo de nuevo más tarde.",
  FlowMessage_Timeout: "No se recibió respuesta después de la transacción.",
  FlowMessage_Success: "Transacción completada con éxito.",
  FlowMessage_Error: "Se produjo un error durante la transacción. Por favor, inténtalo de nuevo más tarde.",
  FlowMessage_InvalidPrice: "Por favor, introduce un precio válido.",
  FlowMessage_InfoCardCopied: "Valor Copiado",
  FlowMessage_UnderConstruction: "La página a la que deseas acceder está en construcción.",
  FlowMessage_InvalidLogin: "Usuario o contraseña incorrectos.",
  FlowMessage_LoginSuccess: "Sesión iniciada. ¡Bienvenido!",
  FlowMessage_LoginError: "Se produjo un error al iniciar sesión. Por favor, inténtalo de nuevo más tarde.",
  FlowMessage_UnfilledFieldWarning: "Por favor, completa todos los campos.",
  FlowMessage_ModifySuccess: "Tu modificación fue exitosa.",
  FlowMessage_PositionClose: "Posición cerrada exitosamente.",
  FlowMessage_OrderDelete: "Orden eliminada exitosamente.",
  FlowMessage_PositionCloseRejected: "No se pudo cerrar la posición.",
  FlowMessage_OrderDeleteRejected: "No se pudo eliminar la orden.",
  FlowMessage_Logout_N: "Cierre de sesión exitoso.",
  FlowMessage_Logout_R: "Cierre de sesión. Iniciando sesión de nuevo...",
  FlowMessage_Logout_0: "Has cerrado sesión debido a mantenimiento en el servidor.",
  FlowMessage_Logout_1: "Has cerrado sesión porque se perdió la conexión. Iniciando sesión de nuevo...",
  FlowMessage_ReLogin_DataSourceIsNotReady: "Tu sesión ha terminado. Por favor, inicia sesión de nuevo.",
  FlowMessage_ReLogin_GroupChanged: "Has cerrado sesión porque se perdió la conexión. Iniciando sesión de nuevo...",
  FlowMessage_ReLogin_LoginFromAnotherDevice: "Sesión iniciada desde otro dispositivo. Has cerrado sesión.",
  FlowMessage_ChangeInformationSuccess: "Tu información ha sido actualizada. \nTe estamos redirigiendo a la pantalla de inicio de sesión.",
  FlowMessage_ChangeInformationError: "Hubo un problema. Tu contraseña no pudo ser cambiada. Por favor, inténtalo de nuevo más tarde.",
  FlowMessage_CustomerComplaintSuccess: "Tu queja ha sido enviada exitosamente.",
  FlowMessage_InvestorError: "No puedes operar en una cuenta de inversionista.",
  FlowMessage_Trade_Buy_MarketLimit: "El valor límite de mercado no puede ser menor o igual al precio de compra.",
  FlowMessage_Trade_Buy_StopLoss: "El valor de Stop Loss no puede ser mayor o igual al precio de compra.",
  FlowMessage_Trade_Buy_TakeProfit: "El valor de Take Profit no puede ser menor o igual al precio de compra.",
  FlowMessage_Trade_Sell_MarketLimit: "El valor límite de mercado no puede ser menor o igual al precio de venta.",
  FlowMessage_Trade_Sell_StopLoss: "El valor de Stop Loss no puede ser menor o igual al precio de venta.",
  FlowMessage_Trade_Sell_TakeProfit: "El valor de Take Profit no puede ser mayor o igual al precio de venta.",
  FlowMessage_Deposit: "Después de hacer la notificación de depósito, informe a su experto en inversiones.",
  FlowMessage_Withdraw: "Después de hacer la notificación de retiro, informe a su experto en inversiones.",
  FlowMessage_PhoneError: "¡Número de teléfono no válido!\nSu número de teléfono debe consistir solo en dígitos.",
  FlowMessage_EmailError: "¡Dirección de correo electrónico no válida!\nPor favor, introduzca una dirección de correo electrónico válida.",
  FlowMessage_ReferenceAdded: "Referencia agregada con éxito.",
  FlowMessage_ReferenceAllReadyCollected: "¡Ya has recogido tu bono!",
  FlowMessage_ReferenceUnderReview: "¡Tu bono aún está bajo revisión!",
  FlowMessage_ReferenceNotEnough: "No tienes suficientes referencias para el bono.",
  FlowMessage_InvalidComplaint: 'Por favor, introduzca una queja válida.',
  Retcode_0: "",
  Retcode_10001: "La solicitud está en camino.",
  Retcode_10002: "Solicitud aceptada.",
  Retcode_10003: "La solicitud está siendo procesada.",
  Retcode_10004: "Oferta recibida en respuesta a la solicitud.",
  Retcode_10005: "Precios proporcionados en respuesta a la solicitud.",
  Retcode_10006: "Solicitud rechazada.",
  Retcode_10007: "Solicitud cancelada.",
  Retcode_10008: "Una orden colocada como resultado de la solicitud.",
  Retcode_10009: "Solicitud completada.",
  Retcode_10010: "Solicitud parcialmente cumplida.",
  Retcode_10011: "Error general en la solicitud.",
  Retcode_10012: "Tiempo de espera de la solicitud agotado.",
  Retcode_10013: "Solicitud no válida.",
  Retcode_10014: "Volumen no válido.",
  Retcode_10015: "Precio no válido.",
  Retcode_10016: "Niveles de stop o precio incorrectos.",
  Retcode_10017: "El comercio está deshabilitado.",
  Retcode_10018: "El mercado está cerrado.",
  Retcode_10019: "Fondos insuficientes.",
  Retcode_10020: "El precio ha cambiado.",
  Retcode_10021: "El precio no está disponible.",
  Retcode_10022: "Duración de orden no válida.",
  Retcode_10023: "Orden modificada.",
  Retcode_10024: "Demasiadas solicitudes comerciales. Por ejemplo, este error puede ocurrir al intentar enviar más de 128 solicitudes comerciales desde una instancia de API de administrador.",
  Retcode_10025: "La solicitud no contiene cambios.",
  Retcode_10026: "El comercio automático está deshabilitado en el servidor.",
  Retcode_10027: "El comercio automático está deshabilitado en el lado del cliente.",
  Retcode_10028: "Solicitud bloqueada por el distribuidor.",
  Retcode_10029: "La modificación falló debido a que la orden o posición está demasiado cerca del mercado.",
  Retcode_10030: "El modo de llenado no es compatible.",
  Retcode_10031: "Sin conexión.",
  Retcode_10032: "Solo permitido para cuentas reales.",
  Retcode_10033: "Límite de recuento de órdenes alcanzado.",
  Retcode_10034: "Límite de volumen alcanzado.",
  Retcode_10035: "Tipo de orden no válido o prohibido.",
  Retcode_10036: "La posición ya está cerrada. Por ejemplo, este error ocurre al intentar cambiar los niveles de stop de una posición.",
  Retcode_10037: "Usado con fines internos.",
  Retcode_10038: "El volumen de cierre supera el volumen actual de la posición abierta.",
  Retcode_10039: "Ya hay una orden para cerrar la posición. El error ocurre en modo de cobertura: al intentar cerrar una posición con una posición opuesta o al intentar cerrar toda o parte de una posición existente, se genera el error si el volumen total de órdenes de cierre existentes supera el volumen actual de la posición y la orden recién colocada.",
  Retcode_10040: "El número de posiciones que pueden estar abiertas simultáneamente en una cuenta puede estar limitado por la configuración de grupo. Cuando se alcanza un límite, el servidor devuelve Retcode_REQUEST_LIMIT_POSITIONS al intentar colocar una orden. La limitación funciona de manera diferente según el tipo de cálculo de posición: Compensación: se tiene en cuenta el número de posiciones abiertas. Cuando se alcanza un límite, la plataforma deshabilita la colocación de operaciones que podrían aumentar el número de posiciones abiertas. De hecho, la plataforma solo permite colocar órdenes para símbolos que ya tienen posiciones abiertas. Las órdenes pendientes existentes no se consideran porque, aunque podrían llevar a cambios en las posiciones existentes, no pueden aumentar el recuento de posiciones. Cobertura: las órdenes pendientes se consideran junto con las posiciones abiertas, ya que activar una orden pendiente siempre conduce a abrir una nueva posición. Cuando se alcanza un límite, la plataforma deshabilita tanto la colocación de nuevas órdenes de mercado para abrir nuevas posiciones como la colocación de órdenes pendientes.",
  Retcode_10041: "Solicitud rechazada, orden cancelada. Este código se devuelve cuando se aplica la acción IMTConRoute::ACTION_CANCEL_ORDER en una regla de enrutamiento.",
  Retcode_10042: 'Solicitud rechazada porque la regla "Solo se permiten posiciones largas" está configurada para el símbolo (IMTConSymbol::TRADE_LONGONLY).',
  Retcode_10043: 'Solicitud rechazada porque la regla "Solo se permiten posiciones cortas" está configurada para el símbolo (IMTConSymbol::TRADE_SHORTONLY).',
  Retcode_10044: 'Solicitud rechazada porque la regla "Solo se permiten cerrar posiciones" está configurada para el símbolo (IMTConSymbol::TRADE_CLOSEONLY).',
  Retcode_10045: "El cierre de la posición no cumple con la regla FIFO. Esto se usa para grupos donde la opción IMTConGroup::TRADEFLAGS_FIFO_CLOSE está habilitada. Con esta opción, todas las posiciones deben cerrarse secuencialmente: se cierra primero la posición más antigua, luego la siguiente y así sucesivamente.",
  Retcode_10046: "No es posible abrir una posición o colocar una orden pendiente porque las posiciones de cobertura están prohibidas. Se devuelve el error cuando la bandera IMTConGroup::TRADEFLAGS_HEDGE_PROHIBIT está habilitada para el grupo y el usuario intenta realizar una operación cuando ya existe una orden o posición opuesta para el mismo símbolo."
};
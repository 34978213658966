import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Animated, Pressable, StyleSheet, Text, View } from "react-native";
import { showMessage } from "react-native-flash-message";
import OrderPositionEditModal from "../OrderPositionEditModal";
import { OrderFields, PositionFields } from "./constants";
// import dayjs from 'dayjs';
import { getActionColor, getActionType } from "./utils";

import { useFocusEffect } from "@react-navigation/native";
import { FONTSIZE, IAuthContext, IColors } from "../../Global";
import socket from "../../config/socket";
import { AuthContext } from "../../context/AuthContext";
import { useTheme } from "../../context/Theme/ThemeProvider";
import apiCall from "../../utils/apiCall";
import getTradeReturnCodeDesc from "../../utils/error-handle";
import PriceFormat from "../PriceFormat";
import CheckBox from "../Checkbox";
import PriceInput from "../PriceInputV2";
import { AntDesign, Entypo } from "@expo/vector-icons";
import { useLanguage } from "../../context/Language/LanguageProvider";
import { showFlowMessage } from "../FlowMessage";
import { Audio } from "expo-av";
import { marketStatusController } from "../../utils/marketUtils";

async function SoundTrade() {
  console.log("Loading Sound");
  const { sound } = await Audio.Sound.createAsync(
    require("../../assets/sounds/soundstrade.mp3")
  );
  console.log("Playing Sound");
  await sound.playAsync();
}

async function SoundSuccess() {
  console.log("Loading Sound");
  const { sound } = await Audio.Sound.createAsync(
    require("../../assets/sounds/soundssuccess.mp3")
  );
  console.log("Playing Sound");
  await sound.playAsync();
}
async function SoundError() {
  console.log("Loading Sound");
  const { sound } = await Audio.Sound.createAsync(
    require("../../assets/sounds/soundserror.mp3")
  );
  console.log("Playing Sound");
  await sound.playAsync();
}

function roundToDigits(num: number, digits: number) {
  return Math.round(num * Math.pow(10, digits)) / Math.pow(10, digits);
}

const OrderPositionItem: React.FC<any> = ({
  data,
  fieldOption,
  backgroundColor,
}) => {
  const { words } = useLanguage();
  const { group, user } = useContext<IAuthContext>(AuthContext);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [lastTickDate, setLastTickDate] = useState(0);

  const [isDeleting, setIsDeleting] = useState(false);
  const [isEnabledPartialClose, setIsEnabledPartialClose] = useState(false);
  const [partialCloseVolume, setPartialCloseVolume] = useState(0);
  const [step, setStep] = useState(0.01);
  const [digits, setDigits] = useState(2);

  const fields =
    fieldOption === "open-positions" ? PositionFields : OrderFields;

  const fadeAnim = useRef(new Animated.Value(1)).current;

  const currentSymbol = group?.Symbols.find(
    (s) => s.Symbol.Symbol === data.Symbol
  );

  const [priceCurrent, setPriceCurrent] = useState(
    data?.PriceCurrent ?? data?.PriceOrder
  );
  const [profit, setProfit] = useState(data?.Profit ?? 0);

  const [isClosed, setIsClosed] = useState({
    close: false,
    modify: false,
    title: "Market Kapalı",
    id: 0,
  });
  const { colors: COLOR } = useTheme();
  const styles = Styles(COLOR);

  function checkStatus() {
    if (currentSymbol) {
      const marketStatus = marketStatusController(currentSymbol, lastTickDate);
      if (marketStatus.id == 3) {
        setIsClosed({
          close: false,
          modify: true,
          title: marketStatus.title,
          id: marketStatus.id,
        });
        return {
          close: false,
          modify: true,
          title: marketStatus.title,
          id: marketStatus.id,
        };
      } else {
        setIsClosed({
          close: marketStatus.isClosed,
          modify: marketStatus.isClosed,
          title: marketStatus.title,
          id: marketStatus.id,
        });
        return {
          close: marketStatus.isClosed,
          modify: marketStatus.isClosed,
          title: marketStatus.title,
          id: marketStatus.id,
        };
      }
    }
  }

  useFocusEffect(
    useCallback(() => {
      if (data) {
        socket.on(data.Symbol, (sockData: any) => {
          setLastTickDate(sockData.Datetime);
          const priceCurrent =
            (fieldOption === "open-positions" ? data?.Action : data?.Type + 1) %
            2
              ? sockData.Ask
              : sockData.Bid;
          setPriceCurrent(priceCurrent);
          if (fieldOption === "open-positions" && currentSymbol?.Symbol) {
            const exchangeRate =
              data?.Action % 2 ? sockData.Exchange.Ask : sockData.Exchange.Bid;
            const profit =
              ((roundToDigits(
                priceCurrent * currentSymbol.Symbol.ContractSize * data.Volume,
                currentSymbol.Symbol.Digits
              ) -
                roundToDigits(
                  data.PriceOpen *
                    currentSymbol.Symbol.ContractSize *
                    data.Volume,
                  currentSymbol.Symbol.Digits
                )) /
                10000) *
              (((fieldOption === "open-positions" ? data?.Action : data?.Type) %
                2 <<
                1) -
                1) *
              -1 *
              exchangeRate;
            setProfit(profit);
          }
        });
      }
    }, [
      data.Action,
      data.Type,
      data.Symbol,
      data.Volume,
      data.PriceOpen,
      currentSymbol?.Symbol,
      fieldOption,
    ])
  );

  useFocusEffect(
    useCallback(() => {
      if (currentSymbol?.Symbol) {
        setStep(currentSymbol.Symbol.VolumeStep / 10000);
        setDigits(currentSymbol.Symbol.Digits);
        setPartialCloseVolume(
          currentSymbol?.Symbol ? currentSymbol.Symbol.VolumeMin / 10000 : 0.01
        );
      }
    }, [currentSymbol?.Symbol])
  );

  useEffect(() => {
    checkStatus();
  }, [lastTickDate]);

  const handleAccordionOpen = () => {
    if (!isAccordionOpen) {
      fadeAnim.setValue(1);
    }
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 700,
      useNativeDriver: true,
      delay: 100,
    }).start();
    setIsAccordionOpen(!isAccordionOpen);

    checkStatus();
  };

  const closeDeleteHandle = () => {
    if (!currentSymbol) {
      SoundError();
      showFlowMessage({
        title:
          fieldOption === "open-positions"
            ? "Pozisyon Kapatma Başarısız"
            : "Emir Silme Başarısız",
        message: "Symbol bilgisi alınamadı",
        type: "warning",
      });
      return;
    }
    
    if (checkStatus()?.id == 0) {
      SoundError();
      showFlowMessage({
        title:
          fieldOption === "open-positions"
            ? "Pozisyon Kapatma Başarısız"
            : "Emir Silme Başarısız",
        message: words["Retcode_10018"],
        type: "warning",
      });
    }

    setIsDeleting(true);

    let closePosition = {
      positionId: data?.Position,
      volume: isEnabledPartialClose ? partialCloseVolume * 10000 : undefined,
    };

    let deleteOrder = {
      orderId: data?.Order,
    };

    apiCall(
      fieldOption === "open-positions" ? "/close-position" : "/delete-order",
      fieldOption === "open-positions" ? closePosition : deleteOrder,
      "POST"
    )
      .then((res) => {
        if (!res) {
          SoundError();
          setIsDeleting(false);
        } else if (res.Retcode === 0) {
          SoundTrade();
          setIsDeleting(false);
          handleAccordionOpen();
          showFlowMessage({
            message:
              fieldOption === "open-positions"
                ? "Pozisyon Kapatma Başarılı"
                : "Emir Silme Başarılı",
            type: "success",
          });
        } else {
          SoundError();
          setIsDeleting(false);
          showFlowMessage({
            title:
              fieldOption === "open-positions"
                ? "Pozisyon Kapatma Başarısız"
                : "Emir Silme Başarısız",
            message: getTradeReturnCodeDesc(res.data.Retcode),
            type: "warning",
          });
        }
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const renderProfit = () => {
    if (fieldOption != "open-positions") {
      return;
    }
    if (profit > 0) {
      // kar
      return (
        <Text style={{ color: COLOR.PROFIT }}>
          {words["Profit"] + ": "}
          <PriceFormat
            value={profit}
            // digits={currentSymbol?.Symbol.Digits || 2}
            digits={2}
            showCurrency={false}
            textColor={COLOR.PROFIT}
          />
        </Text>
      );
    } else if (profit < 0) {
      // zarar
      return (
        <Text style={{ color: COLOR.LOSS }}>
          {words["Loss"] + ": "}
          <PriceFormat
            value={profit}
            // digits={currentSymbol?.Symbol.Digits || 2}
            digits={2}
            showCurrency={false}
            textColor={COLOR.LOSS}
          />
        </Text>
      );
    } else {
      // 0
      return (
        <Text style={{ color: COLOR.TEXT }}>
          {words["Profit"] + ": "}
          <PriceFormat
            value={profit}
            // digits={currentSymbol?.Symbol.Digits || 2}
            digits={2}
            showCurrency={false}
            textColor={COLOR.TEXT}
          />
        </Text>
      );
    }
  };

  return (
    <View style={{ opacity: isDeleting ? 0.5 : 1 }}>
      <Pressable
        onPress={() => {
          handleAccordionOpen();
        }}
        style={[
          styles.wrapper,
          {
            backgroundColor: backgroundColor,
          },
        ]}
      >
        <View style={styles.topWrapper}>
          <View style={styles.topLeftWrapper}>
            <View style={styles.topLeftWrapper}>
              <View
                style={{ flexDirection: "row", gap: 20, alignItems: "center" }}
              >
                <Text style={styles.symbolName}>{data?.Symbol}</Text>
                <Text
                  style={[
                    styles.symbolAction,
                    {
                      color: getActionColor(
                        fieldOption === "open-positions"
                          ? data?.Action
                          : data?.Type,
                        COLOR
                      ),
                    },
                  ]}
                >
                  {
                    words[
                      `Trade_Type_${
                        fieldOption === "open-positions"
                          ? data?.Action
                          : data?.Type
                      }`
                    ]
                  }{" "}
                  {(fieldOption === "open-positions"
                    ? data?.Volume
                    : data?.VolumeCurrent) / 10000}
                </Text>

                {/* <Text style={[styles.dateText, {display: fieldOption === 'open-positions' ? 'none' : 'flex'}]}>{data?.TimeExpiration == '0' ? ' - ' : dayjs.unix(data?.TimeExpiration).format('DD-MM-YYYY HH:mm')}</Text> */}
              </View>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <PriceFormat
                  style={{
                    color: COLOR.TEXT,
                    fontSize: FONTSIZE.m,
                    fontWeight: "600",
                    textAlignVertical: "center",
                  }}
                  value={
                    fieldOption != "open-positions"
                      ? data?.PriceOrder
                      : data.PriceOpen
                  }
                  digits={digits}
                  showCurrency={false}
                />

                <Entypo
                  name="chevron-right"
                  size={24}
                  color={COLOR.TEXT}
                  style={{ marginLeft: "2%", marginRight: "2%" }}
                />

                <PriceFormat
                  style={{
                    color: COLOR.TEXT,
                    fontSize: FONTSIZE.m,
                    fontWeight: "600",
                    textAlignVertical: "center",
                  }}
                  value={priceCurrent}
                  digits={digits}
                  showCurrency={false}
                />
              </View>
            </View>
          </View>
          <View style={styles.topRightWrapper}>{renderProfit()}</View>
        </View>
        {isAccordionOpen && (
          <Animated.View
            style={{
              opacity: fadeAnim,
            }}
          >
            <View style={styles.contentWrapper}>
              {fields.map((field) => (
                <View key={field.id} style={styles.detailItem}>
                  <Text style={{ color: COLOR.TEXT }}>
                    {fieldOption === "open-positions"
                      ? words[`Positions_${field.name}`]
                      : words[`Orders_${field.name}`]}
                  </Text>
                  {field.field == "Storage" ? (
                    <PriceFormat
                      //value={((fieldOption === 'open-positions' ? data?.Action : data?.Type) % 2 ? currentSymbol?.Symbol.SwapLong : currentSymbol?.Symbol.SwapShort) || 0}
                      value={data?.Storage || 0}
                      digits={currentSymbol?.Symbol.Digits || 2}
                      showCurrency={true}
                    />
                  ) : field.renderCell ? (
                    field.renderCell(data, COLOR)
                  ) : field.field === "PriceCurrent" ? (
                    <PriceFormat
                      value={priceCurrent}
                      digits={currentSymbol?.Symbol.Digits || 2}
                      showCurrency={true}
                    />
                  ) : field.field === "Profit" ? (
                    <PriceFormat
                      value={profit}
                      digits={2}
                      showCurrency={false}
                    />
                  ) : field.field === "PriceOrder" ||
                    field.field === "PriceTP" ||
                    field.field === "PriceSL" ? (
                    <PriceFormat
                      value={data?.[field.field]}
                      digits={currentSymbol?.Symbol.Digits || 2}
                      showCurrency={true}
                    />
                  ) : field.field === "Reason" ? (
                    <Text style={{ color: COLOR.TEXT }}>
                      {words[`Position_Type_${data?.Type}`]}
                    </Text>
                  ) : (
                    <Text style={{ color: COLOR.TEXT }}>
                      {data?.[field.field]}
                    </Text>
                  )}
                </View>
              ))}

              {fieldOption === "open-positions" && !user?.IsInvestor && (
                <View
                  style={{
                    flex: 1,
                    width: "100%",
                    flexShrink: 0,
                    minWidth: "100%",
                  }}
                >
                  <CheckBox
                    label={words["Position_PartialClose"]}
                    onPress={() =>
                      setIsEnabledPartialClose(!isEnabledPartialClose)
                    }
                    checked={isEnabledPartialClose}
                    checkBoxHeight={20}
                    checkBoxWidth={20}
                  />
                  <View style={{ opacity: isEnabledPartialClose ? 1 : 0.3 }}>
                    <PriceInput
                      digits={step.toString().split(".")[1].length || 0.1}
                      step={step}
                      placeHolder=""
                      visible={isEnabledPartialClose}
                      max={data?.Volume / 10000}
                      min={
                        currentSymbol?.Symbol
                          ? currentSymbol.Symbol.VolumeMin / 10000
                          : 0.01
                      }
                      defaultValue={data?.Volume ?? data?.VolumeCurrent}
                      value={partialCloseVolume}
                      setValue={setPartialCloseVolume}
                    />
                  </View>
                </View>
              )}
            </View>
            {!user?.IsInvestor && (
              <View style={styles.actionWrapper}>
                <Pressable
                  onPress={closeDeleteHandle}
                  style={({ pressed }) => [
                    styles.actionBtn,
                    { opacity: pressed ? 0.7 : 1 },
                    {
                      backgroundColor: COLOR.REJECT,
                      opacity: isClosed.id == 0 ? 0.7 : 1,
                    },
                  ]}
                  disabled={isClosed.id == 0}
                >
                  {isClosed.id == 0 ? (
                    <Text
                      style={[
                        styles.actionBtnText,
                        { color: COLOR.REJECT_TEXT },
                      ]}
                    >
                      {isClosed.title}
                    </Text>
                  ) : (
                    <Text
                      style={[
                        styles.actionBtnText,
                        { color: COLOR.REJECT_TEXT },
                      ]}
                    >
                      {fieldOption === "open-positions"
                        ? words["Position_ClosePosition"]
                        : words["Orders_DeleteOrder"]}
                    </Text>
                  )}
                </Pressable>
                <Pressable
                  onPress={() => {
                    setIsModalOpen(true);
                  }}
                  disabled={isClosed.modify}
                  style={({ pressed }) => [
                    styles.actionBtn,
                    { opacity: pressed ? 0.7 : 1 },
                    {
                      backgroundColor: COLOR.ACCEPT,
                      opacity: isClosed.modify ? 0.7 : 1,
                    },
                  ]}
                >
                  {isClosed.id == 0 ? (
                    <Text
                      style={[
                        styles.actionBtnText,
                        { color: COLOR.ACCEPT_TEXT },
                      ]}
                    >
                      {isClosed.title}
                    </Text>
                  ) : (
                    <Text
                      style={[
                        styles.actionBtnText,
                        { color: COLOR.ACCEPT_TEXT },
                      ]}
                    >
                      {fieldOption === "open-positions"
                        ? words["Position_ModifyPosition"]
                        : words["Orders_ModifyOrder"]}
                    </Text>
                  )}
                </Pressable>
              </View>
            )}
          </Animated.View>
        )}
      </Pressable>
      {isModalOpen && (
        <OrderPositionEditModal
          priceCurrent={priceCurrent}
          data={data}
          visible={isModalOpen}
          setVisible={setIsModalOpen}
          fieldOption={fieldOption}
          colors={COLOR}
        />
      )}
    </View>
  );
};

export default OrderPositionItem;

const Styles = (COLOR: IColors) =>
  StyleSheet.create({
    wrapper: {
      flexDirection: "column",
      //borderWidth: 1,
      padding: 10,
      borderRadius: 4,
    },
    topWrapper: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    topLeftWrapper: {
      flex: 3.5,
      gap: 4,
    },
    symbolName: {
      color: COLOR.TEXT,
      fontSize: 16,
      fontWeight: "bold",
    },
    symbolAction: {
      color: COLOR.TEXT,
      fontSize: 14,
      fontWeight: "bold",
    },
    dateText: {
      color: COLOR.NATURAL,
      fontSize: 12,
    },
    topRightWrapper: {
      flex: 2.5,
      flexDirection: "column",
      alignItems: "flex-end",
      height: "100%",
    },
    contentWrapper: {
      borderRadius: 4,
      padding: 8,
      marginTop: 10,
      gap: 6,
    },
    detailItem: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    actionWrapper: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      gap: 4,
      marginTop: 10,
    },
    actionBtn: {
      flex: 1,
      borderRadius: 2,
      padding: 6,
    },
    actionBtnText: {
      textAlign: "center",
      fontSize: 14,
      fontWeight: "bold",
    },
  });

const formatNumber = (number: any, digits = 2): string => {
  if (isNaN(Number(number)))
  {
    return "0";
  }
  
  const formatted = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).format(Number(number));
    
  return formatted;
};

export {formatNumber};

import {useContext, useEffect, useState} from 'react';
import {FlatList, StyleSheet, TextInput, View} from 'react-native';
import {IColors, IGroupSymbol} from '../Global';
import SymbolListItem from '../components/Symbol/SymbolListItem';
import Tab from '../components/Tab';
import {AuthContext} from '../context/AuthContext';
import {useTheme} from '../context/Theme/ThemeProvider';
import { InvestorWarningBox } from '../components/InvestorWarningBox';

const tabs = [
  {
    id: 1,
    name: 'Favoriler',
    value: 'favorite',
  },
  // {
  //   id: 2,
  //   name: 'Popüler',
  //   value: 'popular',
  // },
  {
    id: 2,
    name: 'Forex',
    value: 'forex',
  },
  {
    id: 3,
    name: 'Endeksler',
    value: 'indice',
  },
  {
    id: 4,
    name: 'Metaller',
    value: 'metal',
  },
  {
    id: 5,
    name: 'Enerji',
    value: 'energy',
  },
  {
    id: 6,
    name: 'Kripto',
    value: 'crypto',
  },
  {
    id: 7,
    name: 'Hisseler',
    value: 'stock',
  },
];

const Symbols = ({navigation}: any) => {
  const {group, user} = useContext(AuthContext);
  const [selectedTab, setSelectedTab] = useState<any>(tabs[1]);
  const {colors} = useTheme();
  const [searchValue, setSearchValue] = useState<string>('');
  const styles = Styles(colors);

  if (!group?.Symbols || !user) {
    return <View></View>;
  }

  return (
    <View style={styles.container}>
      {user.IsInvestor && <InvestorWarningBox/>}
      <View style={styles.bottomContainer}>
        <View style={styles.menuWrapper}>
          <View style={{alignItems: 'center', width: '100%'}}>
            <TextInput
              style={{
                backgroundColor: colors.CARD_BACKGROUND1,
                padding: 10,
                borderRadius: 5,
                marginBottom: 10,
                color: colors.TEXT,
                width: '100%',
              }}
              placeholder="Ara..."
              placeholderTextColor={colors.TEXT}
              onChangeText={text => setSearchValue(text)}
              value={searchValue}
            />
          </View>
          <Tab
            selectedTab={selectedTab}
            tabs={tabs}
            setSelectedTab={setSelectedTab}
          />
        </View>
        <View style={styles.dataWrapper}>
          <FlatList
            data={group.Symbols?.filter((item: IGroupSymbol) => (
              searchValue.length < 2 ? 
              item?.Symbol.Category.includes(selectedTab.value) || 
              (selectedTab.value === 'favorite' && user?.FavoriteSymbols.indexOf(item.Symbol.Symbol) > -1) : 
              item?.Symbol.Symbol.toLocaleUpperCase().includes(searchValue.toLocaleUpperCase())))}
            windowSize={2}
            initialNumToRender={3}
            keyExtractor={(item: any) => item?.Symbol?.Symbol.toString()}
            renderItem={({item, index}) => (
              <SymbolListItem
                key={item.Symbol.Symbol}
                groupSymbol={item}
                navigation={navigation}
                backgroundColor={index % 2 === 0 ? colors.CARD_BACKGROUND1 : colors.CARD_BACKGROUND2}
                textColor={index % 2 === 0 ? colors.CARD_TEXT1 : colors.CARD_TEXT2}
              />
            )}
          />
        </View>
      </View>
    </View>
  );
};

export default Symbols;

const Styles = (colors: IColors) =>
  StyleSheet.create({
    container: {
      flex: 1,
      paddingHorizontal: 10,
      paddingBottom: 10,
      paddingTop: 5,
      gap: 10,
      backgroundColor: colors.SCREEN_BACKGROUND,
    },
    menuWrapper: {
      flexDirection: 'column',
      flexWrap: 'wrap',
      width: '100%',
    },
    bottomContainer: {
      flex: 1,
      paddingHorizontal: 10,
    },
    dataWrapper: {
      flex: 1,
      gap: 10,
    },
  });

import { io, Socket } from 'socket.io-client';
import { CONSTANTS } from '../Global';

class SocketConnection {
  private socket: Socket;
  private accessToken: string;
  private server: string = CONSTANTS.CPP_URL;
  // private events: Map<string, (data: any) => void>;

  constructor() {
    // this.server = CONSTANTS.CPP_URL;
    // this.events = new Map();
    this.socket = io(this.server, {
      //transports: ['websocket'],
      withCredentials: true,
      autoConnect: false,
      reconnection: true,
    });

    this.accessToken = '';
  }

  setToken(token: string) {
    this.accessToken = token;
  }

  setServer(server: string) {
    this.server = server;

    const socket = io(server, {
      //transports: ['websocket'],
      withCredentials: true,
      autoConnect: false,
      reconnection: true,
    });
    console.log('Socket server changed to:', server);

    // this.events.forEach((callback, event) => {
    //   socket.on(event, callback);
    //   this.socket.off(event);
    // })

    this.socket = socket;

    // this.socket.disconnect();
  }

  get serverUrl() {
    return this.server;
  }

  get token() {
    return this.accessToken;
  }

  connect() {
    console.log('Connecting to socket');
    if (!this.accessToken) {
      console.error('Token not found');
      throw new Error('Token not found');
    }

    this.socket.io.opts.extraHeaders = {
      Authorization: `Bearer ${this.accessToken}`,
    };

    this.socket.io.opts.query = {
      im: 'client',
    };

    this.socket.connect();
  }

  on(event: string, callback: (data: any) => void) {
    // this.events.set(event, callback);
    this.socket.on(event, callback);

  }

  onAny(callback: (eventName: string, ...args: any) => void) {
    this.socket.onAny(callback);
  }

  off(event: string) {
    // this.events.delete(event);
    this.socket.off(event);
  }

  disconnect() {
    this.socket.disconnect();
  }

  removeAllListeners() {
    this.socket.removeAllListeners();
  }

  close() {
    this.socket.close();
  }

  get connected() {
    return this.socket.connected;
  }
}

const socket = new SocketConnection();

export default socket;

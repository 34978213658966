import React, { Dispatch, SetStateAction } from 'react'
import { StyleSheet, Text, View, TextInput } from 'react-native'
import { IColors, FONTSIZE, FONTWEIGHT } from '../../Global'
import { useTheme } from '../../context/Theme/ThemeProvider';


interface Prop{
    type:'basic' | 'password' | "date" | "email" | "phone";
    args?:any;
    response?:Dispatch<SetStateAction<any>>;
}

const InfoCard:React.FC<Prop> = ({type,args, response}:Prop) => {
    const {colors} = useTheme();
    const style = Style(colors);

    if (type == "basic") {
        return (
            <View style={[style.BgColors, {flexDirection:'row',justifyContent:'space-between', alignItems:'center', gap:5}]}>
                <Text style={style.Key}>{args?.title}</Text>
                <TextInput autoCapitalize='none' style={[style.Value, style.TextInput, {}]} defaultValue={args?.value} onChangeText={response}/>
            </View>
        )
    }
    else if (type == "password") {
        return (
            <View style={[style.BgColors, {flexDirection:'row',justifyContent:'space-between', alignItems:'center', gap:5}]}>
                <Text style={[style.Key, {flex:2}]}>{args?.title}</Text>
                <TextInput autoCapitalize='none' secureTextEntry={true} style={[style.Value, style.TextInput, {flex:4}]}  defaultValue={args?.value} onChangeText={response}/>
            </View>
        )
    }
    else if (type == "date") {
        return (
            <View style={[style.BgColors, {flexDirection:'row',justifyContent:'space-between', alignItems:'center', gap:5}]}>
                <Text style={style.Key}>{args?.title}</Text>
                <TextInput autoCapitalize='none' style={[style.Value, style.TextInput, {}]} defaultValue={args?.value} onChangeText={response}/>
            </View>
        )
    }
    else if (type == "email") {
        return (
            <View style={[style.BgColors, {flexDirection:'row',justifyContent:'space-between', alignItems:'center', gap:5}]}>
                <Text style={style.Key}>{args?.title}</Text>
                <TextInput autoCapitalize='none' autoCorrect={false} textContentType={'emailAddress'} keyboardType='email-address' style={[style.Value, style.TextInput, {}]} defaultValue={args?.value} onChangeText={response}/>
            </View>
        )
    }
    else if (type == "phone") {
        return (
            <View style={[style.BgColors, {flexDirection:'row',justifyContent:'space-between', alignItems:'flex-start', gap:5}]}>
                <Text style={style.Key}>{args?.title}</Text>
                <TextInput autoCapitalize='none' autoCorrect={false} textContentType={'telephoneNumber'} keyboardType='number-pad' style={[style.Value, style.TextInput, {}]} defaultValue={args?.value} onChangeText={response}/>
            </View>
        )
    }
}

export default InfoCard

const Style = (COLOR:IColors) => StyleSheet.create({
    BgColors:{
        backgroundColor:COLOR.CARD_BACKGROUND1,
        paddingVertical:'3%',
        paddingHorizontal:'4%',
        borderRadius:5
    },
    Key:{
        color:COLOR.TEXT,
        fontSize:FONTSIZE.l,
        textAlign:'left',
        flexBasis:'25%'
    },
    Value:{
        color:COLOR.TEXT,
        fontSize:FONTSIZE.l,
        fontWeight:'600',
        flexBasis:'75%',
        textAlign:'right'
    },
    TextInput:{
        padding:0
    }
});